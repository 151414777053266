import { AppDispatch } from "@/store/store";
import { PaginateParams, SortDirectionEnum } from "@/types/pagination";
import { showErrorNotification } from "@/utils/errors";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import request from "../../services/request";
import { UserType, Wlp } from "../user/types";
import { Client, ClientStatusEnum, ClientsStore, UserProfileForAutocomplete } from "./clientsTypes";
import { transformFilterToArray } from "@/components/dataGrid/SearchAdvanced/SearchAdvanced";
import { addFiles } from "@/utils/files";
import { t } from "@/utils/translate";
import { FilterOptionType, HeaderFilterType } from "@/components/dataGrid/CustomFilterHeader";
import { getWlpName } from '@/components/dataGrid/utils/Platform';

export const getPaginationParamsInit = (type: UserType) => {
    return {
        filter: `type=${type}`,
        orderBy: "createdDate",
        sort: SortDirectionEnum.DESC,
        take: 25,
        skip: 0,
    };
};

const initialState: ClientsStore = {
    [UserType.INDIVIDUAL]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: getPaginationParamsInit(UserType.INDIVIDUAL),
        filters: {
            [HeaderFilterType.WLPS]: [],
            [HeaderFilterType.STATUS]: []
        },
        filtersSet: false
    },
    [UserType.BUSINESS]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: getPaginationParamsInit(UserType.BUSINESS),
        filters: {
            [HeaderFilterType.WLPS]: [],
            [HeaderFilterType.STATUS]: []
        },
        filtersSet: false
    },
    [UserType.EMPLOYEE]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: getPaginationParamsInit(UserType.EMPLOYEE),
        filters: {
            [HeaderFilterType.WLPS]: [],
            [HeaderFilterType.STATUS]: []
        },
        filtersSet: false
    },
    [UserType.EXTERNAL_INDIVIDUAL]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: getPaginationParamsInit(UserType.EXTERNAL_INDIVIDUAL),
        filters: {
            [HeaderFilterType.WLPS]: [],
            [HeaderFilterType.STATUS]: []
        },
        filtersSet: false
    },
    [UserType.EXTERNAL_BUSINESS]: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: getPaginationParamsInit(UserType.EXTERNAL_BUSINESS),
        filters: {
            [HeaderFilterType.WLPS]: [],
            [HeaderFilterType.STATUS]: []
        },
        filtersSet: false
    }
};

const slice = createSlice({
    name: "clients",
    initialState,
    reducers: {
        setClients(state, action: PayloadAction<{ clientType: UserType; list: Array<Client> }>) {
            state[action.payload.clientType].list = action.payload.list;
        },
        setLoading: (state, action: PayloadAction<{ clientType: UserType; loading: boolean }>) => {
            state[action.payload.clientType].loading = action.payload.loading;
        },
        setError: (state, action: PayloadAction<{ clientType: UserType; error: string }>) => {
            state[action.payload.clientType].error = action.payload.error;
        },
        setCount: (state, action: PayloadAction<{ clientType: UserType; count: number }>) => {
            state[action.payload.clientType].count = action.payload.count;
        },
        setPagination: (state, action: PayloadAction<{ clientType: UserType, pagination: PaginateParams }>) => {
            state[action.payload.clientType].pagination = action.payload.pagination;
        },
        setFilters: (state, action: PayloadAction<{ clientType: UserType, wlps: Array<Wlp> }>) => {
            const wlpsOptions = action.payload.wlps.map(item => ({
                value: item.wlpId,
                text: getWlpName(item.wlpId),
                enabled: true
            }));
            const statusOptions = getClientStatusOptions();
            state[action.payload.clientType].filters[HeaderFilterType.WLPS] = wlpsOptions;
            state[action.payload.clientType].filters[HeaderFilterType.STATUS] = statusOptions;
            state[action.payload.clientType].filtersSet = true;
        },
        setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, clientType: UserType, options: Array<FilterOptionType> }>) => {
            state[action.payload.clientType].filters[action.payload.filterType] = action.payload.options;
        },
        setFiltersSet(state, action: PayloadAction<{ clientType: UserType, set: boolean }>) {
            state[action.payload.clientType].filtersSet = action.payload.set;
        }
    },
});

export const { setClients, setLoading, setError, setCount, setPagination, setFilters, setFilter, setFiltersSet } =
    slice.actions;

export const getClients = (clientType: UserType, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ clientType, loading: true }));
            const filterArray = transformFilterToArray(payload.filter);
            const wlpIdFilter = filterArray.find(
                (p) => p.field.toLowerCase() === "wlpid"
            );
            const response = await request.get("/api/console/users/list", {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take,
                    ...(wlpIdFilter?.value && { wlpId: wlpIdFilter.value.toUpperCase() }),
                },
            });
            const { data } = response;
            dispatch(setClients({ clientType, list: data.list }));
            dispatch(setCount({ clientType, count: data.count }));
            dispatch(setPagination({ clientType, pagination: payload }));
            dispatch(setLoading({ clientType, loading: false }));
            return data;
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading({ clientType, loading: false }));
        }
    };
};

export const getClient = async (userId: number) => {
    const response = await request.get(`/api/console/users/${userId}`);
    const { data } = response;
    return data;
};

export const getUserDetailsInfo = async (userId: number) => {
    const response = await request.get(`/api/console/users/${userId}/user-details`);
    const { data } = response;
    return data;
};

export const changeEmail = async (userId: number, email: string, reason: string, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", userId.toString());
    formData.append("email", email);
    formData.append("reason", reason);

    addFiles(formData, files);
    const response = await request.post("/api/console/users/email/main", formData, { headers: { "Content-Type": "multipart/form-data" } });
    const { data } = response;
    return data;
};

export const changePhone = async (userId: number, phoneNumber: string, reason: string, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", userId.toString());
    formData.append("phoneNumber", phoneNumber);
    formData.append("reason", reason);

    addFiles(formData, files);

    const response = await request.post("/api/console/users/change-phone", formData, { headers: { "Content-Type": "multipart/form-data" } });
    const { data } = response;
    return data;
};

export const reset2Fa = async (
    userId: number,
    reason: string,
    files: File[]
) => {
    const formData = new FormData();
    formData.append("userId", userId.toString());
    formData.append("reason", reason);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/users/2fa/reset",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const getClientStatusOptions = (): Array<FilterOptionType> => {
    return Object.keys(ClientStatusEnum).map(key => { return ({ value: key, text: t('enum.clientStatusEnum.' + key), enabled: true }); })
};

let abortController: AbortController | null = null;

export const getUsersForAutoComplete = async (
    inputValue: string,
    userTypes: UserType[] = [UserType.INDIVIDUAL, UserType.BUSINESS, UserType.EMPLOYEE]
): Promise<{ count: number, list: Array<UserProfileForAutocomplete> }> => {

    if (abortController) {
        abortController.abort();
    }

    abortController = new AbortController();
    const { signal } = abortController;

    const response = await request.get("/api/console/users/autocomplete/list",
        {
            params: {
                take: 10,
                filter: `any=${inputValue}`,
                userTypes: userTypes.join(',')
            },
            signal
        });
    const { data } = response;
    return data;
};

export default slice.reducer;
