import FormTextField from "@/components/form/FormTextField";
import { FeeEnum, PlatformFeePayload } from "@/redux/platformFees/platformFeesTypes";
import { PermissionType } from "@/redux/user/types";
import { useSelector } from "@/store/store";
import { checkWritePermission } from "@/utils/permissions";
import { Alert, AlertTitle, Button, Checkbox, Grid, List, ListItem, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import FormNumericField from "@/components/form/FormNumericField";
import { showErrorNotification, showSuccessNotification } from "@/utils/errors";
import { createOrUpdateFeesList } from "@/redux/platformFees/platformFeesSlice";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FilterOptionType } from "@/components/dataGrid/CustomFilterHeader";
import { createOrUpdateWlpFeesList } from "@/redux/wlpFees/wlpFeesSlice";
import Bulb from "@/icons/Bulb";
import { WlpFee } from "@/redux/wlpFees/wlpFeesTypes";

interface Props {
    platformFee: WlpFee;
    wlpId?: string;
    decimalScale: number;
    regionsOptions: Array<FilterOptionType>;
    riskGroupCodeOptions: Array<FilterOptionType>;
    onComplete: () => void;
    handleBack: () => void;
};

const ForkPlatformFee = ({ platformFee, wlpId, decimalScale, regionsOptions, riskGroupCodeOptions, onComplete, handleBack }: Props) => {
    const { permissions } = useSelector((state) => state.user);

    const { t } = useTranslation();

    // TODO
    // add permission
    const writePermission = checkWritePermission(permissions, PermissionType.PLATFORMS_FEES);

    const ccy = platformFee.ccy;

    const initFeeType = platformFee.feeType;
    const initFeeMonth = platformFee.currentFeeMonth;
    const initFeeAmount = platformFee.currentFeeAmount;
    const initFeePercent = platformFee.currentFeePercent;
    const initMinFee = platformFee.currentMinFee;
    const countryCode = platformFee.countryCode;
    const regionCode = platformFee.regionCode;

    const initialValues = {
        type: initFeeType,
        feeMonth: initFeeMonth,         // ONE_TIME_PLUS_MONTHLY_FEE
        feeAmount: initFeeAmount,       // ONE_TIME_FEE
        feePercent: initFeePercent,     // VOLUME_FEE
        minFee: initMinFee,             // VOLUME_FEE
        feeStartDateTime: null,
        changeImmediately: true
    };

    const validationSchema = Yup.object({
        type: Yup.string()
            .required(t('form.validator.required').toString()),
        feeMonth: Yup.number()
            .typeError(t('form.validator.required').toString())
            .required(t('form.validator.required').toString())
            .min(0, t('form.validator.nonNegative').toString()),
        feeAmount: Yup.number()
            .typeError(t('form.validator.required').toString())
            .required(t('form.validator.required').toString())
            .min(0, t('form.validator.nonNegative').toString()),
        feePercent: Yup.number()
            .typeError(t('form.validator.required').toString())
            .required(t('form.validator.required').toString())
            .min(0, t('form.validator.nonNegative').toString()),
        minFee: Yup.number()
            .typeError(t('form.validator.required').toString())
            .required(t('form.validator.required').toString())
            .min(0, t('form.validator.nonNegative').toString()),
        feeStartDateTime: Yup.date()
            .nullable()
            .when("changeImmediately", {
                is: false,
                then: Yup.date().typeError(t('form.validator.required').toString())
                    .required(t('form.validator.required').toString())
                    .min(new Date(), t('form.validator.feeFutureDate').toString()),
                otherwise: Yup.date().nullable()
            }),
    });

    enum FieldTypeEnum {
        TEXT,
        NUMBER,
    };

    interface PlatfromFeeField {
        type: FieldTypeEnum;
        name: string;
        label: string;
        header: string;
        value: string | number;
        endAdornment?: string;
        disabled?: boolean;
    }

    const feeFormFields: Array<PlatfromFeeField> = [
        ...([
            {
                type: FieldTypeEnum.TEXT,
                name: 'type',
                label: t('form.fields.feeType'),
                header: t('fees.feeType'),
                value: t('enum.feeCategoryCodeTypes.' + initFeeType).toString(),
                disabled: true
            }
        ]),
        ...(platformFee.feeType === FeeEnum.VOLUME_FEE ? [
            {
                type: FieldTypeEnum.NUMBER,
                name: 'feePercent',
                label: t('form.fields.value'),
                header: t('fees.chooseValue'),
                value: initFeePercent,
                endAdornment: '%'
            },
            {
                type: FieldTypeEnum.NUMBER,
                name: 'minFee',
                label: t('form.fields.value'),
                header: t('fees.chooseMin'),
                value: initMinFee,
                endAdornment: ccy
            }
        ] : []),
        ...(platformFee.feeType === FeeEnum.ONE_TIME_FEE ? [
            {
                type: FieldTypeEnum.NUMBER,
                name: 'feeAmount',
                label: t('form.fields.value'),
                header: t('fees.oneTimeFee'),
                value: initFeeAmount,
                endAdornment: ccy
            },
        ] : []),
        ...(platformFee.feeType === FeeEnum.ONE_TIME_PLUS_MONTHLY_FEE ? [
            {
                type: FieldTypeEnum.NUMBER,
                name: 'feeMonth',
                label: t('form.fields.value'),
                header: t('fees.monthlyFee'),
                value: initFeeMonth,
                endAdornment: ccy
            },
            {
                type: FieldTypeEnum.NUMBER,
                name: 'feeAmount',
                label: t('form.fields.value'),
                header: t('fees.oneTimeFee'),
                value: initFeeAmount,
                endAdornment: ccy
            }
        ] : []),
    ];

    const submit = async (formData: any) => {
        try {
            const { feeMonth, feeAmount, feePercent, minFee, feeStartDateTime, changeImmediately } = formData;
            const _feeMonth = parseFloat(feeMonth);
            const _feeAmount = parseFloat(feeAmount);
            const _feePercent = parseFloat(feePercent);
            const _minFee = parseFloat(minFee);

            const startDateTime = (feeStartDateTime && !changeImmediately) ? feeStartDateTime.toISOString().split('.')[0] : null;

            const { ccy, feeType, userProfileType, riskGroupCode } = platformFee;

            const payload = {
                platformFeePayload: {
                    wlpId: wlpId,
                    process: platformFee.process,
                    ccy: ccy,
                    fee: feeType,
                    userProfileType: userProfileType,
                    riskGroupCode: riskGroupCode === "0-DEFAULT" ? null : riskGroupCode,
                    countryCode: countryCode === "0-DEFAULT" ? null : countryCode,
                    regionCode: regionCode === "0-DEFAULT" ? null : regionCode,
                    feeAmount: _feeAmount,
                    feeMonth: _feeMonth,
                    feePercent: _feePercent,
                    minFee: _minFee,
                    invoiceItemCode: null,
                    ...(startDateTime ? { feeStartDateTime: startDateTime } : {}),
                }
            };

            let fees: Array<PlatformFeePayload> = [];

            const allRegionsAndCountriesEnabled = regionsOptions.every(region =>
                region.enabled && (region.subValues?.every(country => country.enabled))
            );
            const enabledRegions = regionsOptions.filter(region => region.enabled);

            if (allRegionsAndCountriesEnabled && regionsOptions.length !== 1) {
                // If all regions and all countries within those regions are enabled,
                riskGroupCodeOptions.filter(risk => risk.enabled).forEach(risk => {
                    fees.push({
                        ...payload.platformFeePayload,
                        riskGroupCode: risk.value,
                        countryCode: null,
                        regionCode: null
                    });
                });
            } else {
                // Handle cases where specific regions or countries within regions are selected
                enabledRegions.forEach(region => {
                    const allCountriesEnabled = region.subValues?.every(country => country.enabled);

                    if (allCountriesEnabled) {
                        // If all countries within this region are enabled, iterate over risk groups
                        riskGroupCodeOptions.filter(risk => risk.enabled).forEach(risk => {
                            fees.push({
                                ...payload.platformFeePayload,
                                riskGroupCode: risk.value,
                                countryCode: null,
                                regionCode: region.value
                            });
                        });
                    } else {
                        // Specific countries within this region are enabled
                        region.subValues?.forEach(country => {
                            if (country.enabled) {
                                // Iterate over each risk group for each enabled country
                                riskGroupCodeOptions.filter(risk => risk.enabled).forEach(risk => {
                                    fees.push({
                                        ...payload.platformFeePayload,
                                        riskGroupCode: risk.value,
                                        countryCode: country.value,
                                        regionCode: region.value
                                    });
                                });
                            }
                        });
                    }
                });
            }

            if (wlpId) {
                const feesPayload = {
                    emiFeeCreateOrUpdateRequestList: fees
                };
                await createOrUpdateFeesList(wlpId, feesPayload);
            } else {
                const feesPayload = {
                    processFeeCreateOrUpdateRequestList: fees
                };
                await createOrUpdateWlpFeesList(platformFee.wlpId, feesPayload);
            }

            onComplete();
            showSuccessNotification(t('emiFinance.feeUpdated'));
        }
        catch (e) {
            showErrorNotification(e);
        }
    };

    const getRegionMessage = (regionsOptions: Array<FilterOptionType>) => {
        if (regionsOptions.every(region => region.enabled && (region.subValues?.every(country => country.enabled))) && regionsOptions.length !== 1) {
            return t('fees.platformsFeeUpdateInfoAllRegions');
        } else if (regionsOptions.some(region => region.enabled && (region.subValues?.every(country => country.enabled)))) {
            return t('fees.platformsFeeUpdateInfoMultipleRegions', {
                regions: regionsOptions
                    .filter(region => region.enabled && (region.subValues?.every(country => country.enabled)))
                    .map(region => region.text)
                    .join(', ')
            });
        } else if (regionsOptions.some(region => region.enabled && (region.subValues?.some(country => country.enabled)))) {
            return t('fees.platformsFeeUpdateInfoSelectedCountries', {
                countries: regionsOptions
                    .filter(region => region.enabled)
                    .flatMap(region => region.subValues?.filter(country => country.enabled))
                    .map(country => country?.text)
                    .join(', ')
            });
        } else {
            return t('fees.platformsFeeUpdateInfoNone');
        }
    };

    const getRiskGroupMessage = (riskGroupCodeOptions: Array<FilterOptionType>) => {
        return (riskGroupCodeOptions.every(riskGroup => riskGroup.enabled) && riskGroupCodeOptions.length !== 1) ?
            t('fees.platformsFeeUpdateInfoAllRiskGroups') :
            t('fees.platformsFeeUpdateInfoSelectedRiskGroups', {
                riskGroups: riskGroupCodeOptions
                    .filter(riskGroup => riskGroup.enabled)
                    .map(riskGroup => riskGroup.text)
                    .join(', ')
            });
    };


    return (
        <Grid container direction='column' wrap='nowrap' height='100%'>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={submit}>
                {({ ...formik }) => {
                    return (
                        <Form>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <Grid container rowGap={2.5} pt={2}>
                                    {
                                        feeFormFields.map((field, index) => (
                                            <Grid item xs={12} key={index}>
                                                <Typography variant='h3'>
                                                    {index + 1}. {field.header}
                                                </Typography>
                                                {
                                                    field.type === FieldTypeEnum.TEXT ?
                                                        <FormTextField
                                                            name={field.name}
                                                            fullWidth
                                                            label={' '}
                                                            value={field.value}
                                                            disabled={field.disabled} />
                                                        : field.type === FieldTypeEnum.NUMBER &&
                                                        <FormNumericField
                                                            name={field.name}
                                                            value={field.value}
                                                            decimalScale={(platformFee.feeType === FeeEnum.VOLUME_FEE && field.name === 'feePercent') ? 2 : decimalScale}
                                                            fullWidth
                                                            label={field.label}
                                                            adornmentEnd={field.endAdornment}
                                                            {...(field.endAdornment === '%' && { maxLimit: 100 })}
                                                            errors={formik?.errors[field.name as keyof typeof formik.errors]} />
                                                }
                                            </Grid>
                                        ))
                                    }
                                    <Grid item xs={12}>
                                        <Typography variant='h3'>
                                            {feeFormFields.length + 1}. {t('fees.feeChangeDate')}
                                        </Typography>
                                        <Grid item xs={12}>
                                            <DateTimePicker
                                                disablePast
                                                ampm={false}
                                                format="dd/MM/yyyy HH:mm"
                                                sx={{ width: '100%' }}
                                                value={formik.values.feeStartDateTime}
                                                label={t('form.fields.date')}
                                                viewRenderers={{
                                                    hours: null,
                                                    minutes: null,
                                                    seconds: null,
                                                }}
                                                onChange={(value) => {
                                                    formik.setFieldValue("changeImmediately", false, true);
                                                    formik.setFieldValue("feeStartDateTime", value, true)
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        variant: "outlined",
                                                        error: formik.touched.feeStartDateTime && Boolean(formik.errors.feeStartDateTime),
                                                        helperText: formik.touched.feeStartDateTime && formik.errors.feeStartDateTime
                                                    }
                                                }}
                                            />
                                        </Grid>
                                        <Grid container item mt={1.5}>
                                            <Checkbox
                                                sx={{ p: 0 }}
                                                checked={formik.values.changeImmediately}
                                                onChange={(event) => {
                                                    formik.setFieldValue("changeImmediately", event.target.checked, true);
                                                    formik.setFieldValue("feeStartDateTime", null, true);
                                                }}
                                            />
                                            <Typography variant="body2" pl={1}>{t('fees.changeImmediately')}</Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid container rowGap={2} mt={2}>
                                    <Grid item xs={12}>
                                        <Alert severity="info" icon={<Bulb />}>
                                            <AlertTitle sx={{ mt: 0.2, mb: 0 }}>{`${t('fees.pleaseNote')}`}</AlertTitle>
                                            <List dense={true}
                                                sx={{
                                                    listStyleType: 'disc',
                                                    pl: 2,
                                                    '& .MuiListItem-root': {
                                                        display: 'list-item',
                                                        pl: 0,
                                                    },
                                                }}>
                                                <ListItem>
                                                    <Typography>
                                                        {getRegionMessage(regionsOptions)}
                                                    </Typography>
                                                </ListItem>
                                                <ListItem>
                                                    <Typography>
                                                        {getRiskGroupMessage(riskGroupCodeOptions)}
                                                    </Typography>
                                                </ListItem>
                                            </List>
                                        </Alert>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} container display='flex' mt={2} justifyContent='space-between'>
                                    <Grid item xs="auto" mt='auto'>
                                        <Button color='secondary' variant='outlined' onClick={handleBack}>{t('form.buttons.back')}</Button>
                                    </Grid>
                                    <Grid item xs="auto" mt='auto'>
                                        <Button type='submit' disabled={!writePermission || formik.isSubmitting}>{t('form.buttons.save')}</Button>
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        </Form>
                    );
                }}
            </Formik>
        </Grid>

    );
};

export default ForkPlatformFee;