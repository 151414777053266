import { BalanceType, SubTabsEnum } from "../emiFinance/emiFinanceTypes";
import { Payment, PaymentsSplit } from "../emiFinanceBanking/emiFinanceBankingTypes";
import { PaginateParams } from "@/types/pagination";

export enum UnassignedCryptoPaymentsType {
    EXCHANGE = 'EXCHANGE',
    FIREBLOCKS = 'FIREBLOCKS'
}

export interface EmiFinanceCryptoStore {
    activeTab: SubTabsEnum;
    userBalances: Array<BalanceType>;
    fireblocks: Array<BalanceType>;
    exchanges: Array<BalanceType>;
    custody: Array<BalanceType>;
    loading: {
        userBalancesLoading: boolean;
        fireblocksLoading: boolean;
        exchangeOwnedLoading: boolean;
        exchangeUtilizedLoading: boolean;
    },
    userBreakdown: Array<CryptoUserBreakdownType>;

    unassignedPayments: {
        [key: string]: {
            unassignedPaymentsSplitLoading: boolean;
            unassignedPaymentsSplit: Array<PaymentsSplit>;
            data: {
                loading: boolean;
                count: number;
                list: Array<Payment>;
                error: string | null;
                pagination: PaginateParams;
                uuid: string | null;
            },
        }
    }
}

export interface CryptoUserBreakdownType {
    id: number;
    emi: string;
    uiType: string;
    integration: string;
    intGroup: string;
    groupHeader: string;
    description: string;
    cpNameCode: string;
    cpDescription: string;
    cpMeaning: string;
    wlpId: string;
    currencyCode: string;
    currencyLogo: string;
    amount: number;
    amountEUR: number;
    totalAmount: number;
    totalAmountEUR: number;
    detailList: Array<CryptoUserBreakdownDetailsType>;
};

export interface CryptoUserBreakdownDetailsType {
    integration: string;
    intGroup: string;
    groupHeader: string;
    wlpId: string;
    cpNameCode: string;
    cpDescription: string;
    cpMeaning: string;
    currencyCode: string;
    currencyLogo: string;
    amount: number;
    amountEur: number;
    amountTotal: number;
    amountTotalEur: number;
};