import * as React from "react";
import { SvgIcon } from '@mui/material';

const UsersDuo = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="7.49935" cy="5.00008" r="3.33333" stroke="currentColor" strokeWidth="1.2" />
            <path opacity="0.5" d="M10.416 3.61759C10.8639 2.94396 11.6298 2.5 12.4993 2.5C13.8801 2.5 14.9993 3.61929 14.9993 5C14.9993 6.38071 13.8801 7.5 12.4993 7.5C11.6298 7.5 10.8639 7.05604 10.416 6.38241" stroke="currentColor" strokeWidth="1.2" />
            <ellipse cx="7.49935" cy="14.1666" rx="5.83333" ry="3.33333" stroke="currentColor" strokeWidth="1.2" />
            <path opacity="0.5" d="M15 11.6667C16.4619 11.9873 17.5 12.7992 17.5 13.7501C17.5 14.6079 16.6552 15.3525 15.4167 15.7254" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default UsersDuo;