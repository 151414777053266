import * as React from "react";
import { SvgIcon } from '@mui/material';

const WorkflowIcon = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <g clipPath="url(#clip0_1201_314)">
                <path opacity="0.5" d="M16.6668 18.3334V13.3334C16.6668 9.65152 13.6821 6.66675 10.0002 6.66675C6.31826 6.66675 3.3335 9.65152 3.3335 13.3334V18.3334" stroke="currentColor" strokeWidth="1.5" />
                <path d="M11.9087 9.58325C12.7443 9.92149 13.4115 10.5887 13.7497 11.4243" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M1.6665 18.3333H18.3332" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M10 1.66675V4.16675" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M17.5 5L16.25 6.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M2.5 5L3.75 6.25" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M11.25 14.5833C11.25 15.2736 10.6904 15.8333 10 15.8333C9.30964 15.8333 8.75 15.2736 8.75 14.5833C8.75 13.8929 9.30964 13.3333 10 13.3333C10.6904 13.3333 11.25 13.8929 11.25 14.5833Z" stroke="currentColor" strokeWidth="1.5" />
                <path opacity="0.5" d="M10 15.8333V18.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_1201_314">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>

        </svg>
    </SvgIcon>
);
export default WorkflowIcon;



