export interface EmiFinanceStore {
    accountBalances: Array<EmiFinanceSummaryType>,
    systemBalances: Array<EmiFinanceSummaryType>,
    userBalances: Array<EmiFinanceSummaryType>,
    earnBalances: Array<EmiFinanceSummaryType>,
    liabilities: LiabilityReponseType | null,
    totalAssets: number | null,
    totalPlatform: number | null,
    loading: EmiFinanceLoading,
};

export interface EmiFinanceEarn {
    totalVaults: Array<BalanceType>,
    deposits: Array<BalanceType>,
    liabilities: Array<BalanceType>,
    interestPayments: Array<BalanceType>
    depositRepaymnets: Array<BalanceType>
};

export interface EmiFinanceLoading {
    platformLoading: boolean,
    liabilitiesLoading: boolean,
    assetsLoading: boolean,
    userBalancesLoading: boolean,
    earnBalances: boolean,
    accountBalances: boolean,
    systemBalances: boolean,
    bankingTransactionsLoading: boolean,
};

export interface LiabilityReponseType {
    id: number,
    integration: string,
    currencyCode: string,
    fromPartners: number,
    toPartners: number
};


export interface EmiFinanceSummaryType {
    id: number,
    integration: string,
    sumType: string,
    sumTypeDescription: string,
    amount: number,
    currencyCode: string,
    totalAmount: number
};


export interface OverviewSubTabsReponse {
    id: number,
    viewType: string,
    integration: string,
    lookupCode?: string,
    description?: string,
    meaning?: string,
    onClick?: () => void
};

export interface BalanceType {
    id: number;
    emi: string;
    serviceOwner: string;
    dataSourceType: string;
    integration: string;
    ownerKey: string;
    allowAssignment: string;
    allowFileUpload: string;
    sumType: string;
    sumTypeDescription: string;
    ccyDecimalScale: number;
    currencyCode: string;
    currencyLogo: string;
    amount: number;
    totalAmount: number;
    amountEur: number;
    totalAmountEur: number;
    totalAmountEurPerIntegarion: number;
    amountString: string;
    totalAmountString: string;
    amountEurString: string;
    paymentConfigId: number;
};

export enum EmiFinanceTabEnum {
    SYSTEM = "SYSTEM",
    BANKING = "BANKING",
    CRYPTO = "CRYPTO",
    EARN = "EARN",
    ACQUIRING = "ACQUIRING",
    TRUST = "TRUST",
    CARD = "CARD",
    FEE = "FEE",
};

export interface SystemBalanceGraphic {
    id: number,
    graphicDate: string,
    currencyCode: string,
    amountIn: number,
    amountOut: number
};

export interface IntegrationDetailsType {
    id: number;
    emi: string;
    serviceOwner: string;
    dataSourceType: string;
    integration: string;
    ownerKey: string;
    allowAssignment: string;
    allowFileUpload: string;
    sumType: string;
    sumTypeDescription: string;
    ccyDecimalScale: number;
    currencyCode: string;
    currencyLogo: string;
    amount: number;
    totalAmount: number;
    amountEur: number;
    totalAmountEur: number;
    totalAmountEurPerIntegarion: number;
    amountString: string;
    totalAmountString: string;
    amountEurString: string;
    paymentConfigId: number;
    maxAmount: number;
};

export enum IntegrationSumTypes {
    CUSTOMER_ACCOUNT = "CUSTOMER_ACCOUNT",
    SYSTEM_ACCOUNT = "SYSTEM_ACCOUNT"
};

export enum SubTabsEnum {
    ALL = "ALL",
    OVERVIEW = "OVERVIEW",
    USER_BALANCES = "USER_BALANCES",
    FIREBLOCKS = "FIREBLOCKS",
    EXCHANGE_FUNDS = "EXCHANGE_FUNDS",
    CUSTODIAL_FUNDS = "CUSTODIAL_FUNDS",
    VAULTS = "VAULTS",
    DEPOSITS = "DEPOSITS",
    INTEREST_PAYMENTS = "INTEREST_PAYMENTS",
    DEPOSIT_REPAYMENT = "PRINCIPAL_RE-PAYMENTS",
    LIABILITIES = "LIABILITIES",
    AVAILABLE_FOR_TOPUP = "AVAILABLE_FOR_TOPUP",
    BANKING = "BANKING",
    CRYPTO = "CRYPTO",
    CARD = "CARD",
    TOKEN = "TOKEN",
    UNASSIGNED_TRANSACTIONS = "UNASSIGNED_TRANSACTIONS"
};

export interface WlpIdRow {
    wlpId: string,
    totalEUR: number,
    currencies: {
        [key: string]: number
    }
};

export interface Currency {
    ccy: string;
    name: string;
    decimals: number;
    displayDecimals: number;
};