import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppDispatch } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';
import { AgentSettings, GeneralSettings, SettingsStore } from './emiSettingsTypes';

const initialState: SettingsStore = {
    loading: true,
    settings: []
};

const slice = createSlice({
    name: 'emiSettings',
    initialState,
    reducers: {
        setSettings(state, action: PayloadAction<Array<GeneralSettings>>) {
            state.settings = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        }
    },
});

export const { setSettings, setLoading } = slice.actions;

export const getSettings = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/settings/emi/general`);
            const { data } = response;
            if (data && data.length > 0) {
                const combinedArray = data.map((wlp: AgentSettings) => {
                    const settings = JSON.parse(wlp.settings);
                    return {
                        code: wlp.code,
                        registrationDisabled: settings.registrationDisabled,
                        outgoingPaymentsDisabled: settings.outgoingPaymentsDisabled
                    };
                });
                dispatch(setSettings(combinedArray));
            }
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoading(false));
        }
    };
};

export const updateRegionstationSettings = async (disabled: boolean, wlpId?: string) => {
    const response = await request.put(`/api/console/settings/registration/update/${disabled}`, { ...(wlpId && { wlpId }) });
    const { data } = response;
    return data;
};

export const updateOutgoingPayments = async (disabled: boolean, wlpId?: string) => {
    const response = await request.put(`/api/console/settings/outgoing-payments/update/${disabled}`, { ...(wlpId && { wlpId }) });
    const { data } = response;
    return data;
};

export default slice.reducer;