import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "@/store/store";
import request from "@/services/request";
import { PaginateParams, SortDirectionEnum } from "@/types/pagination";
import { showErrorNotification } from "@/utils/errors";
import { FilterOptionType, HeaderFilterType } from "@/components/dataGrid/CustomFilterHeader";
import { Wlp } from "../user/types";
import { getWlpName } from "@/components/dataGrid/utils/Platform";
import { StatementLookup, SystemPaymentConfig, SystemPaymentConfigWithSteps, SystemPaymentsConfigsStore } from "./systemPaymentsConfigsTypes";

export const configsDefaultPagination: PaginateParams = {
    filter: '',
    orderBy: 'paymentConfigId',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: SystemPaymentsConfigsStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: configsDefaultPagination,
    filters: {
        [HeaderFilterType.WLPS]: []
    },
    filtersSet: false
};

const slice = createSlice({
    name: 'systemPaymentsConfigs',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setConfigs(state, action: PayloadAction<Array<SystemPaymentConfig>>) {
            state.list = action.payload;
        },
        setPagination: (state, action: PayloadAction<PaginateParams>) => {
            state.pagination = action.payload;
        },
        setError(state, action: PayloadAction<string>) {
            state.error = action.payload;
        },
        setCount(state, action: PayloadAction<number>) {
            state.count = action.payload;
        },
        setFilters: (state, action: PayloadAction<{ wlps: Array<Wlp> }>) => {
            const wlpsOptions = action.payload.wlps.map(item => ({
                value: item.wlpId,
                text: getWlpName(item.wlpId),
                enabled: true
            }));
            state.filters[HeaderFilterType.WLPS] = wlpsOptions;
            state.filtersSet = true;
        },
        setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, options: Array<FilterOptionType> }>) => {
            state.filters[action.payload.filterType] = action.payload.options;
        },
        setFiltersSet(state, action: PayloadAction<boolean>) {
            state.filtersSet = action.payload;
        }
    }
});

export const {
    setLoading,
    setConfigs,
    setError,
    setCount,
    setPagination,
    setFilters,
    setFilter,
    setFiltersSet
} = slice.actions;

export default slice.reducer;

export const getSystemPaymentsConfigs = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/statements/system/payments/configs`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setConfigs(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};

export const getSystemPaymentConfigSteps = async (paymentConfigId: number): Promise<SystemPaymentConfigWithSteps> => {
    const response = await request.get(`/api/console/statements/system/payment/config/steps/${paymentConfigId}`);
    const { data } = response;
    return data;
};

export const getStatementsLookups = async (lookupType: string): Promise<StatementLookup[]> => {
    const response = await request.get(`/api/console/statements/lookups/${lookupType}`);
    const { data } = response;
    return data;
};