import { changeLastSubItem, setPathToItem } from "@/redux/menu/menuSlice";
import { PermissionType } from "@/redux/user/types";
import { dispatch, useSelector } from "@/store/store";
import { checkPermission } from "@/utils/permissions";
import { Grid, Tab, Tabs, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import WlpUserGroups from "./userGroups/WlpUserGroups";
import WlpFees from "./fees/WlpFees";
import PlatformLimits from "../platformDetails/platformLimits/PlatformLimits";
import WlpPlans from "./plans/WlpPlans";


const ConfigurationAndFees = () => {
    const { t } = useTranslation();

    const { permissions } = useSelector((state) => state.user);
    const { state } = useLocation();
    const theme = useTheme();

    const location = useLocation();
    const path = location?.pathname;

    useEffect(() => {
        dispatch(setPathToItem(path));
    }, [path]);

    const tabsOption = [
        {
            value: 0,
            label: t("pages.tabs.fees"),
            to: `/configuration-and-fees/fees`,
            allowed: checkPermission(permissions, PermissionType.PLATFORMS_FEES),
        },
        {
            value: 1,
            label: t("pages.tabs.userGroups"),
            to: `/configuration-and-fees/user-groups`,
            allowed: checkPermission(permissions, PermissionType.USER_GROUPS),
        },
        {
            value: 2,
            label: t("pages.tabs.plans"),
            to: `/configuration-and-fees/plans`,
            allowed: checkPermission(permissions, PermissionType.USER_LEVEL),
        },
        {
            value: 3,
            label: t("pages.tabs.limits"),
            to: `/configuration-and-fees/limits`,
            allowed: checkPermission(permissions, PermissionType.USER_LIMITS),
        }
    ];

    return (
        <Grid container rowGap="30px" sx={{ ...theme.typography.mainContentPadding }}>
            <Grid item xs={12}>
                <Tabs value={path}>
                    {tabsOption
                        .filter((p) => p.allowed)
                        .map((tab, index) => (
                            <Tab
                                key={index}
                                component={Link}
                                value={tab.to}
                                to={tab.to}
                                label={tab.label}
                                id={`tab-${index}`}
                                state={state}
                                onClick={() => dispatch(changeLastSubItem({ path: tab.to }))}
                            />
                        ))}
                </Tabs>
            </Grid>
            <Grid item xs={12}>
                {path === tabsOption[0].to && <WlpFees />}
                {path === tabsOption[1].to && <WlpUserGroups />}
                {path === tabsOption[2].to && <WlpPlans />}
                {path === tabsOption[3].to && <PlatformLimits />}
            </Grid>
        </Grid>
    );

}

export default ConfigurationAndFees;