import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { UserAccountsStore } from './userAccountsTypes';
import { Account, AccountTabType, AccountTypeEnum } from '../accounts/accountsTypes';
import { getAccountStatusOptions } from '../accounts/accountsSlice';

export const paginationParamsInit = {
	filter: '',
	orderBy: 'balanceEur',
	sort: SortDirectionEnum.DESC,
	take: 15,
	skip: 0
};

const initialState: UserAccountsStore = {
	storedUserId: null,
	tabType: AccountTabType.ALL,
	tabs: {
		[AccountTabType.ALL]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[AccountTabType.CRYPTO]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[AccountTabType.BANK]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[AccountTabType.WALLET]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[AccountTabType.TOKEN]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
		[AccountTabType.TRUST]: {
			loading: false,
			list: [],
			error: null,
			count: 0,
			pagination: paginationParamsInit,
			filters: {
				[HeaderFilterType.STATUS]: [],
				[HeaderFilterType.CURRENCY]: []
			},
			filtersSet: false
		},
	}
};


const slice = createSlice({
	name: 'userAccounts',
	initialState,
	reducers: {
		reset: () => initialState,
		setStoredUserId: (state, action: PayloadAction<number>) => {
			state.storedUserId = action.payload;
		},
		setTabType: (state, action: PayloadAction<AccountTabType>) => {
			state.tabType = action.payload;
		},
		// Accounts
		setAccounts(state, action: PayloadAction<{ tabType: AccountTabType, list: Array<Account> }>) {
			state.tabs[action.payload.tabType].list = action.payload.list;
		},
		setLoading: (state, action: PayloadAction<{ tabType: AccountTabType, loading: boolean }>) => {
			state.tabs[action.payload.tabType].loading = action.payload.loading
		},
		setError: (state, action: PayloadAction<{ tabType: AccountTabType, error: string }>) => {
			state.tabs[action.payload.tabType].error = action.payload.error;
		},
		setCount: (state, action: PayloadAction<{ tabType: AccountTabType, count: number }>) => {
			state.tabs[action.payload.tabType].count = action.payload.count;
		},
		setPagination: (state, action: PayloadAction<{ tabType: AccountTabType, pagination: PaginateParams }>) => {
			state.tabs[action.payload.tabType].pagination = action.payload.pagination;
		},
		// Filters
		setFilters: (state, action: PayloadAction<{ type: AccountTabType, currencies: Array<FilterOptionType> }>) => {
			const statusOptions = getAccountStatusOptions();
			state.tabs[action.payload.type].filters[HeaderFilterType.STATUS] = statusOptions;
			state.tabs[action.payload.type].filters[HeaderFilterType.CURRENCY] = action.payload.currencies;
			state.tabs[action.payload.type].filtersSet = true;
		},
		setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, type: AccountTabType, options: Array<FilterOptionType> }>) => {
			state.tabs[action.payload.type].filters[action.payload.filterType] = action.payload.options;
		},
		setFiltersSet(state, action: PayloadAction<{ type: AccountTabType, set: boolean }>) {
			state.tabs[action.payload.type].filtersSet = action.payload.set;
		}
	}
});

export const { reset, setStoredUserId, setTabType, setLoading, setError, setCount, setPagination, setAccounts, setFilters, setFilter, setFiltersSet } = slice.actions;

export const getUserAccounts = (tabType: AccountTabType, payload: PaginateParams, accountTypes?: AccountTypeEnum[]) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading({ tabType, loading: true }));
			const response = await request.get('/api/console/accounts/list', {
				params: {
					isSystem: false,
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take,
					...(accountTypes && { accountTypes: accountTypes.join(',') }),
				}
			});
			const { data } = response;
			dispatch(setAccounts({ tabType, list: data.list }));
			dispatch(setCount({ tabType, count: data.count }));
			dispatch(setPagination({ tabType, pagination: payload }));
			dispatch(setLoading({ tabType, loading: false }));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading({ tabType, loading: false }));
		}
	};
};

export default slice.reducer;