import { Grid, Typography } from "@mui/material";
import CurrencyLogo from "../CurrencyLogo";
import { numericFormatter } from "react-number-format";
import { getCurrencySign } from "@/utils/currency";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

interface Props {
    balance: number,
    currency: string,
    logoSource?: string,
    name?: string,
    decimalScale?: number,
}

const AccountItem = ({ currency, logoSource, name, balance, decimalScale }: Props) => {
    const { t } = useTranslation();

    const formattedBalance = useMemo(() => {
        return numericFormatter(balance.toString(), {
            displayType: 'text',
            decimalScale: decimalScale ? decimalScale : 2,
            fixedDecimalScale: true,
            thousandsGroupStyle: 'thousand',
            thousandSeparator: true,
            valueIsNumericString: true,
            prefix: `${getCurrencySign(currency)} `
        });
    }, [balance, currency, decimalScale])

    return (
        <Grid container flexDirection='column' flex={1} >
            <Grid item container>
                <Grid item xs='auto'>
                    <CurrencyLogo logoUrl={logoSource} />
                </Grid>
                <Grid item xs='auto' container pl={1} >
                    <Grid item xs={12}>
                        <Typography variant='body2' fontWeight={700}> {name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='caption' color='text.secondary' dangerouslySetInnerHTML={{
                            __html: `${t('form.texts.availableBalance', {
                                balance: formattedBalance
                            })}`
                        }} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
};

export default AccountItem;
