import { useState } from 'react';
import { Form, Field, useFormik, FormikProvider } from 'formik';
import { Checkbox, FormControl, InputLabel, FormHelperText, Grid, MenuItem, Select, ListItemText, Typography, List, ListItem, Accordion, AccordionSummary, AccordionDetails, Box, Button } from '@mui/material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ChevronDown2 from '@/icons/ChevronDown2';
import { alpha3ToAlpha2 } from 'i18n-iso-countries';
import { FilterOptionType } from '@/components/dataGrid/CustomFilterHeader';
import { WlpFee } from '@/redux/wlpFees/wlpFeesTypes';


export interface FormValues {
    userType: string;
    regionsOptions: Array<FilterOptionType>;
    riskGroupCodeOptions: Array<FilterOptionType>;
};

interface Props {
    fee: WlpFee;
    regionsOptions: Array<FilterOptionType>;
    riskGroupCodeOptions: Array<FilterOptionType>;
    selectedCurrency: string;
    onSubmitParams: (formData: FormValues) => void;
    handleNext: (isFormValid: boolean) => void;
};

const FeeForkSelectParams = ({ fee, regionsOptions, riskGroupCodeOptions, selectedCurrency, onSubmitParams, handleNext }: Props) => {
    const { t } = useTranslation();

    const [expanded, setExpanded] = useState<string | false>(false);

    const defaultRiskGroupCode = fee.riskGroupCode === '0-DEFAULT';
    const defaultRegionCode = fee.regionCode === '0-DEFAULT';

    const updatedRiskGroupCodeOptions = riskGroupCodeOptions
        .filter(riskGroup => defaultRiskGroupCode || fee.riskGroupCode === riskGroup.value)
        .map(riskGroup => ({
            ...riskGroup,
            enabled: riskGroup.enabled,
        }));

    const updatedRegionsOptions = regionsOptions
        .filter(region => defaultRegionCode || fee.regionCode === region.value)
        .map(region => ({
            ...region,
            enabled: region.enabled,
            subValues: region.subValues?.map(country => ({
                ...country,
                enabled: country.enabled
            }))
        }));

    const initialValues: FormValues = {
        userType: fee.userProfileType,
        regionsOptions: updatedRegionsOptions,
        riskGroupCodeOptions: updatedRiskGroupCodeOptions
    };

    const validationSchema = Yup.object({
        regionsOptions: Yup.array()
            .of(
                Yup.object().shape({
                    subValues: Yup.array().of(
                        Yup.object().shape({
                            enabled: Yup.boolean(),
                        })
                    ),
                })
            )
            .required('Required')
            .test(
                'regionsOptions',
                t('form.validator.enableAtLeastOneOption').toString(),
                regionsOptions => regionsOptions ? regionsOptions.some(region =>
                    region.subValues && region.subValues.some(country => country.enabled)
                ) : false
            ),
        riskGroupCodeOptions: Yup.array()
            .of(
                Yup.object().shape({
                    enabled: Yup.boolean(),
                })
            )
            .required('Required')
            .test(
                'riskGroupCodeOptions',
                t('form.validator.enableAtLeastOneOption').toString(),
                riskGroupCodeOptions => riskGroupCodeOptions ? riskGroupCodeOptions.some(option => option.enabled) : false
            ).required('Required'),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            onSubmitParams(values);
            setSubmitting(false);
            handleNext(formik.isValid);
        },
    });

    return (
        <Grid container direction='column' wrap='nowrap' height='100%' pt={2}>
            <Grid item pl='20px' mb={2}
                sx={(theme) => ({
                    bgcolor: theme.palette.transparentBlack,
                    borderRadius: '10px'
                })} >
                <List dense={true}
                    sx={{
                        listStyleType: 'disc',
                        pl: 2,
                        '& .MuiListItem-root': {
                            display: 'list-item',
                            pl: 0,
                        },
                    }}>
                    <ListItem>
                        <Typography>
                            <strong>{t('form.fields.process')}:</strong> {fee.processName}
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            <strong>{t('form.fields.userType')}:</strong> {t('enum.userType.' + fee.userProfileType)}
                        </Typography>
                    </ListItem>
                    <ListItem>
                        <Typography>
                            <strong>{t('form.fields.currency')}:</strong> {selectedCurrency}
                        </Typography>
                    </ListItem>
                </List>
            </Grid>

            <FormikProvider value={formik}>
                <Form>
                    <FormControl fullWidth error={formik.touched.regionsOptions && !!formik.errors.regionsOptions}>
                        <InputLabel>{t('form.fields.regions')}</InputLabel>
                        <Field
                            component={Select}
                            name="regionsOptions"
                            multiple
                            value={formik.values.regionsOptions}
                            renderValue={() => {
                                return formik.values.regionsOptions
                                    .filter(region => region.enabled)
                                    .map(region => {
                                        const enabledCountriesCount = region.subValues?.filter(country => country.enabled).length;
                                        return `${region.text} (${enabledCountriesCount})`;
                                    }).join(', ');
                            }}
                            MenuProps={{
                                autoFocus: false,
                                PaperProps: { style: { maxHeight: 320 } },
                            }}
                            sx={{
                                '& .MuiAccordion-root .Mui-expanded': { mt: '0 !important' }
                            }}
                        >
                            <MenuItem value="selectAll"
                                sx={{
                                    borderBottom: '1px solid rgba(43, 43, 43, 0.06)',
                                    '&:last-child': {
                                        borderBottom: 'none'
                                    }
                                }}
                                onClick={(event) => {
                                    const areAllMarked = formik.values.regionsOptions.every(region => region.enabled);
                                    const updatedRegions = formik.values.regionsOptions.map(region => ({
                                        ...region,
                                        enabled: !areAllMarked,
                                        subValues: region.subValues?.map(country => ({
                                            ...country,
                                            enabled: !areAllMarked
                                        }))
                                    }));
                                    formik.setFieldValue('regionsOptions', updatedRegions);
                                    formik.setFieldTouched('regionsOptions', true, false);
                                    formik.validateField('regionsOptions');
                                }}>
                                <Checkbox
                                    indeterminate={formik.values.regionsOptions.some(region => region.enabled) && !formik.values.regionsOptions.every(region => region.enabled)}
                                    checked={formik.values.regionsOptions.every(region => region.enabled) && formik.values.regionsOptions.length > 0}
                                />
                                {t('form.buttons.selectAll')}
                            </MenuItem>
                            {formik.values.regionsOptions.map(region => {
                                const anySelected = region.subValues?.some(country => country.enabled);
                                const allSelected = region.subValues?.every(country => country.enabled);
                                const indeterminate = anySelected && !allSelected;

                                return (
                                    <Accordion
                                        key={region.value}
                                        expanded={expanded === region.value}
                                        onChange={() => setExpanded(expanded === region.value ? '' : region.value)}
                                        sx={{
                                            '& .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded': {
                                                minHeight: '48px',
                                            },
                                            '& .MuiAccordionSummary-root .MuiAccordionSummary-content': {
                                                my: 0.1
                                            },
                                            '&.Mui-expanded': {
                                                margin: 0
                                            },
                                        }}>
                                        <AccordionSummary expandIcon={<ChevronDown2 />}>
                                            <Grid container alignItems="center">
                                                <Checkbox
                                                    indeterminate={indeterminate}
                                                    checked={region.enabled && !indeterminate}
                                                    onClick={(event) => { event.stopPropagation(); }}
                                                    onChange={(event) => {
                                                        const updatedRegions = formik.values.regionsOptions.map(r => {
                                                            if (r.value === region.value) {
                                                                const newStatus = event.target.checked;
                                                                return {
                                                                    ...r,
                                                                    enabled: newStatus,
                                                                    subValues: r.subValues?.map(country => ({
                                                                        ...country,
                                                                        enabled: newStatus
                                                                    }))
                                                                };
                                                            }
                                                            return r;
                                                        });
                                                        formik.setFieldValue('regionsOptions', updatedRegions);
                                                        formik.setFieldTouched('regionsOptions', true, false);
                                                        formik.validateField('regionsOptions');
                                                    }}
                                                />
                                                <Typography>{region.text}</Typography>
                                            </Grid>
                                        </AccordionSummary>
                                        <AccordionDetails sx={{ p: 0 }}>
                                            {region.subValues?.map(country => (
                                                <MenuItem key={country.value} value={country.value}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        const updatedRegions = formik.values.regionsOptions.map(r => {
                                                            if (r.value === region.value) {
                                                                const updatedCountries = r.subValues?.map(c => {
                                                                    if (c.value === country.value) {
                                                                        return { ...c, enabled: !c.enabled };
                                                                    }
                                                                    return c;
                                                                });
                                                                return {
                                                                    ...r,
                                                                    enabled: updatedCountries?.some(c => c.enabled),
                                                                    subValues: updatedCountries
                                                                };
                                                            }
                                                            return r;
                                                        });
                                                        formik.setFieldValue('regionsOptions', updatedRegions);
                                                    }}
                                                    sx={{
                                                        pl: 4,
                                                        py: 0.1,
                                                        '&.Mui-selected': {
                                                            backgroundColor: 'transparent'
                                                        }
                                                    }}
                                                >
                                                    <Checkbox checked={country.enabled} />
                                                    <img
                                                        loading="lazy"
                                                        width="21"
                                                        height="14"
                                                        src={`https://flagcdn.com/w20/${alpha3ToAlpha2(country.value)?.toLowerCase()}.png`}
                                                        srcSet={`https://flagcdn.com/w40/${alpha3ToAlpha2(country.value)?.toLowerCase()}.png 2x`}
                                                        alt=""
                                                    />
                                                    <Typography sx={{ pl: 0.5 }}>{country.text}</Typography>
                                                </MenuItem>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                );
                            })}
                        </Field>
                        {formik.touched.regionsOptions && formik.errors.regionsOptions && (
                            <FormHelperText>
                                {typeof formik.errors.regionsOptions === 'string'
                                    ? formik.errors.regionsOptions
                                    : Array.isArray(formik.errors.regionsOptions)
                                        ? formik.errors.regionsOptions.join(', ')
                                        : 'Invalid selection'}
                            </FormHelperText>
                        )}
                    </FormControl>


                    <FormControl fullWidth error={formik.touched.riskGroupCodeOptions && !!formik.errors.riskGroupCodeOptions}>
                        <InputLabel>{t('form.fields.riskGroups')}</InputLabel>
                        <Field
                            component={Select}
                            disabled={!defaultRiskGroupCode}
                            name="riskGroupCodeOptions"
                            multiple
                            value={formik.values.riskGroupCodeOptions}
                            renderValue={() => {
                                return formik.values.riskGroupCodeOptions
                                    .filter(risk => risk.enabled)
                                    .map(risk => risk.text)
                                    .join(', ');
                            }}>
                            <MenuItem value="ALL" onClick={() => {
                                const newEnabledStatus = !formik.values.riskGroupCodeOptions.every(risk => risk.enabled);
                                const newRiskGroupCodeOptions = formik.values.riskGroupCodeOptions.map(risk => ({
                                    ...risk,
                                    enabled: newEnabledStatus
                                }));

                                formik.setFieldValue('riskGroupCodeOptions', newRiskGroupCodeOptions);
                                formik.setFieldTouched('riskGroupCodeOptions', true, false);
                                formik.validateField('riskGroupCodeOptions');
                            }}>
                                <Checkbox
                                    checked={formik.values.riskGroupCodeOptions.every(risk => risk.enabled)}
                                    indeterminate={formik.values.riskGroupCodeOptions.some(risk => risk.enabled) && !formik.values.riskGroupCodeOptions.every(risk => risk.enabled)}
                                />
                                <ListItemText primary={t('form.buttons.selectAll')} />
                            </MenuItem>

                            {formik.values.riskGroupCodeOptions.map((option, index) => (
                                <MenuItem key={option.value} value={option.value} onClick={() => {
                                    const newRiskGroupCodeOptions = formik.values.riskGroupCodeOptions.map((risk, idx) =>
                                        idx === index ? { ...risk, enabled: !risk.enabled } : risk
                                    );
                                    formik.setFieldValue('riskGroupCodeOptions', newRiskGroupCodeOptions);
                                    formik.setFieldTouched('riskGroupCodeOptions', true, false);
                                    formik.validateField('riskGroupCodeOptions');
                                }}>
                                    <Checkbox
                                        checked={option.enabled}
                                    />
                                    <ListItemText primary={option.text} />
                                </MenuItem>
                            ))}
                        </Field>
                        {formik.touched.riskGroupCodeOptions && formik.errors.riskGroupCodeOptions && (
                            <FormHelperText>
                                {typeof formik.errors.riskGroupCodeOptions === 'string'
                                    ? formik.errors.riskGroupCodeOptions
                                    : Array.isArray(formik.errors.riskGroupCodeOptions)
                                        ? formik.errors.riskGroupCodeOptions.join(', ')
                                        : 'Invalid selection'}
                            </FormHelperText>
                        )}
                    </FormControl>

                    <Box mt='auto' sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            variant='outlined'
                            color='secondary'
                            disabled={true}>
                            {t('form.buttons.back')}
                        </Button>
                        <Button type='submit' disabled={!formik.isValid}>
                            {t('form.buttons.next')}
                        </Button>
                    </Box>

                </Form >
            </FormikProvider>

        </Grid>
    );
};

export default FeeForkSelectParams;