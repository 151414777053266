import { SvgIcon } from '@mui/material';

const Pending = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#EFB930" fillOpacity="0.16" />
            <g clipPath="url(#clip0_13241_18400)">
                <circle cx="11.9993" cy="11.9998" r="5.83333" stroke="#EFB930" strokeWidth="1.1" />
                <path d="M12 9.6665V11.9998L13.4583 13.4582" stroke="#EFB930" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_13241_18400">
                    <rect width="14" height="14" fill="white" transform="translate(5 5)" />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);
export default Pending;