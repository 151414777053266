import { AppDispatch } from '@/store/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { showErrorNotification } from '@/utils/errors';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { FndSettingsStore, FndValue, FndValuePayload } from './fndSettingsTypes';

export const fndSettingsPaginationParamsInit = {
    filter: '',
    orderBy: 'lookupValuesId',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: FndSettingsStore = {
    loading: false,
    list: [],
    error: null,
    count: 0,
    pagination: fndSettingsPaginationParamsInit,
    filters: {
        [HeaderFilterType.LOOKUP_TYPES]: [],
    },
    filtersSet: false,
    typesLoading: false,
    types: [],
};

const slice = createSlice({
    name: 'fndSettings',
    initialState,
    reducers: {
        setList(state, action: PayloadAction<Array<FndValue>>) {
            state.list = action.payload;
        },
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload;
        },
        setError: (state, { payload }: PayloadAction<string>) => {
            state.error = payload;
        },
        setCount: (state, { payload }: PayloadAction<number>) => {
            state.count = payload;
        },
        setPagination: (state, { payload }: PayloadAction<PaginateParams>) => {
            state.pagination = payload;
        },
        // Filters
        setFilters: (state, action: PayloadAction<{ types: Array<any> }>) => {
            state.filters[HeaderFilterType.LOOKUP_TYPES] = action.payload.types;
            state.types = action.payload.types;
            state.filtersSet = true;
        },
        setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, options: Array<FilterOptionType> }>) => {
            state.filters[action.payload.filterType] = action.payload.options;
        },
        setFiltersSet(state, action: PayloadAction<{ set: boolean }>) {
            state.filtersSet = action.payload.set;
        },
        setLoadingTypes(state, action: PayloadAction<boolean>) {
            state.typesLoading = action.payload;
        }
    }
});

export const { setList, setLoading, setError, setCount, setPagination, setFilters, setFilter, setFiltersSet, setLoadingTypes } = slice.actions;

export const getFndList = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading(true));
            const response = await request.get(`/api/console/fnd-management/list`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take
                }
            });
            const { data } = response;
            dispatch(setList(data.list));
            dispatch(setCount(data.count));
            dispatch(setPagination(payload));
            dispatch(setLoading(false));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setLoading(false));
        }
    };
};

export const getLookupTypes = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingTypes(true));

            const response = await request.get('/api/console/fnd-management/types');
            const { data } = response;
            let types = []
            if (data) {
                types = data.map((type: any) => ({
                    value: type,
                    text: type,
                    enabled: true
                }));
            }

            dispatch(setFilters({ types }));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setFilter({ filterType: HeaderFilterType.LOOKUP_TYPES, options: [] }));
        } finally {
            dispatch(setLoadingTypes(false));
        }
    };
}


export const addFndLookupValue = async (payload: FndValuePayload): Promise<any> => {
    const response = await request.post('/api/console/fnd-management/value', payload);
    const { data } = response;
    return data;
};

export const editFndLookupValue = async (id: number, payload: FndValuePayload): Promise<any> => {
    const response = await request.put(`/api/console/fnd-management/value/${id}`, payload);
    const { data } = response;
    return data;
};


export const removeFndLookupValue = async (id: number): Promise<any> => {
    const response = await request.delete(`/api/console/fnd-management/value/${id}`);
    const { data } = response;
    return data;
};


export default slice.reducer;