import { Grid, List } from "@mui/material";
import ListDetailItem from "./ListDetailItem";

interface DetailItem {
    text: string;
    value: string | number;
    currency?: string;
    isAmount?: boolean;
}

interface Props {
    items: DetailItem[];
}

const DetailsShortList = ({ items }: Props) => {

    return (
        <Grid
            item
            xs={12}
            pl="20px"
            sx={(theme) => ({
                borderRadius: "10px",
                border: `1px solid ${theme.palette.transparentBlack}`,
                backgroundColor: theme.palette.transparentBlack,
            })}
        >
            <List
                dense
                sx={{
                    listStyleType: "disc",
                    pl: 2,
                    "& .MuiListItem-root": {
                        display: "list-item",
                        pl: 0,
                    },
                }}
            >
                {items.map((item, index) => (
                    <ListDetailItem
                        key={index}
                        label={item.text}
                        value={item.value}
                        currency={item.currency}
                        isAmount={item.isAmount}
                    />
                ))}
            </List>
        </Grid>
    );
};

export default DetailsShortList;