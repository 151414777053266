import { SvgIcon } from '@mui/material';

const Question = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#EFB930" fillOpacity="0.16" />
            <path d="M10 8.875C10 7.83947 10.8395 7 11.875 7C12.9105 7 13.75 7.83947 13.75 8.875C13.75 9.56245 13.38 10.1635 12.8284 10.4899C12.353 10.7711 11.875 11.1977 11.875 11.75V13" stroke="#EFB930" strokeWidth="1.4" strokeLinecap="round" />
            <circle cx="11.875" cy="16" r="1" fill="#EFB930" />
        </svg>
    </SvgIcon>
);
export default Question;