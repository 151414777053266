import request from "@/services/request";
import { AccountType, BillingResponse, CreatePayoutInvoicePayload, FinaceChartType, FinanceStore, FinanceSummaryType } from "./financeTypes";
import { showErrorNotification } from "@/utils/errors";
import { ChartCategoryEnum, ChartPeriodEnum } from "@/types/charts";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppDispatch } from "@/store/store";
import { PaginateParams, SortDirectionEnum } from "@/types/pagination";
import { format, setHours, setMilliseconds, setMinutes, setSeconds, subDays, subMonths } from "date-fns";
import { EmiInvoice } from "../emiBilling/emiBillingTypes";
import { BenificiaryIbanData } from "../platformInvoicesAndPayouts/platformInvoicesAndPayoutsTypes";
import { FinancialConfigurationsTab } from "../financialConfigurations/financialConfigurationsTypes";


export const invoiceDefaultPagination: PaginateParams = {
    filter: '',
    orderBy: 'invoiceDate',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};


const initialState: FinanceStore = {
    incomeChart: {
        list: [],
        period: ChartPeriodEnum.MONTH,
        loading: true
    },
    expenseChart: {
        list: [],
        period: ChartPeriodEnum.MONTH,
        loading: true,
        category: ChartCategoryEnum.ALL
    },
    accountList: [],
    accountSummary: [],
    expenseSummary: [],
    feeSummary: [],
    invoiceSummary: [],
    incomeTotal: null,
    loadingSummaries: {
        accountSummaryLoading: false,
        expenseSummaryLoading: false,
        feeSummaryLoading: false,
        invoiceSummaryLoading: false
    },
    incomeInvoices: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: invoiceDefaultPagination

    },
    expenseInvoices: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: invoiceDefaultPagination

    },
    billingInvoices: {
        loading: false,
        list: [],
        error: null,
        count: 0,
        pagination: invoiceDefaultPagination
    },
    financialConfigurations: {
        openedSubTab: FinancialConfigurationsTab.COUNTERPARTIES,
        filtersConfig: {
            [FinancialConfigurationsTab.COUNTERPARTIES]: {
                filtersSet: false,
                filters: []
            }
        }
    }
};

const slice = createSlice({
    name: 'finance',
    initialState,
    reducers: {
        setExpenseChartData(state, action: PayloadAction<Array<FinaceChartType>>) {
            state.expenseChart.list = action.payload;
        },
        setExpenseChartPeriod: (state, { payload }: PayloadAction<ChartPeriodEnum>) => {
            state.expenseChart.period = payload;
        },
        setExpenseChartLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.expenseChart.loading = payload;
        },
        setExpenseChartCategory: (state, { payload }: PayloadAction<ChartCategoryEnum>) => {
            state.expenseChart.category = payload;
        },
        setIncomeChartData(state, action: PayloadAction<Array<FinaceChartType>>) {
            state.incomeChart.list = action.payload;
        },
        setIncomeChartPeriod: (state, { payload }: PayloadAction<ChartPeriodEnum>) => {
            state.incomeChart.period = payload;
        },
        setIncomeChartLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.incomeChart.loading = payload;
        },
        setAccountList: (state, { payload }: PayloadAction<Array<AccountType>>) => {
            state.accountList = payload;
        },
        setAccountSummary: (state, { payload }: PayloadAction<Array<FinanceSummaryType>>) => {
            state.accountSummary = payload;
        },
        setExpenseSummary: (state, { payload }: PayloadAction<Array<FinanceSummaryType>>) => {
            state.expenseSummary = payload;
        },
        setFeeSummary: (state, { payload }: PayloadAction<Array<FinanceSummaryType>>) => {
            state.feeSummary = payload;
        },
        setInvoiceSummary: (state, { payload }: PayloadAction<Array<FinanceSummaryType>>) => {
            state.invoiceSummary = payload;
        },
        setExpenseInvoices: (state, { payload }: PayloadAction<{ list: Array<EmiInvoice>, count: number, pagination: PaginateParams }>) => {
            state.expenseInvoices.list = payload.list;
            state.expenseInvoices.count = payload.count;
            state.expenseInvoices.pagination = payload.pagination;
        },
        setLoadingSummary(state, action: PayloadAction<{ [key: string]: boolean }>) {
            state.loadingSummaries = { ...state.loadingSummaries, ...action.payload };
        },
        setExpenseInvoicesLoading: (state, action: PayloadAction<boolean>) => {
            state.expenseInvoices.loading = action.payload
        },
        setExpenseInvoicesError: (state, action: PayloadAction<string>) => {
            state.expenseInvoices.error = action.payload
        },
        setIncomeInvoices: (state, { payload }: PayloadAction<{ list: Array<EmiInvoice>, count: number, pagination: PaginateParams }>) => {
            state.incomeInvoices.list = payload.list;
            state.incomeInvoices.count = payload.count;
            state.incomeInvoices.pagination = payload.pagination;
        },
        setIncomeInvoicesLoading: (state, action: PayloadAction<boolean>) => {
            state.incomeInvoices.loading = action.payload
        },
        setIncomeInvoicesError: (state, action: PayloadAction<string>) => {
            state.incomeInvoices.error = action.payload
        },
        setIncomeTotal: (state, { payload }: PayloadAction<FinanceSummaryType | null>) => {
            state.incomeTotal = payload;
        },
        setBillingInvoices: (state, { payload }: PayloadAction<{ list: Array<EmiInvoice>, count: number, pagination: PaginateParams }>) => {
            state.billingInvoices.list = payload.list;
            state.billingInvoices.count = payload.count;
            state.billingInvoices.pagination = payload.pagination;
        },
        setBillingInvoicesLoading: (state, action: PayloadAction<boolean>) => {
            state.billingInvoices.loading = action.payload
        },
        setBillingInvoicesError: (state, action: PayloadAction<string>) => {
            state.billingInvoices.error = action.payload
        },
        // Financial configurations
        setSubTab(state, action: PayloadAction<FinancialConfigurationsTab>) {
            state.financialConfigurations.openedSubTab = action.payload;
        }
    }
});

export const {
    setExpenseChartData,
    setExpenseChartPeriod,
    setExpenseChartLoading,
    setExpenseChartCategory,
    setIncomeChartData,
    setIncomeChartPeriod,
    setIncomeChartLoading,
    setAccountList,
    setAccountSummary,
    setExpenseSummary,
    setFeeSummary,
    setInvoiceSummary,
    setExpenseInvoices,
    setExpenseInvoicesLoading,
    setExpenseInvoicesError,
    setIncomeInvoices,
    setIncomeInvoicesLoading,
    setIncomeInvoicesError,
    setIncomeTotal,
    setBillingInvoices,
    setBillingInvoicesLoading,
    setBillingInvoicesError,
    setLoadingSummary,
    setSubTab
} = slice.actions;


export default slice.reducer;


let expenseController: any;

export const getExpenseChartData = (period: ChartPeriodEnum, category: ChartCategoryEnum) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setExpenseChartLoading(true));
            if (expenseController) {
                expenseController.abort('Cancel previous api call');
            }
            expenseController = new AbortController();
            const barPeriod = getBarPeiod(period);
            const fromDateTime = getFromDateTime(period);
            const response = await request.get(`/api/console/finance/expense/chart/${barPeriod}`,
                {
                    params: {
                        ...({ category: category === ChartCategoryEnum.ALL ? '' : category }),
                        fromDateTime: fromDateTime
                    },
                    signal: expenseController.signal
                });

            if (response?.data) {
                dispatch(setExpenseChartData(response.data));
            }

            dispatch(setExpenseChartLoading(false));
        } catch (e: any) {
            if (e?.name && e.name !== 'CanceledError') {
                dispatch(setExpenseChartLoading(false));
            }
            dispatch(setExpenseChartData([]));
        }
    };
};

const getBarPeiod = (period: ChartPeriodEnum) => {
    switch (period) {
        case ChartPeriodEnum.WEEK:
            return ChartPeriodEnum.DAY
        case ChartPeriodEnum.MONTH:
            return ChartPeriodEnum.DAY
        case ChartPeriodEnum.YEAR:
            return ChartPeriodEnum.MONTH
        case ChartPeriodEnum.ALL:
            return ChartPeriodEnum.MONTH
        default:
            return ChartPeriodEnum.DAY
    }
};

const getFromDateTime = (period: ChartPeriodEnum) => {
    const now = new Date();
    let fromDate;

    switch (period) {
        case ChartPeriodEnum.WEEK:
            fromDate = subDays(now, 7);
            break;
        case ChartPeriodEnum.MONTH:
            fromDate = subDays(now, 30);
            break;
        case ChartPeriodEnum.YEAR:
            fromDate = subMonths(now, 12);
            break;
        case ChartPeriodEnum.ALL:
            fromDate = new Date('2023-01-01');
            break;
        default:
            fromDate = new Date('2023-01-01');
            break;
    }
    fromDate = setHours(fromDate, 0);
    fromDate = setMinutes(fromDate, 0);
    fromDate = setSeconds(fromDate, 0);
    fromDate = setMilliseconds(fromDate, 0);
    return format(fromDate, "yyyy-MM-dd'T'HH:mm:ss");
};

let incomeController: any;

export const getIncomeChartData = (period: ChartPeriodEnum) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setIncomeChartLoading(true));
            if (incomeController) {
                incomeController.abort('Cancel previous api call');
            }
            incomeController = new AbortController();

            const barPeriod = getBarPeiod(period);
            const fromDateTime = getFromDateTime(period);
            const response = await request.get(`/api/console/finance/income/chart/${barPeriod}`,
                {
                    params: { fromDateTime: fromDateTime },
                    signal: incomeController.signal
                });

            if (response?.data) {
                dispatch(setIncomeChartData(response.data));
            }

            dispatch(setIncomeChartLoading(false));
        } catch (e: any) {
            if (e?.name && e.name !== 'CanceledError') {
                dispatch(setIncomeChartLoading(false));
            }

            dispatch(setIncomeChartData([]));
        }
    };
};



export const getFinanceAccountList = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await request.get('/api/console/finance/account/details');
            const { data } = response;
            dispatch(setAccountList(data.filter((p: any) => p?.accountStatus === 'ACTIVE') || []));
            return data;
        } catch (e) {
            showErrorNotification(e);
        }
    };
};

export const getAccountSummary = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingSummary({ accountSummaryLoading: true }));
            const response = await request.get('/api/console/finance/account/summary');
            const data = response?.data || [];
            dispatch(setAccountSummary(data));
        }
        catch (e) {
            showErrorNotification(e);
        }
        finally {
            dispatch(setLoadingSummary({ accountSummaryLoading: false }));
        }
    }
};

export const getExpenseSummary = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingSummary({ expenseSummaryLoading: true }));
            const response = await request.get('/api/console/finance/expense/summary');
            const data = response?.data || [];
            dispatch(setExpenseSummary(data));
        }
        catch (e) {
            showErrorNotification(e);
        }
        finally {
            dispatch(setLoadingSummary({ expenseSummaryLoading: false }));
        }
    }
};


export const getFeeSummary = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingSummary({ feeSummaryLoading: true }));
            const response = await request.get('/api/console/finance/fee/summary');
            const data = response?.data || [];
            dispatch(setFeeSummary(data));
        }
        catch (e) {
            showErrorNotification(e);
        }
        finally {
            dispatch(setLoadingSummary({ feeSummaryLoading: false }));
        }
    }
};

export const getInvoiceSummary = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await request.get('/api/console/finance/invoice/summary');
            const data = response?.data || [];
            dispatch(setInvoiceSummary(data));
        }
        catch (e) {
            showErrorNotification(e);
        }
    }
};


export const getIncomeTotal = () => {
    return async (dispatch: AppDispatch) => {
        try {
            const response = await request.get('/api/console/finance/income/total');
            const data = response?.data[0] || null;
            dispatch(setIncomeTotal(data));
        }
        catch (e) {
            showErrorNotification(e);
        }
    }
};


export const getExpenseTotal = async (): Promise<Array<FinanceSummaryType>> => {
    try {
        const response = await request.get('/api/console/finance/expense/total');
        const { data } = response;
        return data;
    }
    catch (e) {
        showErrorNotification(e);
        return []
    }
};


export const getExpenseInvoices = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setExpenseInvoicesLoading(true));
            const response = await request.get('/api/console/finance/expense/invoices', {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take,
                }
            });
            const data = response?.data?.list || [];
            dispatch(setExpenseInvoices({ list: response?.data?.list || [], count: data?.count ?? 0, pagination: payload }));
            return data;
        } catch (e) {
            dispatch(setExpenseInvoicesError('Failed to load'));
        }
        finally {
            dispatch(setExpenseInvoicesLoading(false));
        }
    };
};

export const getIncomeInvoices = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setIncomeInvoicesLoading(true));
            const response = await request.get('/api/console/finance/income/invoices', {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take,
                }
            });
            const data = response?.data?.list || [];
            dispatch(setIncomeInvoices({ list: response?.data?.list || [], count: data?.count ?? 0, pagination: payload }));
            return data;
        } catch (e) {
            dispatch(setIncomeInvoicesError('Failed to load'));
        }
        finally {
            dispatch(setIncomeInvoicesLoading(false));
        }
    };
};


export const getBillingInvoices = (payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setBillingInvoicesLoading(true));
            const response = await request.get('/api/console/finance/billing/invoices', {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take,
                }
            });
            const data = response?.data?.list || [];
            dispatch(setBillingInvoices({ list: response?.data?.list || [], count: data?.count ?? 0, pagination: payload }));
            return data;
        } catch (e) {
            dispatch(setBillingInvoicesError('Failed to load'));
        }
        finally {
            dispatch(setBillingInvoicesLoading(false));
        }
    };
};


export const getBillingDetails = async (): Promise<BillingResponse | undefined> => {
    try {
        const response = await request.get('/api/console/finance/billing');
        return response?.data[0] || undefined;
    }
    catch (e) {
        showErrorNotification(e);
    }
};

export const getDataByIban = async (iban: string): Promise<BenificiaryIbanData> => {
    const { data } = await request.get(`/api/console/beneficiary/iban/${iban}`);
    return data;
};

export const ukChapsValidation = async (sortCode: string, accountNumber: string) => {
    const { data } = await request.get(`/api/console/beneficiary/uk-chaps/${sortCode}/${accountNumber}`);
    return data;
};

export const runOperation = async (payload: any, accountUserId: number, operation?: string) => {

    if (!operation) throw new Error('Operation not available');
    const response = await request.post('/api/console/operations/', { operation, userId: accountUserId, data: payload });
    const { data } = response;
    return data;
};


export const lookupClient = async (clientId: string, fromAccountId: number) => {
    try {
        const { data } = await request.get(`/api/console/finance/client/lookup?clientId=${clientId}&fromAccountId=${fromAccountId}`);
        return data;
    }
    catch (e) { return undefined }
};

export const validateWithdrawAddress = async (beneficiaryCryptoAddress: string, beneficiaryCryptoNetwork: string) => {
    const response = await request.get(`/api/console/beneficiary/wallet-address/lookup?beneficiaryCryptoAddress=${beneficiaryCryptoAddress}&beneficiaryCryptoNetwork=${beneficiaryCryptoNetwork}`);
    const { data } = response;
    return data;
};

export const createPayoutInvoice = async (payload: CreatePayoutInvoicePayload) => {
    const response = await request.post(`/api/console/finance/create/payout/invoice`, payload);
    return response?.data;
};