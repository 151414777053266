import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { useState } from "react";

interface LoadingButtonProps extends ButtonProps {
    loading?: boolean;
}

const LoadingButton = ({ loading, onClick, ...props }: LoadingButtonProps) => {

    const [isLoading, setIsLoading] = useState(false);

    const handleClick = async (event: any) => {
        setIsLoading(true);
        onClick && await onClick(event);
        setIsLoading(false);
    }

    return <Button {...props} onClick={handleClick}>
        {isLoading || loading === true ? <CircularProgress size={20} color="inherit" /> : props.children}
    </Button>
}

export default LoadingButton;