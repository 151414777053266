import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import request from '../../services/request';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';
import { FlagDocumentPayload, RisksTransactionStore } from './risksTransactionTypes';
import { addFiles } from '@/utils/files';
import { getDocumentTypeOptions, getTranferList } from '../transfers/transfersSlice';
import { AccountTypeEnum } from '../accounts/accountsTypes';
import { DocumentTypeEnum, DocumentTypeWBH, TrasferStoreKeyEnum } from '../transfers/transfersTypes';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { getWlpName } from '@/components/dataGrid/utils/Platform';
import { Wlp } from '../user/types';

export const paginationParamsInit = {
    filter: 'status=APPROVAL,LEGAL_APPROVAL',
    orderBy: 'documentDate',
    sort: SortDirectionEnum.DESC,
    take: 25,
    skip: 0
};

const initialState: RisksTransactionStore = {
    assignedToMe: {
        [TrasferStoreKeyEnum.ALL]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit,
            filters: {
                [HeaderFilterType.WLPS]: [],
                [HeaderFilterType.TYPE]: [],
                [HeaderFilterType.CLIENT_ID]: []
            },
            filtersSet: false
        },
        [TrasferStoreKeyEnum.BANK]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit,
            filters: {
                [HeaderFilterType.WLPS]: [],
                [HeaderFilterType.TYPE]: [],
                [HeaderFilterType.CLIENT_ID]: []
            },
            filtersSet: false
        },
        [TrasferStoreKeyEnum.WALLET]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit,
            filters: {
                [HeaderFilterType.WLPS]: [],
                [HeaderFilterType.TYPE]: [],
                [HeaderFilterType.CLIENT_ID]: []
            },
            filtersSet: false
        },
        [TrasferStoreKeyEnum.TRUST]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit,
            filters: {
                [HeaderFilterType.WLPS]: [],
                [HeaderFilterType.TYPE]: [],
                [HeaderFilterType.CLIENT_ID]: []
            },
            filtersSet: false
        },
        [TrasferStoreKeyEnum.TOKEN]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit,
            filters: {
                [HeaderFilterType.WLPS]: [],
                [HeaderFilterType.TYPE]: [],
                [HeaderFilterType.CLIENT_ID]: []
            },
            filtersSet: false
        },
        [TrasferStoreKeyEnum.CRYPTO_TRANSFER]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit,
            filters: {
                [HeaderFilterType.WLPS]: [],
                [HeaderFilterType.TYPE]: [],
                [HeaderFilterType.CLIENT_ID]: []
            },
            filtersSet: false
        },
        [TrasferStoreKeyEnum.TOP_UP_BY_CARD]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: { ...paginationParamsInit, filter: 'TYPE=TOPUP_FROM_CARD' },
            filters: {
                [HeaderFilterType.WLPS]: [],
                [HeaderFilterType.TYPE]: [],
                [HeaderFilterType.CLIENT_ID]: []
            },
            filtersSet: false
        },
    },
    notAssignedToMe: {
        [TrasferStoreKeyEnum.ALL]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit,
            filters: {
                [HeaderFilterType.WLPS]: [],
                [HeaderFilterType.TYPE]: [],
                [HeaderFilterType.CLIENT_ID]: []
            },
            filtersSet: false
        },
        [TrasferStoreKeyEnum.BANK]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit,
            filters: {
                [HeaderFilterType.WLPS]: [],
                [HeaderFilterType.TYPE]: [],
                [HeaderFilterType.CLIENT_ID]: []
            },
            filtersSet: false
        },
        [TrasferStoreKeyEnum.WALLET]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit,
            filters: {
                [HeaderFilterType.WLPS]: [],
                [HeaderFilterType.TYPE]: [],
                [HeaderFilterType.CLIENT_ID]: []
            },
            filtersSet: false
        },
        [TrasferStoreKeyEnum.TRUST]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit,
            filters: {
                [HeaderFilterType.WLPS]: [],
                [HeaderFilterType.TYPE]: [],
                [HeaderFilterType.CLIENT_ID]: []
            },
            filtersSet: false
        },
        [TrasferStoreKeyEnum.TOKEN]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit,
            filters: {
                [HeaderFilterType.WLPS]: [],
                [HeaderFilterType.TYPE]: [],
                [HeaderFilterType.CLIENT_ID]: []
            },
            filtersSet: false
        },
        [TrasferStoreKeyEnum.CRYPTO_TRANSFER]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: paginationParamsInit,
            filters: {
                [HeaderFilterType.WLPS]: [],
                [HeaderFilterType.TYPE]: [],
                [HeaderFilterType.CLIENT_ID]: []
            },
            filtersSet: false
        },
        [TrasferStoreKeyEnum.TOP_UP_BY_CARD]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: { ...paginationParamsInit, filter: 'TYPE=TOPUP_FROM_CARD' },
            filters: {
                [HeaderFilterType.WLPS]: [],
                [HeaderFilterType.TYPE]: [],
                [HeaderFilterType.CLIENT_ID]: []
            },
            filtersSet: false
        },
    }
};

const slice = createSlice({
    name: 'risksTransaction',
    initialState,
    reducers: {
        setRisksTransactionList(state, action: PayloadAction<{ storeKey: TrasferStoreKeyEnum, list: Array<DocumentTypeWBH>, assignedToMe: boolean }>) {
            if (action.payload.assignedToMe) state.assignedToMe[action.payload.storeKey].list = action.payload.list;
            else state.notAssignedToMe[action.payload.storeKey].list = action.payload.list;
        },
        setLoading: (state, action: PayloadAction<{ storeKey: TrasferStoreKeyEnum, loading: boolean, assignedToMe: boolean }>) => {
            if (action.payload.assignedToMe) state.assignedToMe[action.payload.storeKey].loading = action.payload.loading;
            else state.notAssignedToMe[action.payload.storeKey].loading = action.payload.loading;
        },
        setError: (state, action: PayloadAction<{ storeKey: TrasferStoreKeyEnum, error: string, assignedToMe: boolean }>) => {
            if (action.payload.assignedToMe) state.assignedToMe[action.payload.storeKey].error = action.payload.error;
            else state.notAssignedToMe[action.payload.storeKey].error = action.payload.error;
        },
        setCount: (state, action: PayloadAction<{ storeKey: TrasferStoreKeyEnum, count: number, assignedToMe: boolean }>) => {
            if (action.payload.assignedToMe) state.assignedToMe[action.payload.storeKey].count = action.payload.count;
            else state.notAssignedToMe[action.payload.storeKey].count = action.payload.count;
        },
        setPagination: (state, action: PayloadAction<{ storeKey: TrasferStoreKeyEnum, pagination: PaginateParams, assignedToMe: boolean }>) => {
            if (action.payload.assignedToMe) state.assignedToMe[action.payload.storeKey].pagination = action.payload.pagination;
            else state.notAssignedToMe[action.payload.storeKey].pagination = action.payload.pagination;
        },
        setFilters: (state, action: PayloadAction<{ storeKey: TrasferStoreKeyEnum, wlps: Array<Wlp>, assignedToMe?: boolean }>) => {
            const wlpsOptions = action.payload.wlps.map(item => ({
                value: item.wlpId,
                text: getWlpName(item.wlpId),
                enabled: true
            }));
            const typeOptions = getDocumentTypeOptions();
            if (action.payload.assignedToMe) {
                state.assignedToMe[action.payload.storeKey].filters[HeaderFilterType.WLPS] = wlpsOptions;
                state.assignedToMe[action.payload.storeKey].filters[HeaderFilterType.TYPE] = typeOptions;
                state.assignedToMe[action.payload.storeKey].filtersSet = true;
            }
            else {
                state.notAssignedToMe[action.payload.storeKey].filters[HeaderFilterType.WLPS] = wlpsOptions;
                state.notAssignedToMe[action.payload.storeKey].filters[HeaderFilterType.TYPE] = typeOptions;
                state.notAssignedToMe[action.payload.storeKey].filtersSet = true;
            }
        },
        setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, storeKey: TrasferStoreKeyEnum, assignedToMe?: boolean, options: Array<FilterOptionType> }>) => {
            if (action.payload.assignedToMe) state.assignedToMe[action.payload.storeKey].filters[action.payload.filterType] = action.payload.options;
            else state.notAssignedToMe[action.payload.storeKey].filters[action.payload.filterType] = action.payload.options;
        },
        setFiltersSet(state, action: PayloadAction<{ storeKey: TrasferStoreKeyEnum, assignedToMe?: boolean, set: boolean }>) {
            if (action.payload.assignedToMe) {
                state.assignedToMe[action.payload.storeKey].filtersSet = action.payload.set;
            }
            else {
                state.notAssignedToMe[action.payload.storeKey].filtersSet = action.payload.set;
            }
        }
    }
});
export default slice.reducer;

export const { setRisksTransactionList, setLoading, setError, setCount, setPagination, setFilters, setFilter, setFiltersSet } = slice.actions;

export const getRiskTransactions = (storeKey: TrasferStoreKeyEnum, payload: PaginateParams, assignedToMe: boolean = false, accountTypes?: Array<AccountTypeEnum>, documentTypes?: Array<DocumentTypeEnum>) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ storeKey, loading: true, assignedToMe }));
            const data = await getTranferList(payload, assignedToMe, 'V2', accountTypes, documentTypes);
            dispatch(setRisksTransactionList({ storeKey, list: data.list, assignedToMe }));
            dispatch(setCount({ storeKey, count: data.count, assignedToMe }));
            dispatch(setPagination({ storeKey, pagination: payload, assignedToMe }));
            dispatch(setLoading({ storeKey, loading: false, assignedToMe }));
            return data;
        } catch (e: any) {
            if (e?.name && e.name !== 'CanceledError') {
                showErrorNotification(e);
                dispatch(setLoading({ storeKey, loading: false, assignedToMe }));
            }
        }
    };
};

export const flagDocument = async (payload: FlagDocumentPayload, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);
    formData.append("enable", payload.enable);
    formData.append("documentNumber", payload.documentNumber);
    formData.append("entityId", payload.entityId);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/document/flag",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};
