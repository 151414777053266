import { SvgIcon } from '@mui/material';

const Error = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <path d="M8.09785 11.2775C9.80022 8.25918 10.6514 6.75 11.9993 6.75C13.3473 6.75 14.1985 8.25918 15.9008 11.2775L16.113 11.6537C17.5276 14.1619 18.235 15.416 17.5957 16.333C16.9564 17.25 15.3748 17.25 12.2115 17.25H11.7872C8.62393 17.25 7.04228 17.25 6.403 16.333C5.76372 15.416 6.47105 14.1619 7.88571 11.6537L8.09785 11.2775Z" stroke="#EF3030" strokeWidth="1.1" />
            <path d="M12 9.6665V12.5832" stroke="#EF3030" strokeWidth="1.1" strokeLinecap="round" />
            <circle cx="11.9993" cy="14.3333" r="0.583333" fill="#EF3030" />
        </svg>
    </SvgIcon>
);
export default Error;