import request from "../../services/request";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ComplianceAssignmentTypes, ComplianceOfficersResponse, ComplianceOverviewStatistics, ComplianceStore, ComplianceTab, UserComplianceData, UserFlaggedHistory, UserHistoryEntityTypeEnum, } from "./complianceTypes";
import { addFiles } from "@/utils/files";

const initialState: ComplianceStore = {
    [ComplianceTab.CHECKS]: {
        openedSubTab: 0,
    },
    [ComplianceTab.ASSIGNED_TO_ME]: {
        openedSubTab: 0,
    },
    [ComplianceTab.TRANSACTIONS]: {
        openedSubTab: 0,
    },
    [ComplianceTab.MONITORING]: {
        openedSubTab: 0,
    },
    [ComplianceTab.USER_RISK_GROUPS]: {
        openedSubTab: 0,
    },
    [ComplianceTab.USER_RISK_LEVELS]: {
        openedSubTab: 0,
    },
    [ComplianceTab.REPORTING]: {
        openedSubTab: 0,
    },
};

const slice = createSlice({
    name: "compliance",
    initialState,
    reducers: {
        setSubTab(state, action: PayloadAction<{ tab: ComplianceTab; subTab: number }>) {
            state[action.payload.tab].openedSubTab = action.payload.subTab;
        },
    },
});

export default slice.reducer;

export const { setSubTab } = slice.actions;

export const flagUser = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);
    formData.append("enable", payload.enable);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/user/flag",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const blockUser = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);
    formData.append("enable", payload.enable);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/user/action/block",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const createUserAutoAccounts = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/user/action/create-auto-accounts",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const suspendUser = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);
    formData.append("enable", payload.enable);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/user/action/suspend",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const startComplianceRequest = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/user/compliance-request",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const updateUserKYC = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);
    formData.append("service", payload.service);
    formData.append("serviceId", payload.serviceId);
    formData.append("checkId", payload.checkId);
    formData.append("kycId", payload.kycId);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/kyc/user/update",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const createUserKYC = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);
    formData.append("service", payload.service);
    formData.append("serviceId", payload.serviceId);
    formData.append("checkId", payload.checkId);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/kyc/user/create",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const syncWithKycProvider = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);
    formData.append("applicantId", payload.applicantId);
    formData.append("provider", payload.provider);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/kyc/sync",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const resetApplicant = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);
    formData.append("applicantId", payload.applicantId);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/kyc/sumsub/reset",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const approveRiskLevel = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);
    formData.append("riskLevelId", payload.riskLevelId);
    formData.append("currentRiskLevel", payload.currentRiskLevel);
    formData.append("newRiskLevel", payload.newRiskLevel);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/kyc/user/risk-level/approve",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const recalculateRiskScore = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/kyc/user/risk-score/calculate",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const approveRiskScore = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);
    payload.type && formData.append("historyType", payload.type);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/kyc/user/risk-score/approve",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const toogleUserPoa = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);
    formData.append("poaId", payload.poaId);
    formData.append("status", payload.status);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/kyc/user/poa/toogle",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const toogleBusinessSection = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);
    formData.append("formId", payload.formId);
    formData.append("status", payload.status);
    formData.append("sectionType", payload.sectionType);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/user/business/section/action",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const updateBusinessSection = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);
    formData.append("sectionId", payload.sectionId);
    formData.append("sectionValues", JSON.stringify(payload.values));

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/user/business/section/update",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const toogleBusinessPayment = async (payload: any, files: File[]) => {
    const formData = new FormData();
    formData.append("userId", payload.userId.toString());
    formData.append("reason", payload.reason);
    formData.append("id", payload.kycPaymentId);
    formData.append("status", payload.status);

    addFiles(formData, files);

    const response = await request.post(
        "/api/console/compliance/user/business/payment/status",
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const downloadBusinessComplianceFile = async (key: string, name: string) => {
    const response = await request.get(`/api/console/compliance/user/business/download/${key}`,
        {
            responseType: "blob",
            params: {
                cacheBustTimestamp: Date.now(),
            }
        }
    );

    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.className = "hidden";
    document.body.appendChild(link);

    link.onclick = function () {
        requestAnimationFrame(function () {
            URL.revokeObjectURL(url);
            setTimeout(() => link.remove(), 300);
        });
    };

    link.click();
};

export const uploadBusinessComplianceFile = async (
    payload: FormData
): Promise<any> => {
    const response = await request.post(
        "/api/console/compliance/user/business/upload/file",
        payload,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const downloadUserPoaFile = async (key: string, name: string) => {
    const response = await request.get(
        `/api/console/compliance/kyc/user/poa/download/${key}`,
        {
            responseType: "blob",
            params: {
                cacheBustTimestamp: Date.now(),
            },
        }
    );
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.className = "hidden";
    document.body.appendChild(link);

    link.onclick = function () {
        requestAnimationFrame(function () {
            URL.revokeObjectURL(url);
            setTimeout(() => link.remove(), 300);
        });
    };

    link.click();
};

export const updateComplianceForm = async (payload: FormData) => {
    const response = await request.post(
        "/api/console/compliance/user/business/form/action",
        payload,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const updateUserDocInfo = async (payload: FormData) => {
    const response = await request.post(
        "/api/console/compliance/kyc/user/update/document",
        payload,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const updateUserResidency = async (payload: FormData) => {
    const response = await request.post(
        "/api/console/compliance/kyc/user/residency/update",
        payload,
        { headers: { "Content-Type": "multipart/form-data" } }
    );
    const { data } = response;
    return data;
};

export const getUserFlagHistory = async (
    entity: number,
    type: UserHistoryEntityTypeEnum
): Promise<UserFlaggedHistory[]> => {
    const response = await request.get(
        `/api/console/compliance/user/flag/history/type/${type}/${entity}`
    );
    const { data } = response;
    return data;
};

export const getComplianceOfficerList =
    async (): Promise<ComplianceOfficersResponse> => {
        const response = await request.get("/api/console/compliance/officer/list");
        const { data } = response;
        return data;
    };

export const getIndividualUserComplianceData = async (
    userId: number
): Promise<UserComplianceData> => {
    const response = await request.get(
        `/api/console/compliance/user/individual/${userId}`
    );
    const { data } = response;
    return data;
};

export const getBusinessUserComplianceData = async (
    userId: number
): Promise<any> => {
    const response = await request.get(
        `/api/console/compliance/user/business/${userId}`
    );
    const { data } = response;
    return data;
};

export const getBusinessRepresentativeComplianceData = async (
    userId: number
): Promise<any> => {
    const response = await request.get(
        `/api/console/compliance/user/representative/${userId}`
    );
    const { data } = response;
    return data;
};


export const assignComplianceOfficer = async (
    type: ComplianceAssignmentTypes,
    newAssign: boolean,
    entityId: number | undefined,
    officerId?: number
): Promise<any> => {
    const response = await request.post(
        "/api/console/compliance/officer/assing",
        { type, newAssign, entityId, officerId }
    );
    const { data } = response;
    return data;
};

export const downloadUserHistoryAttachment = async (
    key: string,
    name: string
) => {
    const response = await request.get(
        `/api/console/compliance/user/flag/history/file/${key}`,
        {
            responseType: "blob",
            params: {
                cacheBustTimestamp: Date.now(),
            },
        }
    );
    const url = window.URL.createObjectURL(response.data);
    const link = document.createElement("a");
    link.download = name;
    link.href = url;
    link.className = "hidden";
    document.body.appendChild(link);

    link.onclick = function () {
        requestAnimationFrame(function () {
            URL.revokeObjectURL(url);
            setTimeout(() => link.remove(), 300);
        });
    };

    link.click();
};

export const getOverviewStatistics = async (): Promise<ComplianceOverviewStatistics> => {
    const response = await request.get('/api/console/compliance/overview');
    const { data } = response;
    return data;
};


export const getOverviewUserStatusesStatistics = async (): Promise<any[]> => {
    const response = await request.get('/api/console/compliance/overview/statistics/status');
    const { data } = response;
    return data;
};

export const getOverviewNewUsersStatistics = async (period: string): Promise<any[]> => {
    const response = await request.get(`/api/console/compliance/overview/statistics/new-users/${period}`);
    const { data } = response;
    return data;
};
