import { Theme } from "@mui/material";
import { CSSProperties } from "react";
import Approval from "@/icons/documentsStatusIcons/Approval";
import Completed from "@/icons/documentsStatusIcons/Completed";
import Error from "@/icons/documentsStatusIcons/Error";
import Pending from "@/icons/documentsStatusIcons/Pending";
import Rejected from "@/icons/documentsStatusIcons/Rejected";
import Reverted from "@/icons/documentsStatusIcons/Reverted";
import Question from "@/icons/documentsStatusIcons/Question";


const successStatus = [
    'COMPLETED',
    'ACTIVE',
    'APPROVED',
    'APPROVED_BY_RISK_MANAGER',
    'APPROVED_BY_COMPLIANCE_MANAGER',
    'PAID',
    'VERIFIED',
    'USED',
    'PRIMARY',
    'SENT',
    'MANUALLY_PAID',
    'NEW_GREEN'
];

const warningStatus = [
    'FX_PENDING',
    'PENDING',
    'PENDING_FX',
    'INACTIVE',
    'ORDERED',
    'CARD_BLOCKED',
    'CARD_LOST',
    'PARTIAL',
    'PARTIALLY_PAID',
    'SUBMITTED',
    'APPROVAL',
    'LEGAL_APPROVAL',
    'TERMINATED',
    'PENDING_EXTERNALLY',
    'PLANNED',
    'UNVERIFIED',
    'EXPIRED',
    'UNKNOWN',
    'MATCHED',
    'IN_REVIEW',
    'IN REVIEW',
    'DONE',
    'CREATED',
    'SKIP_KYC',
    'UPCOMING',
    'OFFER',
    'COMPLETED_WARNING',
    'PARTIALLY_IMPORTED',
    'PARTIALLY_PAID'
];

const greyStatus = ['PENDING_UNASSIGNED_PAYMENT', 'DRAFT', 'NOT_PAID', 'NEW'];

const errorStatus = ['REJECTED', 'ERROR', 'FAILED', 'DISABLED', 'BLOCKED', 'LATE', 'CANCELLED'];

const blueStatus = ['CANCELED', 'REVERTED', 'SUSPENDED', 'LOST', 'STOLEN', 'EXECUTED', 'ISSUED'];

export const getStatusColor = (status: string, theme: Theme, isText?: boolean, withBackground: boolean = true) => {

    const result: CSSProperties = {
        backgroundColor: theme.palette.success.main,
        color: isText ? theme.palette.text.primary : 'white'
    };


    if (successStatus.includes(status)) {
        result.backgroundColor = theme.palette.success.light;
        result.color = theme.palette.success.main;
    }

    else if (warningStatus.includes(status)) {
        result.backgroundColor = theme.palette.warning.light;
        result.color = theme.palette.warning.main;
    }

    else if (errorStatus.includes(status)) {
        result.backgroundColor = theme.palette.error.light;
        result.color = theme.palette.error.main;
    }

    else if (blueStatus.includes(status)) {
        result.backgroundColor = theme.palette.ligthBlue;
        result.color = theme.palette.blue;
    }

    else if (greyStatus.includes(status)) {
        result.backgroundColor = theme.palette.transparentGreen;
        result.color = theme.palette.text.primary;
    }

    else {

        if (status.includes('ACTIVE') && !status.includes('INACTIVE')) {
            result.backgroundColor = theme.palette.warning.main;
            if (isText) result.color = theme.palette.warning.main;
        }
        else if (status.includes('BLOCKED')) {
            result.backgroundColor = theme.palette.error.main;
            if (isText) result.color = theme.palette.error.main;
        }
        else {
            result.backgroundColor = theme.palette.transparentBlack;
            result.color = theme.palette.text.primary;

        }

    }

    if (!withBackground) {
        result.backgroundColor = undefined;
    }

    return result;
};

const documentSuccessStatus = ['COMPLETED'];
const documentRejectedStatus = ['REJECTED'];
const documentRevertedStatus = ['REVERTED'];
const documentApprovalStatus = ['LEGAL_APPROVAL', 'EMAIL_APPROVAL', 'APPROVAL', 'PENDING_FX'];
const documentPendingStatus = ['LEGAL_APPROVED', 'APPROVED', 'APPROVED_BY_RISK_MANAGER', 'APPROVED_BY_COMPLIANCE_MANAGER', 'NEW', 'PENDING', 'PENDING_EXTERNALLY'];
const documentErrorStatus = ['FAILED', 'ERROR', 'CANCELED', 'CANCELLED'];

export const getShortenerStatusIcon = (status: string) => {
    if (documentErrorStatus.includes(status)) {
        return <Error />
    } else if (documentSuccessStatus.includes(status)) {
        return <Completed />
    } else if (documentApprovalStatus.includes(status)) {
        return <Approval />
    } else if (documentPendingStatus.includes(status)) {
        return <Pending />
    } else if (documentRejectedStatus.includes(status)) {
        return <Rejected />
    } else if (documentRevertedStatus.includes(status)) {
        return <Reverted />
    } else {
        return <Question />
    }
}
