import DialogTitle from "@/components/DialogTitle";
import FormTextField from "@/components/form/FormTextField";
import { FeeEnum } from "@/redux/platformFees/platformFeesTypes";
import { PermissionType } from "@/redux/user/types";
import { useSelector } from "@/store/store";
import { checkWritePermission } from "@/utils/permissions";
import { Alert, AlertTitle, Button, Checkbox, DialogContent, Grid, List, ListItem, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from 'yup';
import FormNumericField from "@/components/form/FormNumericField";
import { showErrorNotification, showSuccessNotification } from "@/utils/errors";
import { createOrUpdateFee } from "@/redux/platformFees/platformFeesSlice";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { createOrUpdateWlpFee } from "@/redux/wlpFees/wlpFeesSlice";
import Bulb from "@/icons/Bulb";
import DialogWithStatus from "@/components/DialogWithStatus";
import CustomSelectNew from "@/components/CustomSelectNew";
import { WlpFee } from "@/redux/wlpFees/wlpFeesTypes";

export const getFeeFieldCount = (type: FeeEnum) => {
    if (type === FeeEnum.ONE_TIME_FEE || type === FeeEnum.FIXED_FEE) {
        return 2;
    } else if (type === FeeEnum.ONE_TIME_PLUS_MONTHLY_FEE || type === FeeEnum.VOLUME_FEE) {
        return 3;
    }
    return 1;
};

export interface FeeOption {
    value: FeeEnum;
    text: string;
    enabled: boolean;
};

export interface EditFeeFormData {
    feeOptions: FeeOption[];        // Select options
    type: FeeEnum;                  // Fee Type Enum
    feeMonth: number;               // Applies to "ONE_TIME_PLUS_MONTHLY_FEE"
    feeAmount: number;              // Used for "ONE_TIME_FEE"
    feePercent: number;             // Used for "VOLUME_FEE"
    minFee: number;                 // Used for "VOLUME_FEE"
    feeStartDateTime: Date | null;  // Date when the fee applies
    changeImmediately: boolean;     // Whether the change applies immediately
};

interface Props {
    platformFee: WlpFee;
    open: boolean;
    wlpId?: string;
    decimalScale: number;
    onClose: () => void;
    onComplete: () => void;
    showFutureFee?: boolean;
    showEmiFeeFromWlpSide?: boolean;
    onDeleteFee?: () => void;
};

const EditPlatformFeeForm = ({ open, platformFee, wlpId, decimalScale, onClose, onComplete, showFutureFee = false, onDeleteFee, showEmiFeeFromWlpSide }: Props) => {
    const { permissions } = useSelector((state) => state.user);

    const { t } = useTranslation();

    const volumeFixedFee = platformFee.feeType === FeeEnum.VOLUME_FEE && platformFee.currentFeePercent === 0;
    const monthFixedFee = platformFee.feeType === FeeEnum.ONE_TIME_PLUS_MONTHLY_FEE && platformFee.currentFeeMonth === 0;

    const feeOptions: FeeOption[] = platformFee.feeType === FeeEnum.VOLUME_FEE
        ? [
            {
                value: FeeEnum.VOLUME_FEE,
                text: t(`enum.feeCategoryCodeTypes.${FeeEnum.VOLUME_FEE}`).toString(),
                enabled: !volumeFixedFee,
            },
            {
                value: FeeEnum.FIXED_FEE,
                text: t(`enum.feeCategoryCodeTypes.${FeeEnum.FIXED_FEE}`).toString(),
                enabled: volumeFixedFee,
            },
        ]
        : platformFee.feeType === FeeEnum.ONE_TIME_PLUS_MONTHLY_FEE
            ? [
                {
                    value: FeeEnum.ONE_TIME_PLUS_MONTHLY_FEE,
                    text: t(`enum.feeCategoryCodeTypes.${FeeEnum.ONE_TIME_PLUS_MONTHLY_FEE}`).toString(),
                    enabled: !monthFixedFee,
                },
                {
                    value: FeeEnum.FIXED_FEE,
                    text: t(`enum.feeCategoryCodeTypes.${FeeEnum.FIXED_FEE}`).toString(),
                    enabled: monthFixedFee,
                },
            ]
            : [];

    // TODO
    // add permission
    const writePermission = checkWritePermission(permissions, PermissionType.PLATFORMS_FEES);

    const initFeeType = (volumeFixedFee || monthFixedFee) ? FeeEnum.FIXED_FEE : platformFee.feeType;
    const initFeeMonth = !showFutureFee ? platformFee.currentFeeMonth : (showEmiFeeFromWlpSide ? platformFee.futureEmiFeeMonth : platformFee.futureFeeMonth);
    const initFeeAmount = !showFutureFee ? platformFee.currentFeeAmount : (showEmiFeeFromWlpSide ? platformFee.futureEmiFeeAmount : platformFee.futureFeeAmount);
    const initFeePercent = !showFutureFee ? platformFee.currentFeePercent : (showEmiFeeFromWlpSide ? platformFee.futureEmiFeePercent : platformFee.futureFeePercent);
    const initMinFee = !showFutureFee ? platformFee.currentMinFee : (showEmiFeeFromWlpSide ? platformFee.futureEmiMinFee : platformFee.futureMinFee);
    const countryCode = platformFee.countryCode;
    const countryName = platformFee.countryName;
    const regionCode = platformFee.regionCode;
    const regionName = platformFee.regionName;
    const feeStartDateTime = !showFutureFee ? null : (showEmiFeeFromWlpSide ? new Date(platformFee.futureEmiStartDate + 'Z') : new Date(platformFee.futureStartDate + 'Z'));
    const changeImmediately = !showFutureFee;

    const initialValues = {
        feeOptions: feeOptions,         // Select
        type: initFeeType,
        feeMonth: initFeeMonth,         // ONE_TIME_PLUS_MONTHLY_FEE
        feeAmount: initFeeAmount,       // ONE_TIME_FEE
        feePercent: initFeePercent,     // VOLUME_FEE
        minFee: initMinFee,             // VOLUME_FEE
        feeStartDateTime: feeStartDateTime,
        changeImmediately: changeImmediately
    };

    const validationSchema = Yup.object({
        type: Yup.string()
            .required(t('form.validator.required').toString()),
        feeMonth: Yup.number()
            .typeError(t('form.validator.required').toString())
            .required(t('form.validator.required').toString())
            .min(0, t('form.validator.nonNegative').toString()),
        feeAmount: Yup.number()
            .typeError(t('form.validator.required').toString())
            .required(t('form.validator.required').toString())
            .min(0, t('form.validator.nonNegative').toString()),
        feePercent: Yup.number()
            .typeError(t('form.validator.required').toString())
            .required(t('form.validator.required').toString())
            .min(0, t('form.validator.nonNegative').toString()),
        minFee: Yup.number()
            .typeError(t('form.validator.required').toString())
            .required(t('form.validator.required').toString())
            .min(0, t('form.validator.nonNegative').toString()),
        feeStartDateTime: Yup.date()
            .nullable()
            .when("changeImmediately", {
                is: false,
                then: Yup.date().typeError(t('form.validator.required').toString())
                    .required(t('form.validator.required').toString())
                    .min(new Date(), t('form.validator.feeFutureDate').toString()),
                otherwise: Yup.date().nullable()
            }),
        feeOptions: Yup.array()
            .of(
                Yup.object().shape({
                    value: Yup.string().required(),
                    text: Yup.string().required(),
                    enabled: Yup.boolean().required()
                })
            )
            .test(
                'at-least-one-enabled',
                t('form.validator.enableAtLeastOneOption').toString(),
                (feeOptions) => {
                    if (platformFee.feeType === FeeEnum.MANUAL_FEE || platformFee.feeType === FeeEnum.ONE_TIME_FEE) return true;
                    return feeOptions ? feeOptions.some(option => option.enabled) : false;
                }
            ),
    });

    const submit = async (formData: EditFeeFormData) => {
        try {
            const { feeMonth, feeAmount, feePercent, minFee, feeStartDateTime, changeImmediately } = formData;

            const startDateTime = (feeStartDateTime && !changeImmediately) ? feeStartDateTime.toISOString().split('.')[0] : null;

            const { ccy, feeType, userProfileType, riskGroupCode } = platformFee;

            if (wlpId) {
                const payload = {
                    platformFeePayload: {
                        wlpId: wlpId,
                        process: platformFee.process,
                        ccy: ccy,
                        fee: feeType,
                        userProfileType: userProfileType,
                        riskGroupCode: riskGroupCode === "0-DEFAULT" ? null : riskGroupCode,
                        countryCode: countryCode === "0-DEFAULT" ? null : countryCode,
                        regionCode: regionCode === "0-DEFAULT" ? null : regionCode,
                        feeAmount: feeAmount,
                        feeMonth: feeMonth,
                        feePercent: feePercent,
                        minFee: minFee,
                        invoiceItemCode: null,
                        ...(startDateTime ? { feeStartDateTime: startDateTime } : {}),
                    }
                };
                await createOrUpdateFee(wlpId, payload)
            } else {
                const payload = {
                    wlpFeePayload: {
                        process: platformFee.process,
                        ccy: ccy,
                        fee: feeType,
                        userProfileType: userProfileType,
                        riskGroupCode: riskGroupCode === "0-DEFAULT" ? null : riskGroupCode,
                        countryCode: countryCode === "0-DEFAULT" ? null : countryCode,
                        regionCode: regionCode === "0-DEFAULT" ? null : regionCode,
                        feeAmount: feeAmount,
                        feeMonth: feeMonth,
                        feePercent: feePercent,
                        minFee: minFee,
                        ...(startDateTime ? { feeStartDateTime: startDateTime } : {}),
                    }
                };
                await createOrUpdateWlpFee(platformFee.wlpId, payload);
            }
            onComplete();
            showSuccessNotification(t('emiFinance.feeUpdated'));
        }
        catch (e) {
            showErrorNotification(e);
        }
    };

    return (
        <DialogWithStatus
            open={open}
            onClose={onClose}
            fullWidth>
            <DialogTitle onClose={onClose}>
                {showFutureFee ? t('fees.updateFutureFee') : t('fees.updateFee')}
            </DialogTitle>

            <DialogContent sx={{ pb: 0 }}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={submit}>
                    {({ ...formik }) => {
                        const handleTypeChange = (value: string) => {
                            formik.setFieldValue('type', value);
                            formik.setFieldValue('feeMonth', 0);
                            formik.setFieldValue('feeAmount', 0);
                            formik.setFieldValue('feePercent', 0);
                            formik.setFieldValue('minFee', 0);
                        };
                        return (
                            <Form>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <Grid item pl='20px' mt={2}
                                        sx={(theme) => ({
                                            bgcolor: theme.palette.transparentBlack,
                                            borderRadius: '10px'
                                        })} >
                                        <List dense={true}
                                            sx={{
                                                listStyleType: 'disc',
                                                pl: 2,
                                                '& .MuiListItem-root': {
                                                    display: 'list-item',
                                                    pl: 0,
                                                },
                                            }}>
                                            <ListItem>
                                                <Typography>
                                                    <strong>{t('form.fields.process')}:</strong> {platformFee.processName}
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <Typography>
                                                    <strong>{t('form.fields.userType')}:</strong> {t('enum.userType.' + platformFee.userProfileType)}
                                                </Typography>
                                            </ListItem>
                                            <ListItem>
                                                <Typography>
                                                    <strong>{t('form.fields.currency')}:</strong> {platformFee.ccy}
                                                </Typography>
                                            </ListItem>
                                        </List>
                                    </Grid>
                                    <Grid container rowGap={2.5} pt={2}>
                                        {(
                                            formik.values.type === FeeEnum.VOLUME_FEE ||
                                            formik.values.type === FeeEnum.ONE_TIME_PLUS_MONTHLY_FEE ||
                                            formik.values.type === FeeEnum.FIXED_FEE) ? (
                                            <Grid item xs={12}>
                                                <Typography variant='h3'>
                                                    {1}. {t('fees.feeType')}
                                                </Typography>
                                                <CustomSelectNew
                                                    name="feeOptions"
                                                    label={t('form.fields.feeType')}
                                                    onChange={(event) => handleTypeChange(event.target.value as string)}
                                                    disabled={showEmiFeeFromWlpSide}
                                                />
                                            </Grid>
                                        ) :
                                            <Grid item xs={12}>
                                                <Typography variant='h3'>
                                                    {1}. {t('fees.feeType')}
                                                </Typography>
                                                <FormTextField
                                                    name="type"
                                                    fullWidth
                                                    label={' '}
                                                    value={t(`enum.feeCategoryCodeTypes.${formik.values.type}`).toString()}
                                                    disabled={true} />
                                            </Grid>
                                        }
                                        {formik.values.type === FeeEnum.ONE_TIME_FEE && (
                                            <Grid item xs={12}>
                                                <Typography variant='h3'>
                                                    {2}. {t('fees.oneTimeFee')}
                                                </Typography>
                                                <FormNumericField
                                                    name='feeAmount'
                                                    value={formik.values.feeAmount}
                                                    decimalScale={decimalScale}
                                                    fullWidth
                                                    label={t('form.fields.value')}
                                                    adornmentEnd={platformFee.ccy}
                                                    errors={formik.errors.feeAmount}
                                                    disabled={showEmiFeeFromWlpSide}
                                                />
                                            </Grid>
                                        )}
                                        {(formik.values.type === FeeEnum.ONE_TIME_PLUS_MONTHLY_FEE || (formik.values.type === FeeEnum.FIXED_FEE && platformFee.feeType === FeeEnum.ONE_TIME_PLUS_MONTHLY_FEE)) && (
                                            <>
                                                {!(formik.values.type === FeeEnum.FIXED_FEE) &&
                                                    <Grid item xs={12}>
                                                        <Typography variant='h3'>
                                                            {2}. {t('fees.monthlyFee')}
                                                        </Typography>
                                                        <FormNumericField
                                                            name='feeMonth'
                                                            value={formik.values.feeMonth}
                                                            decimalScale={decimalScale}
                                                            fullWidth
                                                            label={t('form.fields.value')}
                                                            adornmentEnd={platformFee.ccy}
                                                            errors={formik.errors.feeMonth}
                                                            disabled={showEmiFeeFromWlpSide}
                                                        />
                                                    </Grid>}
                                                <Grid item xs={12}>
                                                    <Typography variant='h3'>
                                                        {formik.values.type === FeeEnum.FIXED_FEE ? `2. ${t('fees.fixedFee')}` : `3. ${t('fees.oneTimeFee')}`}
                                                    </Typography>
                                                    <FormNumericField
                                                        name='feeAmount'
                                                        value={formik.values.feeAmount}
                                                        decimalScale={decimalScale}
                                                        fullWidth
                                                        label={t('form.fields.value')}
                                                        adornmentEnd={platformFee.ccy}
                                                        errors={formik.errors.feeAmount}
                                                        disabled={showEmiFeeFromWlpSide}
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                        {(formik.values.type === FeeEnum.VOLUME_FEE || (formik.values.type === FeeEnum.FIXED_FEE && platformFee.feeType === FeeEnum.VOLUME_FEE)) && (
                                            <>
                                                {!(formik.values.type === FeeEnum.FIXED_FEE) &&
                                                    <Grid item xs={12}>
                                                        <Typography variant='h3'>
                                                            {2}. {t('fees.chooseValue')}
                                                        </Typography>
                                                        <FormNumericField
                                                            name='feePercent'
                                                            value={formik.values.feePercent}
                                                            decimalScale={2}
                                                            fullWidth
                                                            label={t('form.fields.value')}
                                                            adornmentEnd={'%'}
                                                            errors={formik.errors.feePercent}
                                                            maxLimit={100}
                                                            disabled={showEmiFeeFromWlpSide}
                                                        />
                                                    </Grid>}
                                                <Grid item xs={12}>
                                                    <Typography variant='h3'>
                                                        {formik.values.type === FeeEnum.FIXED_FEE ? `2. ${t('fees.fixedFee')}` : `3. ${t('fees.chooseMin')}`}
                                                    </Typography>
                                                    <FormNumericField
                                                        name='minFee'
                                                        value={formik.values.minFee}
                                                        decimalScale={decimalScale}
                                                        fullWidth
                                                        label={t('form.fields.value')}
                                                        adornmentEnd={platformFee.ccy}
                                                        errors={formik.errors.minFee}
                                                        disabled={showEmiFeeFromWlpSide}
                                                    />
                                                </Grid>
                                            </>
                                        )}

                                        <Grid item xs={12}>
                                            <Typography variant='h3'>
                                                {getFeeFieldCount(formik.values.type) + 1}. {t('fees.feeChangeDate')}
                                            </Typography>
                                            <Grid item xs={12}>
                                                <DateTimePicker
                                                    disablePast
                                                    ampm={false}
                                                    format="dd/MM/yyyy HH:mm"
                                                    sx={{ width: '100%' }}
                                                    value={formik.values.feeStartDateTime}
                                                    label={t('form.fields.date')}
                                                    viewRenderers={{
                                                        hours: null,
                                                        minutes: null,
                                                        seconds: null,
                                                    }}
                                                    onChange={(value) => {
                                                        formik.setFieldValue("changeImmediately", false, true);
                                                        formik.setFieldValue("feeStartDateTime", value, true)
                                                    }}
                                                    slotProps={{
                                                        textField: {
                                                            variant: "outlined",
                                                            error: formik.touched.feeStartDateTime && Boolean(formik.errors.feeStartDateTime),
                                                            helperText: formik.touched.feeStartDateTime && formik.errors.feeStartDateTime
                                                        }
                                                    }}
                                                    disabled={showEmiFeeFromWlpSide}
                                                />
                                            </Grid>
                                            <Grid container item mt={1.5}>
                                                <Checkbox
                                                    sx={{ p: 0 }}
                                                    checked={formik.values.changeImmediately}
                                                    onChange={(event) => {
                                                        formik.setFieldValue("changeImmediately", event.target.checked, true);
                                                        formik.setFieldValue("feeStartDateTime", null, true);
                                                    }}
                                                />
                                                <Typography variant="body2" pl={1}>{t('fees.changeImmediately')}</Typography>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid container rowGap={2} mt={2}>
                                        <Grid item xs={12}>
                                            <Alert severity="info" icon={<Bulb />}>
                                                <AlertTitle sx={{ mt: 0.2, mb: 0 }}>{t('fees.pleaseNote')}</AlertTitle>
                                                <List dense sx={{ listStyleType: 'disc', pl: 2, '& .MuiListItem-root': { display: 'list-item', pl: 0 } }}>
                                                    {showFutureFee && (
                                                        <ListItem>
                                                            <Typography>
                                                                {t('platforms.fees.futureFeeScheduledOn', {
                                                                    date: new Date(platformFee.futureStartDate + 'Z').toLocaleString('en-GB', {
                                                                        year: 'numeric',
                                                                        month: '2-digit',
                                                                        day: '2-digit',
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                        hour12: false
                                                                    })
                                                                })}
                                                            </Typography>
                                                        </ListItem>
                                                    )}
                                                    <ListItem>
                                                        <Typography>
                                                            {regionCode === "0-DEFAULT" && countryCode === "0-DEFAULT"
                                                                ? t('fees.platformsFeeUpdateInfoAllRegions')
                                                                : countryCode === "0-DEFAULT"
                                                                    ? t('fees.platformsFeeUpdateInfo', { region: regionName })
                                                                    : t('fees.platformsFeeUpdateInfoCountry', { country: countryName })
                                                            }
                                                        </Typography>
                                                    </ListItem>
                                                </List>
                                            </Alert>
                                        </Grid>
                                    </Grid>

                                    <Grid container item justifyContent='space-between' mt='auto' pt={2} pb={2}>
                                        <Grid item xs="auto" mt='auto'>
                                            <Button color='secondary' variant='outlined' onClick={onClose}>{t('form.buttons.discard')}</Button>
                                        </Grid>
                                        {!showEmiFeeFromWlpSide &&
                                            <Grid container item xs="auto" mt='auto' columnGap={1}>
                                                {showFutureFee &&
                                                    <Button onClick={onDeleteFee} size='small' variant='outlined' color="error">
                                                        {t('form.buttons.deleteFee')}
                                                    </Button>
                                                }
                                                <Button type='submit' disabled={!writePermission || formik.isSubmitting}>{t('form.buttons.save')}</Button>
                                            </Grid>}

                                    </Grid>
                                </LocalizationProvider>
                            </Form>
                        );
                    }}
                </Formik>

            </DialogContent>
        </DialogWithStatus >
    );
};

export default EditPlatformFeeForm;