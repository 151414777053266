import * as React from "react";
import { SvgIcon } from '@mui/material';

const FndSettingsIcon = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.5" d="M16.667 5L2.50032 5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path opacity="0.5" d="M8.33301 9.1665L2.49967 9.1665" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path opacity="0.5" d="M8.33301 13.3335H2.49967" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
                <path d="M11.667 11.2502L13.417 13.3335L16.667 9.16683" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </svg>
    </SvgIcon>
);
export default FndSettingsIcon;

