import * as React from "react";
import { SvgIcon } from '@mui/material';

const Vault = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <path d="M1.66602 9.99984C1.66602 6.07147 1.66602 4.10728 2.8864 2.88689C4.10679 1.6665 6.07098 1.6665 9.99935 1.6665C13.9277 1.6665 15.8919 1.6665 17.1123 2.88689C18.3327 4.10728 18.3327 6.07147 18.3327 9.99984C18.3327 13.9282 18.3327 15.8924 17.1123 17.1128C15.8919 18.3332 13.9277 18.3332 9.99935 18.3332C6.07098 18.3332 4.10679 18.3332 2.8864 17.1128C1.66602 15.8924 1.66602 13.9282 1.66602 9.99984Z" stroke="currentColor" strokeWidth="1.3" />
            <path opacity="0.5" d="M5 5.8335L5 14.1668" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
            <path d="M9.16602 10C9.16602 8.61929 10.2853 7.5 11.666 7.5C13.0467 7.5 14.166 8.61929 14.166 10C14.166 11.3807 13.0467 12.5 11.666 12.5C10.2853 12.5 9.16602 11.3807 9.16602 10Z" stroke="currentColor" strokeWidth="1.3" />
            <path opacity="0.5" d="M13.75 7.9165L15 6.6665" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
            <path opacity="0.5" d="M8.33398 13.3335L9.58398 12.0835" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
            <path opacity="0.5" d="M9.58398 7.9165L8.33398 6.6665" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
            <path opacity="0.5" d="M15 13.3335L13.75 12.0835" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default Vault;