import Loader from "@/components/Loader";
import AssetCellWithUrl from "@/components/dataGrid/renderCell/AssetCellWithUrl";
import SwitchCell from "@/components/dataGrid/renderCell/SwitchCell";
import { changeDefaultPaymentsConfig, editPaymentsConfig, enableDisablePaymentsConfig, getPlatformPaymentsConfig } from "@/redux/platformInvoicesAndPayouts/platformInvoicesAndPayoutsSlice";
import { EditPaymentConfigPayload, PaymentConfig } from "@/redux/platformInvoicesAndPayouts/platformInvoicesAndPayoutsTypes";
import { defaultPageOptions, defaultPageSize } from "@/utils/constant";
import { showErrorNotification, showSuccessNotification } from "@/utils/errors";
import { Box, Button, Grid, Stack, useTheme } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef, GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { TFunction } from "i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PayeeConfigCell from "./PayeeConfigCell";
import Search from "@/pages/platforms/components/Search";
import Plus from "@/icons/Plus";
import TypographyWithEllipsis from "@/components/TypographyWithEllipsis";
import PaymentConfigStatusCell from "@/components/dataGrid/renderCell/PaymentConfigStatusCell";
import AddPaymentConfig from "./AddPaymentConfig";
import TrashDelete from "@/icons/TrashDelete";
import { RowActionType } from "@/types/rowActions";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import EditChangePencil from "@/icons/EditChangePencil";
import { useParams } from "react-router";
import { InvoiceParty, InvoicePartyRoleEnum } from "@/redux/emiBilling/emiBillingTypes";
import { getInvoiceParties } from "@/redux/emiBilling/emiBillingSlice";

const getConfigColumn = (
    t: TFunction,
    actionItems: Array<RowActionType<PaymentConfig>>,
    handleSwitchEnabled: (checked: boolean, configId: number) => void,
    handleSwitchDefault: (configId: number) => void
): Array<GridColDef> => {
    const columns: Array<GridColDef> = [
        {
            field: 'currencyCode',
            headerName: t('form.fields.asset').toString(),
            flex: 1.2,
            renderCell: AssetCellWithUrl,
        },
        {
            field: 'disabled',
            headerName: t('form.fields.enabled').toString(),
            flex: 0.8,
            renderCell: (params: GridRenderCellParams) => {
                const isChecked = !params.value;
                return (
                    <SwitchCell
                        checked={isChecked}
                        disabled={params.row.isDefault}
                        onChange={(value) => handleSwitchEnabled(value, params.row.id)}
                    />
                );
            }
        },
        {
            field: 'isDefault',
            headerName: t('form.fields.primaryConfig').toString(),
            flex: 0.8,
            renderCell: (params: GridRenderCellParams) => {
                return <PaymentConfigStatusCell
                    value={params.value}
                    onClick={() => handleSwitchDefault(params.row.id)} />
            },
        },
        {
            field: 'paymentWalletId',
            headerName: t('form.fields.remitter').toString(),
            flex: 1,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                return <TypographyWithEllipsis variant="body2" color='text.primary' sx={{ fontWeight: 500 }}>
                    {params.value}
                </TypographyWithEllipsis>
            }
        },
        {
            field: 'partyName',
            headerName: t('form.fields.beneficiaryName').toString(),
            flex: 1,
        },
        {
            field: 'iban',
            headerName: t('form.fields.beneficiary').toString(),
            flex: 1.5,
            sortable: false,
            renderCell: (params: GridRenderCellParams) => {
                return <PayeeConfigCell paymentConfig={params.row} />;
            },
        },
        {
            field: 'paymentDescription',
            headerName: t('form.fields.paymentDescription').toString(),
            flex: 1,
            sortable: false
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: t('form.fields.actions').toString(),
            headerAlign: 'center',
            align: 'center',
            flex: 1,
            sortable: false,
            getActions: (params: GridRowParams) => actionItems.map(item => {
                const icon: JSX.Element = ((params.row.isUsed || params.row.isDefault) && item.iconOpposite && item.key === "edit") ? item.iconOpposite : item.icon;
                const isDisabled: boolean = item.key === "edit" && (params.row.isUsed || params.row.isDefault);

                return (
                    <GridActionsCellItem
                        icon={icon}
                        disabled={isDisabled}
                        onClick={() => { item.onClick(params.row) }}
                        label=""
                    />
                )
            })
        }

    ];
    return columns;
};

const PaymentsConfig = () => {
    const { wlpId } = useParams();

    const { t } = useTranslation();
    const theme = useTheme();

    const [loading, setLoading] = useState(true);

    const [configs, setConfigs] = useState<Array<PaymentConfig>>([]);
    const [loadingConfigs, setLoadingConfigs] = useState(true);

    const [searchValue, setSearchValue] = useState('');

    const [openAddConfig, setOpenAddConfig] = useState(false);
    const [selectedConfig, setSelectedConfig] = useState<PaymentConfig | null>(null);
    const [openDelete, setOpenDelete] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);

    const [parties, setParties] = useState<Array<InvoiceParty>>([]);
    const [loadingParties, setLoadingParties] = useState(true);

    const getConfig = useCallback(() => {
        const get = async () => {
            setLoadingConfigs(true);
            try {
                if (wlpId) {
                    const data = await getPlatformPaymentsConfig(wlpId);
                    setConfigs(data);
                }
            } catch (e) {
                showErrorNotification(e);
            } finally {
                setLoadingConfigs(false);
            }
        };
        get();
    }, [wlpId])

    useEffect(() => {
        getConfig();
    }, [getConfig]);

    const getParties = useCallback(() => {
        const get = async () => {
            try {
                const data = await getInvoiceParties();
                const filtered = data.filter(party => party.partyRole === InvoicePartyRoleEnum.WLP && party.wlpId === wlpId && party.payable);
                setParties(filtered);
            } catch (e) {
                setParties([]);
                showErrorNotification(e);
            } finally {
                setLoadingParties(false);
            }
        };
        get();
    }, [wlpId])

    useEffect(() => {
        getParties();
    }, [getParties]);

    const filteredRows = searchValue === ''
        ? configs.filter(c => !c.isHidden)
        : configs.filter(c =>
            !c.isHidden && (
                c.paymentWalletId.toLowerCase().includes(searchValue.toLowerCase()) ||
                c.currencyCode?.toLowerCase().includes(searchValue.toLowerCase()) ||
                c.cryptoAddress?.toLowerCase().includes(searchValue.toLowerCase())
            )
        );

    const [loadingSwitch, setLoadingSwitch] = useState(false);

    const handleSwitchEnabled = useCallback((checked: boolean, configId: number) => {
        const post = async () => {
            if (wlpId) {
                try {
                    if (configId && !loadingSwitch) {
                        setLoadingSwitch(true);
                        const payload = {
                            wlpId: wlpId,
                            configId: configId
                        };
                        await enableDisablePaymentsConfig(payload);
                        setConfigs((prevConfigs) =>
                            prevConfigs.map((config) =>
                                config.id === configId ? { ...config, disabled: !checked } : config
                            )
                        );
                        showSuccessNotification(t('form.fields.configurationsUpdated'));
                    }
                } catch (e) {
                    showErrorNotification(e);
                } finally {
                    setLoadingSwitch(false);
                };
            }
        };
        post();
    }, [loadingSwitch, wlpId, t])

    const handleSwitchDefault = useCallback((configId: number) => {
        const post = async () => {
            if (wlpId) {
                try {
                    if (configId && !loadingSwitch) {
                        setLoadingSwitch(true);

                        const selectedConfig = configs.find(config => config.id === configId);
                        if (!selectedConfig) return;
                        if (selectedConfig.disabled) {
                            // Enable the config if it's disabled
                            const payload = {
                                wlpId: wlpId,
                                configId: configId
                            };
                            await enableDisablePaymentsConfig(payload);
                            setConfigs((prevConfigs) =>
                                prevConfigs.map((config) =>
                                    config.id === configId ? { ...config, disabled: false } : config
                                )
                            );
                        };

                        const payload = {
                            wlpId: wlpId,
                            configId: configId
                        };
                        await changeDefaultPaymentsConfig(payload);
                        setConfigs((prevConfigs) => {
                            const selectedConfig = prevConfigs.find(config => config.id === configId);
                            if (!selectedConfig) return prevConfigs;

                            return prevConfigs.map((config) =>
                                config.currencyCode === selectedConfig.currencyCode
                                    ? { ...config, isDefault: config.id === configId }
                                    : config
                            );
                        });
                        showSuccessNotification(t('form.fields.configurationsUpdated'));
                    }
                } catch (e) {
                    showErrorNotification(e);
                } finally {
                    setLoadingSwitch(false);
                };
            }
        };
        post();
    }, [loadingSwitch, wlpId, configs, t])

    const handleHide = async () => {
        if (wlpId) {
            try {
                if (selectedConfig && selectedConfig.id) {
                    const payload: EditPaymentConfigPayload = {
                        beneficiaryType: selectedConfig.beneficiaryType,
                        configId: selectedConfig.id,
                        bankName: selectedConfig.bankName,
                        bankCountry: selectedConfig.bankCountry,
                        bankAddress: selectedConfig.bankAddress,
                        swiftCode: selectedConfig.swiftCode,
                        beneficiaryName: selectedConfig.beneficiaryName,
                        beneficiaryAddress: selectedConfig.beneficiaryAddress,
                        registrationNumber: selectedConfig.beneficiaryIdNumber,
                        iban: selectedConfig.iban,
                        sortCode: selectedConfig.sortCode,
                        accountNumber: selectedConfig.accountNumber,
                        cryptoAddress: selectedConfig.cryptoAddress,
                        cryptoNetwork: selectedConfig.cryptoNetwork,
                        description: selectedConfig.paymentDescription,
                        hidden: true
                    }
                    await editPaymentsConfig(payload);
                    showSuccessNotification(t('emiBilling.configDeleted'));
                    setConfigs((prevConfigs) => {
                        const foundConfig = prevConfigs.find(config => config.id === selectedConfig.id);
                        if (!foundConfig) return prevConfigs;

                        return prevConfigs.map((config) =>
                            config.id === foundConfig.id
                                ? { ...config, isHidden: true }
                                : config
                        );
                    });
                }
            } catch (e) {
                showErrorNotification(e);
            } finally {
                setOpenDelete(false);
                setSelectedConfig(null);
            };
        }
    }

    const columns = useMemo(() => {
        const actionItems = [
            {
                key: "edit",
                icon: <EditChangePencil />,
                iconOpposite: <EditChangePencil color="#82918E66" />,
                onClick: (row: PaymentConfig) => {
                    setSelectedConfig(row);
                    setOpenEdit(true);
                }
            },
            {
                key: "hide",
                icon: <TrashDelete />,
                iconOpposite: <TrashDelete color="#82918E66" />,
                onClick: (row: PaymentConfig) => {
                    setSelectedConfig(row);
                    setOpenDelete(true);
                }
            }
        ];
        return getConfigColumn(t, actionItems, handleSwitchEnabled, handleSwitchDefault)
    }, [t, handleSwitchEnabled, handleSwitchDefault]);

    useEffect(() => {
        if (loadingParties || loadingConfigs) setLoading(true);
        else setLoading(false)
    }, [loadingParties, loadingConfigs]);

    if (loading) return <Loader />;

    return (
        <Grid container sx={{ ...theme.typography.mainContentPadding }}>
            <Grid container item xs={12}
                rowGap={1.2}
                sx={{
                    borderColor: theme.palette.transparentBlack,
                    borderRadius: 2,
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    bgcolor: theme.palette.white.main,
                }}>
                {loadingSwitch && <Loader />}
                <Box
                    sx={{
                        width: '100%',
                        '.MuiDataGrid-root': {
                            '& .MuiDataGrid-columnHeaders': {
                                borderColor: theme.palette.transparentBlack,
                                borderRadius: wlpId ? '' : '7px 7px 0 0',
                            },
                            '& .MuiDataGrid-row:first-of-type': {
                                borderLeft: 'none',
                                borderRight: 'none',
                                borderWidth: '0.5px',
                                borderColor: theme.palette.transparentBlack,
                            },
                            '& .MuiDataGrid-row:last-child': {

                                borderBottom: 'none',
                                borderWidth: '0.5px',
                                borderColor: theme.palette.transparentBlack,
                                borderRadius: '0 0 7px 7px',
                            },
                            '& .MuiDataGrid-row': {
                                borderLeft: 'none',
                                borderRight: 'none'
                            }
                        }
                    }}>
                    <Grid container>
                        <Grid item xs sx={{ ml: 2, py: 1.2 }}>
                            <Search inputValue={searchValue} setInputValue={setSearchValue} />
                        </Grid>
                        {((wlpId && openAddConfig) || (openEdit && selectedConfig !== null && wlpId)) && parties && parties.length > 0 &&
                            <AddPaymentConfig
                                open={openAddConfig || (openEdit && selectedConfig !== null)}
                                wlpId={wlpId}
                                config={selectedConfig}
                                parties={parties}
                                onClose={() => {
                                    setOpenAddConfig(false);
                                    setOpenEdit(false);
                                    setSelectedConfig(null);
                                }}
                                onComplete={getConfig} />}
                        <ConfirmationDialog
                            title={t('compliance.sar.confirmationRequired').toString()}
                            text={t('emiBilling.wishProceedPaymentConfigDeletion').toString()}
                            open={openDelete}
                            onClose={() => {
                                setOpenDelete(false);
                                setSelectedConfig(null);
                            }}
                            onConfirm={handleHide}
                            withIcon={false}
                        />
                        <Grid container item xs justifyContent='end'
                            sx={{
                                px: 2,
                                py: 1.2
                            }}>
                            <Button variant="outlined" color='secondary' onClick={() => setOpenAddConfig(true)} >
                                <Stack direction='row' columnGap={1}>
                                    <Plus sx={{
                                        fontSize: '0.813rem',
                                        margin: 'auto',
                                        color: 'inherit',
                                        'path': { strokeWidth: 3 }
                                    }} />
                                    {t('form.buttons.createConfig')}
                                </Stack>
                            </Button>
                        </Grid>
                    </Grid>

                    <DataGrid
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: defaultPageSize, page: 0 },
                            },
                            sorting: {
                                sortModel: [{ field: 'currencyCode', sort: 'asc' }]
                            },
                        }}
                        pageSizeOptions={defaultPageOptions}
                        rows={filteredRows}
                        getRowId={(row) => row.id}
                        autoHeight={true}
                        columns={columns}
                        disableRowSelectionOnClick={true}
                        hideFooter={true}
                        disableColumnMenu={true}
                        rowHeight={70}
                    />
                </Box>
            </Grid>
        </Grid>
    );
};

export default PaymentsConfig;