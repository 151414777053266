import * as React from "react";
import { SvgIcon } from '@mui/material';

const WlpSettingsIcon = (props: any) => (
    <SvgIcon {...props}>
        <svg width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="9.99984" cy="10.0001" r="1.66667" transform="rotate(180 9.99984 10.0001)" stroke="currentColor" strokeWidth="1.5" />
            <circle cx="16.6668" cy="11.6666" r="1.66667" transform="rotate(180 16.6668 11.6666)" stroke="currentColor" strokeWidth="1.5" />
            <circle cx="1.66667" cy="1.66667" r="1.66667" transform="matrix(-1 8.74228e-08 8.74228e-08 1 5 6.66675)" stroke="currentColor" strokeWidth="1.5" />
            <path opacity="0.5" d="M16.6665 10L16.6665 4.16667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            <path opacity="0.5" d="M3.3335 10L3.3335 15.8333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            <path opacity="0.5" d="M10 15.8334L10 11.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            <path opacity="0.5" d="M16.6665 15.8333L16.6665 13.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
            <path opacity="0.5" d="M10.0002 8.33341V4.16675M3.3335 4.16675L3.3335 6.38897" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
    </SvgIcon>
);
export default WlpSettingsIcon;

