import { Grid, IconButton, InputAdornment, Box as MuiBox, TextField, styled } from '@mui/material';
import Search2 from '@/icons/Search2';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { PaginateParams } from '@/types/pagination';
import XClose from '@/icons/XClose';

const BoxStyled = styled(MuiBox)(
    ({ theme, }) => ({
        p: 2,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid ' + theme.palette.secondary.main,
        borderRadius: '10px',
    }),
);

interface Props {
    anyFilterValue: string;
    paginationParams: PaginateParams;
    searchFilterName: string;
    setAnyFilterValue: (value: string) => void;
    onSearch: (pagination: PaginateParams) => void;
}

const AnySearchAdvanced = ({ anyFilterValue, paginationParams, searchFilterName, setAnyFilterValue, onSearch }: Props) => {
    const { t } = useTranslation();

    const filterName = searchFilterName;

    const submit = () => {
        const sanitizedFilterValue = anyFilterValue.replace(/\s+/g, ' ').replace(/;/g, '').trim();
        setAnyFilterValue(sanitizedFilterValue);

        const prevFilter = paginationParams.filter.split(';').filter(part => !part.startsWith(filterName)).join(';');
        if (isEmpty(sanitizedFilterValue) || sanitizedFilterValue.trim().length === 0) {
            const paginationState = { ...paginationParams, skip: 0, filter: `${prevFilter}` };
            onSearch(paginationState);
        } else {
            const paginationState = {
                ...paginationParams, skip: 0, filter: prevFilter ? `${prevFilter};${filterName}=${sanitizedFilterValue}` : `${filterName}=${sanitizedFilterValue}`
            };
            onSearch(paginationState);
        }
    };

    const clear = () => {
        setAnyFilterValue('');
        const prevFilter = paginationParams.filter.split(';').filter(part => !part.startsWith(filterName)).join(';');
        const paginationState = { ...paginationParams, filter: `${prevFilter}` };
        onSearch(paginationState);
    };

    return (
        <Grid container justifyContent='space-between'>
            <Grid container item xs={12}>
                <Grid item>
                    <BoxStyled>
                        <TextField
                            value={anyFilterValue}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setAnyFilterValue(event.target.value);
                            }}
                            onKeyDown={(ev: any) => {
                                if (ev.key === 'Enter') {
                                    submit();
                                }
                            }}
                            placeholder={t('form.buttons.search').toString()}
                            fullWidth
                            sx={{
                                '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'unset',
                                },
                                '& .MuiInputBase-root': {
                                    px: 0
                                },
                                minWidth: '330px'
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={submit}>
                                            <Search2 sx={{ fontSize: '0.813rem' }} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    anyFilterValue ? (
                                        <InputAdornment sx={{ mr: 0.5 }} position="end">
                                            <IconButton onClick={clear}>
                                                <XClose sx={{ fontSize: '1.063rem' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    ) : null
                                )
                            }} />
                    </BoxStyled >
                </Grid>
            </Grid >
        </Grid>

    );
}

export default AnySearchAdvanced;