import { SvgIcon } from '@mui/material';

const Rejected = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#2B2B2B" fillOpacity="0.06" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3163 5.3335C13.345 5.3335 13.3731 5.33592 13.4005 5.34056L13.4913 5.34103C13.6273 5.34103 13.7573 5.39636 13.852 5.49436L17.2287 9.0137C17.318 9.10636 17.368 9.23103 17.368 9.3597V15.5597C17.3713 17.2057 16.034 18.5597 14.3873 18.577L9.048 18.5777H9.02867C8.22867 18.5777 7.472 18.2677 6.90067 17.7024C6.32467 17.133 6.00467 16.373 6 15.5624V8.32703C6 6.68103 7.33933 5.34103 8.986 5.34103L13.2321 5.34056C13.2594 5.33592 13.2876 5.3335 13.3163 5.3335ZM12.816 6.34083L8.986 6.34103C7.89067 6.34103 7 7.23236 7 8.32703V15.5597C7.00333 16.1004 7.218 16.6097 7.604 16.9917C7.98933 17.3724 8.52067 17.591 9.04467 17.5777C9.047 17.5777 9.0665 17.5775 9.08592 17.5774L14.382 17.577C15.4767 17.565 16.37 16.6604 16.368 15.5604L16.368 9.98883L15.0276 9.9895C13.8076 9.98616 12.8163 8.9915 12.8163 7.77283L12.816 6.34083ZM10.7598 11.1016L11.562 11.9042L12.3646 11.1016C12.5599 10.9062 12.8759 10.9062 13.0713 11.1016C13.2666 11.2969 13.2666 11.6129 13.0713 11.8082L12.2687 12.6108L13.0705 13.4122C13.2658 13.6076 13.2658 13.9236 13.0705 14.1189C12.9731 14.2169 12.8451 14.2656 12.7171 14.2656C12.5891 14.2656 12.4611 14.2169 12.3638 14.1189L11.562 13.3175L10.7606 14.1189C10.6633 14.2169 10.5353 14.2656 10.4073 14.2656C10.2793 14.2656 10.1513 14.2169 10.0539 14.1189C9.8586 13.9236 9.8586 13.6076 10.0539 13.4122L10.8553 12.6108L10.0531 11.8082C9.8578 11.6129 9.8578 11.2969 10.0531 11.1016C10.2485 10.9062 10.5645 10.9062 10.7598 11.1016ZM13.816 6.9015L13.8163 7.77283C13.8163 8.44216 14.3603 8.9875 15.0289 8.9895L15.8193 8.98883L13.816 6.9015Z" fill="#EF3030" />
        </svg>
    </SvgIcon>
);
export default Rejected;