import DialogTitle from "@/components/DialogTitle";
import AttentionIcon from "@/icons/AttentionIcon";
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoadingButton from "./buttons/LoadingButton";


interface Props {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    text?: string;
    title?: string;
    withIcon?: boolean;
    confirmLoading?: boolean;
}

const ConfirmationDialog = ({ open, onConfirm, onClose, text, title, withIcon = true, confirmLoading = false }: Props) => {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: !withIcon ? '220px' : "300px",
                    maxHeight: "300px",
                    maxWidth: !withIcon ? '500px' : undefined
                },
            }}>
            <DialogTitle onClose={onClose}>
                {title ? title : ''}
            </DialogTitle>
            <DialogContent >
                {withIcon ?
                    <Grid container sx={{ marginBottom: 'auto', marginTop: 'auto', pt: 3, pb: 1 }}>
                        <Grid item xs={12} display='flex' justifyContent='center' mb={1}>
                            <AttentionIcon style={{ width: '64px', height: '64px' }} />
                        </Grid>
                        <Grid item xs={12} display='flex' justifyContent='center'>
                            <Typography variant='body1' textAlign='center'>{text ?? t('form.fields.confirmAction')}</Typography>
                        </Grid>
                    </Grid> :
                    <Grid container item mt={2}>
                        <Typography variant="body1">
                            {text}
                        </Typography>
                    </Grid>
                }
            </DialogContent>
            <DialogActions sx={{ px: 2.5, pb: 2 }}>
                <Grid item xs={12} container justifyContent='space-between' >
                    <Grid item xs="auto">
                        <Button variant='outlined' color='secondary' onClick={onClose}>{t('form.buttons.cancel')}</Button>
                    </Grid>
                    <Grid item xs="auto">
                        <LoadingButton onClick={onConfirm} color='primary' disabled={confirmLoading} loading={confirmLoading}>{t('form.buttons.confirm')}</LoadingButton>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;