import { GridRenderCellParams } from "@mui/x-data-grid";
import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import CopyNew from "@/icons/CopyNew";
import LinkIcon from "@/icons/Link";
import { useTranslation } from "react-i18next";
import CopyToClipboard from "react-copy-to-clipboard";
import { showSuccessNotification } from "@/utils/errors";

export const getTrimmedValue = (value: string, frontChars = 6, endChars = 6) => {
    if (!value || typeof value !== 'string') {
        return '';
    }

    if (value.length <= frontChars + endChars) {
        return value;
    }

    return `${value.substring(0, frontChars)}...${value.substring(value.length - endChars)}`;
};

const TransactionHashCell = (params: GridRenderCellParams) => {
    const { t } = useTranslation();

    if (params.value === null || params.value === undefined || params.value === '')
        return '';

    // BE issue
    const transactionHash =
        params.row.networkName === 'Ethereum (ERC-20)' && !params.value.startsWith('0x')
            ? `0x${params.value}`
            : params.value;

    const trimmedValue = getTrimmedValue(transactionHash);
    const url = params.row.scannerUrl ? `${params.row.scannerUrl}${transactionHash}` : '';

    return (
        <Grid container alignItems='center'>
            <Typography>
                <Tooltip
                    title={transactionHash}
                    placement="top"
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -5],
                                },
                            },
                        ],
                    }}>
                    <>{trimmedValue}</>
                </Tooltip>
            </Typography>
            <Grid item pl={0.5}>
                {url &&
                    <IconButton size="small" onClick={() => window.open(url, '_blank')}>
                        <LinkIcon sx={{ fontSize: '1rem', 'path': { strokeWidth: 2 } }} />
                    </IconButton>
                }
                <CopyToClipboard text={params.value} onCopy={() => showSuccessNotification(t('form.buttons.copiedSuccessfully'))}>
                    <IconButton size="small">
                        <CopyNew sx={{ fontSize: '1rem', 'path': { strokeWidth: 1.8 } }} />
                    </IconButton>
                </CopyToClipboard>
            </Grid>
        </Grid >

    );
};

export default TransactionHashCell;
