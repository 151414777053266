import { Dialog, DialogContent, Grid } from '@mui/material';
import DialogTitle from '@/components/DialogTitle';
import { useTranslation } from 'react-i18next';
import FeeForkSelectParams, { FormValues } from './FeeForkSelectParams';
import { FilterOptionType } from '@/components/dataGrid/CustomFilterHeader';
import { useState } from 'react';
import ForkPlatformFee from './ForkPlatformFee';
import { WlpFee } from '@/redux/wlpFees/wlpFeesTypes';

interface Props {
    fee: WlpFee;
    wlpId?: string;
    decimalScale: number;
    regionsOptions: Array<FilterOptionType>;
    riskGroupOptions: Array<FilterOptionType>;
    selectedCurrency: string;
    open: boolean;
    onClose: () => void;
    onComplete: () => void;
};

const ForkPlatformFeeForm = ({ fee, wlpId, decimalScale, riskGroupOptions, regionsOptions, selectedCurrency, open, onClose, onComplete }: Props) => {
    const { t } = useTranslation();

    const [activeStep, setActiveStep] = useState(0);

    const [regions, setRegions] = useState<Array<FilterOptionType>>(regionsOptions);
    const [riskGroups, setRiskGroups] = useState<Array<FilterOptionType>>(riskGroupOptions);

    const handleNext = (isFormValid: boolean) => {
        if (isFormValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const onCloseDialog = () => {
        onClose();
        setActiveStep(0);
        setRegions(regionsOptions);
        setRiskGroups(riskGroupOptions);
    };

    const onSubmitParams = async (formData: FormValues) => {
        const { regionsOptions, riskGroupCodeOptions } = formData;
        setRegions(regionsOptions);
        setRiskGroups(riskGroupCodeOptions);
    };

    return (
        <Dialog
            id="fork-platform-fee-form"
            open={open}
            onClose={onCloseDialog}
            fullWidth
            PaperProps={{
                sx: {
                    minHeight: '600px',
                    maxHeight: '700px',
                    maxWidth: '550px'
                }
            }}>

            <DialogTitle onClose={onCloseDialog}>
                {t('fees.createFee')}
            </DialogTitle>

            <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                    {activeStep === 0 && (
                        <Grid item xs={12}>
                            <FeeForkSelectParams
                                fee={fee}
                                regionsOptions={regions}
                                riskGroupCodeOptions={riskGroups}
                                selectedCurrency={selectedCurrency}
                                onSubmitParams={onSubmitParams}
                                handleNext={handleNext} />
                        </Grid>
                    )}
                    {activeStep === 1 && (
                        <Grid item xs={12}>
                            <ForkPlatformFee
                                platformFee={fee}
                                wlpId={wlpId}
                                decimalScale={decimalScale}
                                regionsOptions={regions}
                                riskGroupCodeOptions={riskGroups}
                                handleBack={handleBack}
                                onComplete={onComplete} />
                        </Grid>
                    )}
                </Grid>

            </DialogContent>

        </Dialog>
    );
};

export default ForkPlatformFeeForm;