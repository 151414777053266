import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BalanceType, SubTabsEnum, SystemBalanceGraphic } from "../emiFinance/emiFinanceTypes";
import { CryptoUserBreakdownType, EmiFinanceCryptoStore, UnassignedCryptoPaymentsType } from "./emiFinanceCryptoTypes";
import { AppDispatch } from "@/store/store";
import request from "@/services/request";
import { Payment, PaymentsSplit } from "../emiFinanceBanking/emiFinanceBankingTypes";
import { paginationParamsInit } from "../emiFinanceBanking/emiFinanceBankingSlice";
import { PaginateParams } from "@/types/pagination";
import { UnassignedPaymentsTabsEnum } from "@/pages/emiFinance/EmiFinanceBanking/UnassignedPayments";

export const getUnassignedPaymentsFilter = (type: string, activeType: UnassignedPaymentsTabsEnum, walletUuid: string): string => {
    if (activeType !== UnassignedPaymentsTabsEnum.ALL) {
        const status =
            activeType === UnassignedPaymentsTabsEnum.UNMATCHED
                ? `${UnassignedPaymentsTabsEnum.PENDING},${UnassignedPaymentsTabsEnum.UNMATCHED}`
                : activeType;
        return `transactionType=${type};status=${status};walletUuid=${walletUuid}`;
    }
    return `transactionType=${type};walletUuid=${walletUuid}`;
};

const initialState: EmiFinanceCryptoStore = {
    activeTab: SubTabsEnum.ALL,
    userBalances: [],
    fireblocks: [],
    exchanges: [],
    custody: [],
    loading: {
        userBalancesLoading: true,
        fireblocksLoading: true,
        exchangeOwnedLoading: true,
        exchangeUtilizedLoading: true,
    },
    userBreakdown: [],

    unassignedPayments: {
        [UnassignedCryptoPaymentsType.EXCHANGE]: {
            unassignedPaymentsSplitLoading: true,
            unassignedPaymentsSplit: [],
            data: {
                loading: false,
                count: 0,
                list: [],
                error: null,
                pagination: paginationParamsInit,
                uuid: null
            },
        },
        [UnassignedCryptoPaymentsType.FIREBLOCKS]: {
            unassignedPaymentsSplitLoading: true,
            unassignedPaymentsSplit: [],
            data: {
                loading: false,
                count: 0,
                list: [],
                error: null,
                pagination: paginationParamsInit,
                uuid: null
            },
        }
    }
};

const slice = createSlice({
    name: 'emiFinanceCrypto',
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<{ [key: string]: boolean }>) {
            state.loading = { ...state.loading, ...action.payload };
        },
        setUserBalances(state, action: PayloadAction<Array<BalanceType>>) {
            state.userBalances = action.payload;
        },
        setFireblocks(state, action: PayloadAction<Array<BalanceType>>) {
            state.fireblocks = action.payload;
        },
        setExchanges(state, action: PayloadAction<Array<BalanceType>>) {
            state.exchanges = action.payload;
        },
        setCustody(state, action: PayloadAction<Array<BalanceType>>) {
            state.custody = action.payload;
        },
        setUserBreakdown(state, action: PayloadAction<Array<CryptoUserBreakdownType>>) {
            state.userBreakdown = action.payload;
        },
        setActiveTab(state, action: PayloadAction<SubTabsEnum>) {
            state.activeTab = action.payload;
        },
        // Unassigned payments split
        setUnassignedPaymentsSplit(state, action: PayloadAction<{ split: Array<PaymentsSplit>, type: UnassignedCryptoPaymentsType }>) {
            state.unassignedPayments[action.payload.type].unassignedPaymentsSplit = action.payload.split;
        },
        setUnassignedPaymentsSplitLoading(state, action: PayloadAction<{ loading: boolean, type: UnassignedCryptoPaymentsType }>) {
            state.unassignedPayments[action.payload.type].unassignedPaymentsSplitLoading = action.payload.loading;
        },
        // Unassigned payments
        setPayments(state, action: PayloadAction<{ list: Array<Payment>, type: UnassignedCryptoPaymentsType }>) {
            state.unassignedPayments[action.payload.type].data.list = action.payload.list;
        },
        setCount(state, action: PayloadAction<{ count: number, type: UnassignedCryptoPaymentsType }>) {
            state.unassignedPayments[action.payload.type].data.count = action.payload.count;
        },
        setLoadingPayments(state, action: PayloadAction<{ loading: boolean, type: UnassignedCryptoPaymentsType }>) {
            state.unassignedPayments[action.payload.type].data.loading = action.payload.loading;
        },
        setPagination: (state, action: PayloadAction<{ pagination: PaginateParams, type: UnassignedCryptoPaymentsType }>) => {
            state.unassignedPayments[action.payload.type].data.pagination = action.payload.pagination;
        },
        clearUnassignedPayments(state, action: PayloadAction<{ type: UnassignedCryptoPaymentsType }>) {
            state.unassignedPayments[action.payload.type].data.list = [];
            state.unassignedPayments[action.payload.type].data.count = 0;
            state.unassignedPayments[action.payload.type].data.loading = false;
            state.unassignedPayments[action.payload.type].data.pagination = paginationParamsInit;
        },
        // Crypto uuid
        setCryptoUuid(state, action: PayloadAction<{ uuid: string, type: UnassignedCryptoPaymentsType }>) {
            state.unassignedPayments[action.payload.type].data.uuid = action.payload.uuid;
        },
    }
});

export const {
    setLoading,
    setUserBalances,
    setUserBreakdown,
    setFireblocks,
    setExchanges,
    setCustody,
    setActiveTab,
    setUnassignedPaymentsSplit,
    setUnassignedPaymentsSplitLoading,
    setPayments,
    setCount,
    setLoadingPayments,
    setPagination,
    clearUnassignedPayments,
    setCryptoUuid
} = slice.actions;

export default slice.reducer;

export const getCryptoClientGraphic = async (integration: string, dateFrom: string, dateTo: string): Promise<Array<SystemBalanceGraphic>> => {
    const response = await request.get(`/api/console/emi-finance/crypto/client/graphic/${integration}?dateFrom=${dateFrom}&dateTo=${dateTo}`);
    return response?.data;
};

export const getCryptoExchangeGraphic = async (integration: string, dateFrom: string, dateTo: string, uiType: string): Promise<Array<SystemBalanceGraphic>> => {
    const response = await request.get(`/api/console/emi-finance/crypto/exchange/graphic/${uiType}/${integration}?dateFrom=${dateFrom}&dateTo=${dateTo}`);
    return response?.data;
};

export const getCryptoCustomerBalancesAll = () => {
    return async (dispatch: AppDispatch) => {
        try {

            dispatch(setLoading({ customerBalancesLoading: true }));
            const response = await request.get(`/api/console/emi-finance/crypto/all/customers`);
            const data = response?.data || [];
            dispatch(setUserBalances(data));
        }
        catch (e) {
            dispatch(setUserBalances([]));
        }
        finally {
            dispatch(setLoading({ customerBalancesLoading: false }));
        }
    }
};

export const getCryptoCustomerBreakdown = () => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ userBalancesLoading: true }));
            const response = await request.get(`/api/console/emi-finance/crypto/customers/breakdown`);
            const data = response?.data || [];
            dispatch(setUserBreakdown(data));
        }
        catch (e) {
            dispatch(setUserBreakdown([]));
        }
        finally {
            dispatch(setLoading({ userBalancesLoading: false }));
        }
    }
};

export const getCryptoFireblocksAll = () => {
    return async (dispatch: AppDispatch) => {
        try {

            dispatch(setLoading({ fireblocksLoading: true }));
            const response = await request.get(`/api/console/emi-finance/crypto/all/fireblocks`);
            const data = response?.data || [];
            dispatch(setFireblocks(data));
        }
        catch (e) {
            dispatch(setFireblocks([]));
        }
        finally {
            dispatch(setLoading({ fireblocksLoading: false }));
        }
    }
};

export const getCryptoExchangeOwned = () => {
    return async (dispatch: AppDispatch) => {
        try {

            dispatch(setLoading({ exchangeOwnedLoading: true }));
            const response = await request.get(`/api/console/emi-finance/crypto/exchanges/owned`);
            const data = response?.data || [];
            dispatch(setExchanges(data));
        } catch (e) {
            dispatch(setExchanges([]));
        } finally {
            dispatch(setLoading({ exchangeOwnedLoading: false }));
        }
    }
};

export const getCryptoExchangeUtilized = () => {
    return async (dispatch: AppDispatch) => {
        try {

            dispatch(setLoading({ exchangeUtilizedLoading: true }));
            const response = await request.get(`/api/console/emi-finance/crypto/exchanges/utilized`);
            const data = response?.data || [];
            dispatch(setCustody(data));
        } catch (e) {
            dispatch(setCustody([]));
        } finally {
            dispatch(setLoading({ exchangeUtilizedLoading: false }));
        }
    }
};

export const getCryptoUnassignedPayments = (integration: string, type: UnassignedCryptoPaymentsType) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setUnassignedPaymentsSplitLoading({ loading: true, type: type }));
            const response = await request.get(`/api/console/emi-finance/banking/payments/split/${integration}`);
            const data = response?.data || [];
            dispatch(setUnassignedPaymentsSplit({ split: data, type: type }));
        } catch (e) {
            dispatch(setUnassignedPaymentsSplit({ split: [], type: type }));
        } finally {
            dispatch(setUnassignedPaymentsSplitLoading({ loading: false, type: type }));
        }
    }
};

export const getCryptoPaymentsList = (params: PaginateParams, type: UnassignedCryptoPaymentsType, integration: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingPayments({ loading: true, type: type }));
            const response = await request.get(`/api/console/emi-finance/banking/payments/list/${integration}`, {
                params: {
                    filter: params.filter,
                    orderBy: params.orderBy,
                    sort: params.sort.toUpperCase(),
                    skip: params.skip.toString(),
                    take: params.take,
                }
            });
            const { data } = response;
            dispatch(setPayments({ list: data.list, type: type }));
            dispatch(setCount({ count: data.count, type: type }));
            dispatch(setPagination({ pagination: params, type: type }));
        } catch (e) {
            dispatch(setPayments({ list: [], type: type }));
        } finally {
            dispatch(setLoadingPayments({ loading: false, type: type }));
        }
    }
};