import { FilterOptionType } from '@/components/dataGrid/CustomFilterHeader';
import { PaginateParams } from '@/types/pagination';
import { UserType } from '../user/types';
import { SubProcess } from '../cards/cardsTypes';

export interface CryptoVault {
    vaultId: string,
    asset: string,
    balance: number,
}

export interface AccountAddress {
    accountAddressId: number;
    address: string;
    token: string;
    network: string;
    label: string;
}

export interface AccountsStore {
    accountTabs: {
        [key: string]: {
            loading: boolean,
            list: Array<Account>,
            error: string | null,
            count: number,
            pagination: PaginateParams,
            filters: {
                [key: string]: Array<FilterOptionType>
            },
            filtersSet: boolean;
        }
    },
    accountSummaries: {
        [key: string]: {
            loading: boolean,
            summary: Array<AccountSummary>,
        }
    }
}

export enum SummaryTabType {
    STANDARD = 'STANDARD',
    CRYPTO = 'CRYPTO'
};

export interface DepositRequest {
    accountId: number,
    userId: number,
    amount: number,
    ccy: string,
    details: string
};

export enum AccountTabType {
    ALL = 'ALL',                // All
    WALLET = 'WALLET',          // Pooling
    BANK = 'BANK',              // Standart
    CRYPTO = 'CRYPTO',          // Crypto
    TRUST = 'TRUST',            // Trust
    TOKEN = 'TOKEN'             // Token
};

export enum AccountTypeEnum {
    ALL = 'ALL',
    BANK = 'BANK',
    BANK_AND_CARD = 'BANK_AND_CARD',
    CARD = 'CARD',
    WALLET = 'WALLET',
    CRYPTO = 'CRYPTO',
    CRYPTO_TOKEN = 'CRYPTO_TOKEN',
    TRUST = 'TRUST',
    SYSTEM = 'SYSTEM',
    TOKEN = 'TOKEN',
    VAULT = 'VAULT',
    BANK_AND_WALLET = "BANK_AND_WALLET",
};

export enum AccountStatusEnum {
    ORDERED = 'ORDERED',
    ACTIVE = 'ACTIVE',
    DELETED = 'DELETED',
    TERMINATED = 'TERMINATED',
    BLOCKED = 'BLOCKED',
    CARD_STATUS_UNKNOWN = 'CARD_STATUS_UNKNOWN',
    CARD_REQUESTED = 'CARD_REQUESTED',
    CARD_PENDING = 'CARD_PENDING',
    CARD_ORDERED = 'CARD_ORDERED',
    CARD_ORDER_COMPLETED = 'CARD_ORDER_COMPLETED',
    CARD_APPROVED = 'CARD_APPROVED',
    CARD_REJECTED = 'CARD_REJECTED',
    CARD_SUSPENDED = 'CARD_SUSPENDED',
    CARD_SUSPENDING = 'CARD_SUSPENDING',
    CARD_ACTIVE = 'CARD_ACTIVE',
    CARD_ACTIVATING = 'CARD_ACTIVATING',
    CARD_INACTIVE = 'CARD_INACTIVE',
    CARD_LOST = 'CARD_LOST',
    CARD_STOLEN = 'CARD_STOLEN',
    CARD_BLOCKED = 'CARD_BLOCKED',
    CARD_EXPIRED = 'CARD_EXPIRED',
    CARD_FROZEN = 'CARD_FROZEN'
}

export interface AccountDetailsResponseType {
    id: number;
    status: string;
    type: string;
    balance: number;
    balanceString: string;
    balanceAvailable: number;
    balanceAvailableString: string;
    balanceEur: number;
    balanceEurString: string;
    balanceOnHold: number;
    balanceOnHoldString: string;
    balanceOnHoldEur: number;
    balanceOnHoldEurString: string;
    balanceFrozen: number;
    balanceFrozenString: string;
    currency: string;
    userId: number;
    clientId: string;
    externalId: string;
    email: string;
    firstName: string;
    lastName: string;
    companyName: string;
    userType: string;
    walletId: string;
    mobileNumber: string;
    integration: string;
    integrationName: string;
    label: string;
    iban: string;
    accountNumber: string;
    recipient: string;
    bankSwift: string;
    bankSortCode: string;
    bankName: string;
    bankCountryCode: string;
    bankAddress: string;
    bankCity: string;
    bankBranchName: string;
    monthCommDate: string;
    accountAddresses: Array<AccountAddress>;
    wlpId: string;
    closureDate: string;
    displayDecimals: number;
    feeAccountBalanceBasedOnFeePayouts: number;
    feeAccountBalanceBasedOnFeePayoutsString: string;
    proc: string;
    sysAccountType: string;
    subProcesses: Record<string, SubProcess>;
    customToken: boolean;
};

export interface AccountSummary {
    currencyCode: string;
    displayDecimals: number;
    integration: string;
    type: AccountTypeEnum;
    balance: number;
    balanceEur: number;
    onHold: number;
    onHoldEur: number;
    totalAccounts: number;
};


export interface Account {
    id: number;
    status: string;
    type: string;
    balance: number;
    balanceString: string;
    balanceAvailable: number;
    balanceAvailableString: string;
    balanceEur: number;
    balanceEurString: string;
    balanceOnHold: number;
    balanceOnHoldString: string;
    balanceFrozen: number;
    balanceFrozenString: string;
    currency: string;
    userId: number;
    clientId: string;
    externalId: string;
    email: string;
    firstName: string;
    lastName: string;
    companyName: string;
    userType: UserType;
    walletId: string;
    mobileNumber: string;
    integration: string;
    integrationName: string;
    label: string;
    iban: string;
    accountNumber: string;
    recipient: string;
    bankSwift: string;
    bankSortCode: string;
    bankName: string;
    bankCountryCode: string;
    bankAddress: string;
    bankCity: string;
    bankBranchName: string;
    monthCommDate: string;
    isCustomToken: boolean;
    accountAddresses: Array<AccountAddress>;
    wlpId: string;
    closureDate: string;
    displayDecimals: number;
    feeAccountBalanceBasedOnFeePayouts: number;
    feeAccountBalanceBasedOnFeePayoutsString: string;
    customToken: boolean;
    proc?: string;
};

export interface AccountForAutocomplete {
    id: number;
    currencyCode: string;
    integration: string;
    walletId: string;
    wlpId: string;
    type: string;
    status: string;
};
