import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { ReferralStore, RefereeType, ReferralPaymentType, ReferralsSummary, ReferralChartStatistic, ReferralProgram } from './referralTypes';

export const referralsPaginationParamsInit = {
	filter: '',
	orderBy: 'referee',
	sort: SortDirectionEnum.DESC,
	take: 25,
	skip: 0
};

export const paymentsPaginationParamsInit = {
	filter: '',
	orderBy: 'referee',
	sort: SortDirectionEnum.DESC,
	take: 25,
	skip: 0
};


export const referralProgramsPaginationParamsInit = {
	filter: '',
	orderBy: 'startDate',
	sort: SortDirectionEnum.DESC,
	take: 25,
	skip: 0
};

const initialState: ReferralStore = {
	storedUserId: null,
	referrals: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: referralsPaginationParamsInit
	},
	storedRefUserId: null,
	payments: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: paymentsPaginationParamsInit
	},
	// Statistics
	summary: null,
	loadingSummary: false,
	storedWlpId: null,
	programs: {
		loading: false,
		list: [],
		error: null,
		count: 0,
		pagination: referralProgramsPaginationParamsInit
	}
};

const slice = createSlice({
	name: 'referrals',
	initialState,
	reducers: {
		// Referrals
		setStoredUserId(state, action: PayloadAction<number | null>) {
			state.storedUserId = action.payload;
		},
		setReferrals(state, action: PayloadAction<Array<RefereeType>>) {
			state.referrals.list = action.payload;
		},
		setLoadingReferrals: (state, { payload }: PayloadAction<boolean>) => {
			state.referrals.loading = payload;
		},
		setErrorReferrals: (state, { payload }: PayloadAction<string>) => {
			state.referrals.error = payload;
		},
		setCountReferrals: (state, { payload }: PayloadAction<number>) => {
			state.referrals.count = payload;
		},
		setPaginationReferrals: (state, { payload }: PayloadAction<PaginateParams>) => {
			state.referrals.pagination = payload;
		},
		resetReferrals(state) {
			state.referrals.list = [];
			state.referrals.count = 0;
			state.referrals.pagination = referralsPaginationParamsInit;
			state.referrals.loading = false;
			state.referrals.error = null;
		},
		// Payments
		setStoredRefUserId(state, action: PayloadAction<number | null>) {
			state.storedRefUserId = action.payload;
		},
		setReferralPayments(state, action: PayloadAction<Array<ReferralPaymentType>>) {
			state.payments.list = action.payload;
		},
		setLoadingPayments: (state, { payload }: PayloadAction<boolean>) => {
			state.payments.loading = payload;
		},
		setErrorPayments: (state, { payload }: PayloadAction<string>) => {
			state.payments.error = payload;
		},
		setCountPayments: (state, { payload }: PayloadAction<number>) => {
			state.payments.count = payload;
		},
		setPaginationPayments: (state, { payload }: PayloadAction<PaginateParams>) => {
			state.payments.pagination = payload;
		},
		resetPayments(state) {
			state.payments.list = [];
			state.payments.count = 0;
			state.payments.pagination = paymentsPaginationParamsInit;
			state.payments.loading = false;
			state.payments.error = null;
		},
		// Summary
		setSummary: (state, { payload }: PayloadAction<ReferralsSummary | null>) => {
			state.summary = payload;
		},
		setLoadingSummary: (state, { payload }: PayloadAction<boolean>) => {
			state.loadingSummary = payload;
		},
		// Programs
		setPrograms: (state, { payload }: PayloadAction<Array<ReferralProgram>>) => {
			state.programs.list = payload;
		},
		setLoadingPrograms: (state, { payload }: PayloadAction<boolean>) => {
			state.programs.loading = payload;
		},
		setErrorPrograms: (state, { payload }: PayloadAction<string>) => {
			state.programs.error = payload;
		},
		setCountPrograms: (state, { payload }: PayloadAction<number>) => {
			state.programs.count = payload;
		},
		setPaginationPrograms: (state, { payload }: PayloadAction<PaginateParams>) => {
			state.programs.pagination = payload;
		},
		resetPrograms(state) {
			state.programs.list = [];
			state.programs.count = 0;
			state.programs.pagination = referralProgramsPaginationParamsInit;
			state.programs.loading = false;
			state.programs.error = null;
		},
		// Stored wlpId
		setStoredWlpId: (state, { payload }: PayloadAction<string>) => {
			state.storedWlpId = payload;
		}
	}
});

export const {
	setStoredUserId,
	resetReferrals,
	setReferrals,
	setLoadingReferrals,
	setErrorReferrals,
	setCountReferrals,
	setPaginationReferrals,
	setStoredRefUserId,
	setReferralPayments,
	resetPayments,
	setLoadingPayments,
	setErrorPayments,
	setCountPayments,
	setPaginationPayments,
	setSummary,
	setLoadingSummary,
	setPrograms,
	setLoadingPrograms,
	setErrorPrograms,
	setCountPrograms,
	setPaginationPrograms,
	resetPrograms,
	setStoredWlpId
} = slice.actions;

export const getUserReferrals = (userId: number, payload: PaginateParams) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoadingReferrals(true));
			const response = await request.get(`/api/console/referrals/list/${userId}`, {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setReferrals(data.list));
			dispatch(setCountReferrals(data.count));
			dispatch(setPaginationReferrals(payload));
			dispatch(setLoadingReferrals(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoadingReferrals(false));
		}
	};
};


export const getReferralPayments = (payload: PaginateParams) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoadingPayments(true));
			const response = await request.get(`/api/console/referrals/payments`, {
				params: {
					isSystem: false,
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take
				}
			});
			const { data } = response;
			dispatch(setReferralPayments(data.list));
			dispatch(setCountPayments(data.count));
			dispatch(setPaginationPayments(payload));
		} catch (e) {
			showErrorNotification(e);
		} finally {
			dispatch(setLoadingPayments(false));
		}
	};
};

export const getReferralsTotalStatistics = (wlpId?: string) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoadingSummary(true));
			const response = await request.get('/api/console/referrals/total-statistics', {
				params: {
					...(wlpId && { wlpId: wlpId }),
				}
			});
			const data = response?.data;
			dispatch(setSummary(data));
		} catch (e) {
			dispatch(setSummary(null));
		} finally {
			dispatch(setLoadingSummary(false));
		};
	}
};

export const getReferralsChartStatistics = async (date: string, wlpId?: string): Promise<{ statistics: Array<ReferralChartStatistic> }> => {
	const response = await request.get('/api/console/referrals/chart-statistics', {
		params: {
			date: date,
			...(wlpId && { wlpId: wlpId }),
		}
	});
	const { data } = response;
	return data;
};

export const getReferralProgarms = (payload: PaginateParams, wlpId?: string) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoadingPrograms(true));
			const response = await request.get(`/api/console/referrals/programs`, {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take,
					...(wlpId && { wlpId: wlpId }),
				}
			});
			const { data } = response;
			dispatch(setPrograms(data.list));
			dispatch(setCountPrograms(data.count));
			dispatch(setPaginationPrograms(payload));
		} catch (e) {
			showErrorNotification(e);
		} finally {
			dispatch(setLoadingPrograms(false));
		}
	};
};


export default slice.reducer;
