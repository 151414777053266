import React from 'react';
import { useField, useFormikContext } from 'formik';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';

export interface CustomSelectOptionType {
    value: string;
    text: string;
    enabled: boolean;
};

type Props = SelectProps & {
    name: string;
    label: string;
    renderOption?: (option: CustomSelectOptionType) => React.ReactNode;
};

const CustomSelectNew = ({ name, label, renderOption, ...props }: Props) => {
    const { values, setFieldValue, setFieldTouched, validateField } = useFormikContext<any>();
    const [, meta] = useField(name);


    const handleOptionClick = (value: string) => {
        const newOptions = options.map(option =>
            option.value === value ? { ...option, enabled: true } : { ...option, enabled: false }
        );
        setFieldValue(name, newOptions);
        setFieldTouched(name, true, false);
        validateField(name);
    };

    const renderError = (error: string | string[] | { [key: string]: string }) => {
        if (typeof error === 'string') {
            return error;
        } else if (Array.isArray(error)) {
            return error.join(', ');
        } else if (typeof error === 'object') {
            return Object.values(error).join(', ');
        } else {
            return 'Invalid selection';
        }
    };

    const options: Array<CustomSelectOptionType> = values[name] || [];
    const enabledOptions = options.filter(option => option.enabled);

    return (
        <FormControl fullWidth error={meta.touched && Boolean(meta.error)}>
            <InputLabel>{label}</InputLabel>
            <Select
                {...props}
                value={enabledOptions.length === 1 ? enabledOptions[0].value : ''}>
                {options.map((option, index) => (
                    <MenuItem key={option.value.toString()}
                        onClick={() => handleOptionClick(option.value)}
                        value={option.value.toString() || ''}>
                        {renderOption ? renderOption(option) : option.text}
                    </MenuItem>
                ))}
            </Select>
            {
                meta.error ? (
                    <FormHelperText>{renderError(meta.error)}</FormHelperText>
                ) : null
            }
        </FormControl >
    );
};

export default CustomSelectNew;