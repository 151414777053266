import { normalizeString } from "@/utils/stringFormat";

export const getWlpName = (wlp: string) => {
    switch (wlp.toUpperCase()) {
        case "CNDA":
            return "CNDA";
        case "ARONWIRE":
            return "Aronwire";
        case "DTS":
            return "DTS Money";
        case "DTSUS":
            return "DTS Money Americas";
        case "MONETAL":
            return "Monetal";
        case "CACTUS":
            return "Cactus Pay";
        case "COSPAY":
            return "Cospay";
        case "CUSTODYFY":
            return "Custodyfy";
        case "ESCROWFY":
            return "Escrowfy";
        case "GAVICO":
            return "Gavico";
        case "HAWEX":
            return "Hawex";
        case "IDEALOOP":
            return "Idealoop";
        case "XWARD":
            return "Xward Pay";
        case "LUIDORE":
            return "Luidore";
        case "NEURAL":
            return "Neural Pay";
        case "NEURALID":
            return "Neural ID Pay";
        case "OLAMIT":
            return "Olamit";
        case "REW":
            return "Rew Money";
        case "SALAZAR":
            return "Salazar";
        case "VOOLA":
            return "Voola";
        case "TUTUS":
            return "Tutus";
        case "WZ":
            return "Wirezee";
        case "YESSY":
            return "Yessy Pay";
        case "DTSCB":
            return "DTSCB";
        case "WALLEXCARD":
            return "Wallexcard";
        case "AZURE":
            return "Azure";
        case "AZUREPAY":
            return "Azurepay";
        case "TYNCOFEX":
            return "Tyncofex";
        case "SLP":
            return "Unicorn Club";
        case "BRIDGY":
            return "Bridgy";
        case "BRIDGYNET":
            return "Bridgy Network"; 
        case "BRIDGYDEFI":
            return "Bridgy DeFi";
        case "KYRREX":
            return "Kyrrex";
        case "DTSWAP":
            return "DTS Swap";
        default:
            return normalizeString(wlp);
    }
};

export const getWlpImageSrc = (wlp: string) => {
    return `https://abcmob-public.s3.eu-central-1.amazonaws.com/platform-logo/${wlp.toLowerCase()}.svg`
}