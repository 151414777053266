import Accounts from "@/icons/menuIcons/Accounts";
import Apps from "@/icons/menuIcons/Apps";
import Cards from "@/icons/menuIcons/Cards";
// import Chart from "@/icons/menuIcons/Chart";
import Earn from "@/icons/menuIcons/Earn";
import Home from "@/icons/menuIcons/Home";
import Money from "@/icons/menuIcons/Money";
import Settings from "@/icons/menuIcons/Settings";
import Support from "@/icons/menuIcons/Support";
import Tokens from "@/icons/menuIcons/Tokens";
import Transfers from "@/icons/menuIcons/Transfers";
import Users from "@/icons/menuIcons/Users";
import { EmiSection, EmiSectionType, Permission, PermissionType } from "@/redux/user/types";
import { checkAtLeastOnePermission, checkPermission, checkSectionEnabled } from "@/utils/permissions";
import { EmiSectionEnum, Section } from "./emiSections";
import CryptoWallet from "@/icons/menuIcons/CryptoWallet";
import Trades from "@/icons/menuIcons/Trades";
import { TFunction } from "i18next";
import Report from "@/icons/menuIcons/Report";
import UsersDuo from "@/icons/menuIcons/UsersDuo";
// import Escrow from "@/icons/menuIcons/Escrow";
// import Report from "@/icons/menuIcons/Report"; 

const getConfigurationAndFeesUrl = (permissions: Array<Permission>): string => {
    if (checkPermission(permissions, PermissionType.PLATFORMS_FEES)) {
        return '/configuration-and-fees/fees';
    }
    if (checkPermission(permissions, PermissionType.USER_GROUPS)) {
        return '/configuration-and-fees/user-groups';
    }
    if (checkPermission(permissions, PermissionType.USER_LEVEL)) {
        return '/configuration-and-fees/plans';
    }
    if (checkPermission(permissions, PermissionType.USER_LIMITS)) {
        return '/configuration-and-fees/limits';
    }
    return '/not-permitted';
};

const getWlpSections = (permissions: Array<Permission>, t: TFunction, iconSize: number, viewBox: string, emiSections: Array<EmiSectionType>): Array<Section> => {
    const settingsRequiredPermissions = [PermissionType.SYSTEM_USERS];
    const configurationAndFeesRequiredPermissions = [PermissionType.PLATFORMS_FEES, PermissionType.USER_GROUPS, PermissionType.USER_LEVEL, PermissionType.USER_LIMITS];

    return [
        {
            items: [
                {
                    id: 'dashboard',
                    title: t('pages.dashboard'),
                    type: 'item',
                    url: '/dashboard',
                    icon: <Home sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: true,
                },
                {
                    id: 'settings',
                    title: t('pages.settings'),
                    type: 'item',
                    url: '/settings/users',
                    icon: <Settings sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkAtLeastOnePermission(permissions, settingsRequiredPermissions) && checkSectionEnabled(emiSections, EmiSection.SETTINGS)
                },
            ]
        },
        {
            type: EmiSectionEnum.FINANCES_AND_CONFIGURATION,
            items: [
                {
                    id: 'finances',
                    title: t('pages.finances'),
                    type: 'item',
                    url: '/finances/overview',
                    icon: <Money sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.FINANCE) && checkSectionEnabled(emiSections, EmiSection.FINANCES)
                },
                {
                    id: 'configuration',
                    title: t('pages.configuration'),
                    type: 'item',
                    url: getConfigurationAndFeesUrl(permissions),
                    icon: <Apps sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkAtLeastOnePermission(permissions, configurationAndFeesRequiredPermissions)
                },
                {
                    id: 'transfers',
                    title: t('pages.transfers'),
                    type: 'item',
                    url: '/transfers/all',
                    icon: <Transfers sx={{ fontSize: iconSize }} />,
                    allowed: checkPermission(permissions, PermissionType.DOCUMENTS) && checkSectionEnabled(emiSections, EmiSection.TRANSFERS)
                },
                {
                    id: 'trades',
                    title: t('pages.trades'),
                    type: 'item',
                    url: '/trades',
                    icon: <Trades sx={{ fontSize: iconSize }} />,
                    allowed: checkPermission(permissions, PermissionType.DOCUMENTS) && checkSectionEnabled(emiSections, EmiSection.TRADES)
                },
            ]
        },
        {
            type: EmiSectionEnum.PLATFORM_AND_USER_MANAGEMENT,
            items: [
                {
                    id: 'users',
                    title: t('pages.users'),
                    type: 'item',
                    url: '/users/private',
                    icon: <Users sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.USERS) && checkSectionEnabled(emiSections, EmiSection.USERS)
                },
                {
                    id: 'accounts',
                    title: t('pages.accounts'),
                    type: 'item',
                    url: '/accounts/all',
                    icon: <Accounts sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.ACCOUNTS) && checkSectionEnabled(emiSections, EmiSection.ACCOUNTS)
                },
                {
                    id: 'cards',
                    title: t('pages.cards'),
                    type: 'item',
                    url: '/cards/physical',
                    icon: <Cards sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.CARDS) && checkSectionEnabled(emiSections, EmiSection.CARDS)
                },
                {
                    id: 'wallets',
                    title: t('pages.wallets'),
                    type: 'item',
                    url: '/wallets',
                    icon: <CryptoWallet sx={{ fontSize: iconSize }} />,
                    allowed: checkPermission(permissions, PermissionType.ACCOUNTS) && checkSectionEnabled(emiSections, EmiSection.WALLETS)
                },
                {
                    id: 'referrals',
                    title: t('pages.referrals'),
                    type: 'item',
                    url: '/referrals',
                    icon: <UsersDuo sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.REFERRALS) && checkSectionEnabled(emiSections, EmiSection.REFERRALS)
                },
            ]
        },
        {
            type: EmiSectionEnum.ANALYTICS_AND_REPORTING,
            items: [
                {
                    id: 'reports',
                    title: t('pages.reports'),
                    type: 'item',
                    url: '/reports/all',
                    icon: <Report sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.REPORTS) && checkSectionEnabled(emiSections, EmiSection.REPORTS)
                }
            ]
        },
        {
            type: EmiSectionEnum.PRODUCT_AND_SERVICES,
            items: [
                {
                    id: 'earn',
                    title: t('pages.earn'),
                    type: 'item',
                    url: '/earn/overview',
                    icon: <Earn sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.EARN) && checkSectionEnabled(emiSections, EmiSection.EARN)
                },
                {
                    id: 'tokens',
                    title: t('pages.tokens'),
                    type: 'item',
                    url: '/tokens',
                    icon: <Tokens sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.TOKENS) && checkSectionEnabled(emiSections, EmiSection.TOKENS)
                }
            ]
        },
        {
            type: EmiSectionEnum.SUPPORT,
            items: [
                {
                    id: 'support',
                    title: t('pages.support'),
                    type: 'item',
                    url: '/support',
                    icon: <Support sx={{ fontSize: iconSize }} viewBox={viewBox} />,
                    allowed: checkPermission(permissions, PermissionType.SUPPORT) && checkSectionEnabled(emiSections, EmiSection.SUPPORT),
                },
            ]
        }
    ];
}

export default getWlpSections;