import { SvgIcon } from '@mui/material';

const Completed = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}>
            <circle cx="12" cy="12" r="12" fill="#40927E" fillOpacity="0.16" />
            <path d="M7 12.8L10.1429 16L18 8" stroke="#40927E" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);
export default Completed;