import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";
import { HTMLAttributes, useState } from "react";

export interface AutocompleteOptionType {
    value: string | number;
    label: string;
    [key: string]: any;
}

type Props = {
    name: string;
    options: Array<AutocompleteOptionType>;
    defaultValue?: AutocompleteOptionType;
    renderOption?: (props: HTMLAttributes<HTMLLIElement>, option: any) => JSX.Element;
    onChangeSelectedOption?: (newValue: any) => void;
    renderInput?: (params: TextFieldProps, configTextfield: TextFieldProps, selectedOption: AutocompleteOptionType | null) => JSX.Element;
    autocompleteProps?: Partial<AutocompleteProps<any, boolean, boolean, boolean>>;
    textFieldProps?: Partial<TextFieldProps>;
} & TextFieldProps;

const TextWithAutocomplete = ({
    name,
    options,
    defaultValue,
    renderOption,
    onChangeSelectedOption,
    renderInput,
    autocompleteProps,
    textFieldProps,
    ...otherProps
}: Props) => {
    const [field, meta, helpers] = useField({ name, value: defaultValue?.value || '' });

    const [open, setOpen] = useState(false);

    const configTextfield = {
        ...field,
        ...otherProps,
    };

    if (meta && meta.touched && meta.error) {
        configTextfield.error = true;
        configTextfield.helperText = meta.error;
    }

    const selectedOption = options.find(option => option.value === field.value) || defaultValue || null;

    const handleAutocompleteChange = (event: React.SyntheticEvent<Element, Event>, newValue: AutocompleteOptionType | null) => {
        if (newValue) {
            helpers.setValue(newValue ? newValue.value : null);
        } else {
            helpers.setValue('');
        }
        setOpen(false);
        if (onChangeSelectedOption) {
            onChangeSelectedOption(newValue ? newValue : null);
        }
    };

    const defaultRenderInput = (params: TextFieldProps, configTextfield: TextFieldProps) => (
        <TextField {...params} {...configTextfield} />
    );

    return (
        <Autocomplete
            isOptionEqualToValue={(option, value) => option.value === value.value}
            options={options}
            value={selectedOption}
            onChange={handleAutocompleteChange}
            renderOption={renderOption}
            getOptionLabel={(option) => option.label || ""}
            renderInput={(params) => renderInput ? renderInput(params, configTextfield, selectedOption) : defaultRenderInput(params, configTextfield)}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            {...autocompleteProps}
        />
    );
}

export default TextWithAutocomplete;