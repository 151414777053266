import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { Wlp } from '../user/types';
import { getWlpName } from '@/components/dataGrid/utils/Platform';
import { Workflow, WorkflowsStore } from './workflowsTypes';

export const workflowsPaginationParamsInit = {
	filter: '',
	orderBy: 'updatedDate',
	sort: SortDirectionEnum.DESC,
	take: 15,
	skip: 0
};



const initialState: WorkflowsStore = {
	loading: false,
	list: [],
	error: null,
	count: 1000,
	pagination: workflowsPaginationParamsInit,
	filters: {
		[HeaderFilterType.WLPS]: [],
	},
	filtersSet: false
};


const slice = createSlice({
	name: 'workflows',
	initialState,
	reducers: {
		setWorkflowsList(state, action: PayloadAction<Array<Workflow>>) {
			state.list = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload
		},
		setError: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
		},
		setCount: (state, action: PayloadAction<number>) => {
			state.count = action.payload;
		},
		setPagination: (state, action: PayloadAction<PaginateParams>) => {
			state.pagination = action.payload;
		},
		// Filters
		setFilters: (state, action: PayloadAction<{ wlps: Array<Wlp> }>) => {
			const wlpsOptions = action.payload.wlps.map(item => ({
				value: item.wlpId,
				text: getWlpName(item.wlpId),
				enabled: true
			}));

			state.filters[HeaderFilterType.WLPS] = wlpsOptions;
			state.filtersSet = true;
		},
		setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, options: Array<FilterOptionType> }>) => {
			state.filters[action.payload.filterType] = action.payload.options;
		},
		setFiltersSet(state, action: PayloadAction<boolean>) {
			state.filtersSet = action.payload;
		}
	}
});

export const { setLoading, setError, setCount, setPagination, setWorkflowsList, setFilters, setFilter, setFiltersSet } = slice.actions;

export const getWorkflowsList = (payload: PaginateParams) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/console/workflow/list', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take,
				}
			});
			const { data } = response;
			dispatch(setWorkflowsList(data.list));
			if(data.list.length >= 15) {
				dispatch(setCount(1000));
			} else {
				dispatch(setCount(data.list.length));
			}
			dispatch(setPagination(payload));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};


export const restartWorkflow = async (id: number): Promise<any> => {
	const response = await request.post('/api/console/workflow/restart', { id });
	const { data } = response;
	return data;
};


export default slice.reducer;
