import { FilterOptionType } from "@/components/dataGrid/CustomFilterHeader";
import { PaginateParams } from "@/types/pagination";
import { WlpFee } from "../wlpFees/wlpFeesTypes";

export interface PlatformFeeStore {
    commonFilterOptions: CommonFilterOptions,
    fees: {
        [key: string]: {
            loading: boolean,
            list: Array<WlpFee>,
            error: string | null,
            count: number,
            pagination: PaginateParams,
            loadingProcesses: boolean;
            uniqueCcy: Array<string>;
            selectedCurrency: string;
            processesConfigurations: Array<ProcessesFilterConfiguration> | null;
            filters: {
                [key: string]: Array<FilterOptionType>
            },
            filtersSet: boolean;
        }
    }
    serviceFees: {
        [key: string]: {
            loading: boolean,
            list: Array<ServiceFee>,
            error: string | null,
            count: number,
            pagination: PaginateParams,
        }
    }
};

interface CommonFilterOptions {
    storedWlpId: string | null;
    regionsOptions: Array<FilterOptionType>;
    loadingRegions: boolean;
};

export interface ProcessConfigurations {
    process: string;
    userTypes: Array<string>;
    ccyList: Array<string>;
    integration: string;
    integrationName: string;
    type: string;
    processName: string;
    description: string;
    countries: Array<string>;
};

export enum FeeEnum {
    ONE_TIME_FEE = 'oneTimeFee',
    ONE_TIME_PLUS_MONTHLY_FEE = 'oneTimePlusMonthlyFee',
    VOLUME_FEE = 'volumeFee',
    MANUAL_FEE = 'manualFee',
    FIXED_FEE = 'fixedFee'      // Fake value
};

export interface InitPlatformFee {
    initFeeMonth: number,
    initFeeAmount: number,
    initFeePercent: number,
    initMinFee: number,
    ccy: string,
    feeType: FeeEnum,
};

export interface Country {
    region: string,
    regionName: string,
    country: string,
    countryName: string
};

export interface WlpCountry {
    countryCode: string;
};

export interface RegionCountry {
    country: string;
    countryName: string;
}

export interface Region {
    region: string;
    regionName: string;
    countries: Array<RegionCountry>
};

export interface RegionCountry {
    country: string;
    countryName: string;
}

export interface PostFeePayload {
    emiWlpId: string;
    wlpId: string;
    process: string;
    ccy: string;
    fee: string;
    userProfileType: string;
    riskGroupCode: string;
    countryCode: string;
    feeAmount: number;
    feeMonth: number;
    feePercent: number;
    minFee: number;
    requestDoneBy: number;
    invoiceItemCode: string;
};

export interface CreateOrUpdateFeesPayload {
    emiFeeCreateOrUpdateRequestList: Array<PlatformFeePayload>;
};

export interface CreateOrUpdateFeePayload {
    platformFeePayload: PlatformFeePayload;
};

export interface PlatformFeePayload {
    wlpId?: string;
    process: string;
    ccy: string;
    fee: FeeEnum;
    userProfileType: string;
    riskGroupCode: string | null;
    countryCode: string | null;
    feeAmount: number;
    feeMonth: number;
    feePercent: number;
    minFee: number;
    invoiceItemCode: string | null;
    regionCode: string | null;
};

export interface CreateOrUpdateServiceFeePayload {
    serviceFeePayload: ServiceFeePayload;
};

export interface ServiceFeePayload {
    wlpId: string;
    fee: string;
    serviceCode: string;
    ownerType: string;
    ownerValue: string;
    costCcy: string;
    costAmount: number;
    serviceCcy: string;
    servicePrice: number;
    billingCycle: string;
    feeStartDateTime?: string;
};

// export interface EmiFee {
//     uuid: string;
//     emiFeeId: number;
//     defaultFeeId: number;
//     uiFeeId: number;
//     uiFutureFeeId: number;
//     emiWlpId: string;
//     wlpId: string;
//     process: string;
//     userProfileType: string;
//     feeType: FeeEnum;
//     ccy: string;
//     riskGroupCode: string;
//     regionCode: string;
//     countryCode: string;
//     currentFeeId: number;
//     currentFeeType: FeeEnum;
//     currentFeeAmount: number;
//     currentFeeMonth: number;
//     currentFeePercent: number;
//     currentMinFee: number;
//     currentStartDate: string;
//     currentEndDate: string;
//     currentScope: string;
//     futureFeeId: number;
//     futureFeeAmount: number;
//     futureFeeMonth: number;
//     futureFeePercent: number;
//     futureMinFee: number;
//     futureStartDate: string;
//     futureEndDate: string;
//     processName: string;
//     integration: string;
//     categoryCode: string;
//     disabledProcess: boolean;
//     integrationName: string;
//     regionName: string;
//     countryName: string;
//     countriesFeeIsAppliedTo: Array<string>;
// };

export interface ServiceFee {
    uuid: string;
    serviceCode: string;
    emiWlpId: string;
    wlpId: string;
    feeId: number;
    ownerType: string;
    ownerValue: string;
    feeType: string;
    costCcy: string;
    costAmount: number;
    serviceCcy: string;
    servicePrice: number;
    costSysCcy: string;
    costSysAmount: number;
    serviceSysCcy: string;
    serviceSysPrice: number;
    billingCycle: string;
    startDate: string;
    endDate: string;
    futureFeeId: string;
    futureCostCcy: string;
    futureCostAmount: number;
    futureServiceCcy: string;
    futureServicePrice: number;
    futureBillingCycle: string;
    futureStartDate: string;
    futureEndDate: string;
    scope: string;
    upperName: string;
    lowerName: string;
}

export interface Process {
    wlpId: string;
    process: string;
    disabled: boolean;
    userProfileType: string;
    ccy: string;
    integration: string;
    integrationName: string;
    categoryCode: string;
    processName: string;
    description: string;
    type: string;
    availableCountries: Array<string>;
};

export interface ProcessConfiguration {
    process: string;
    parents: Array<string>;
    isParent: boolean;
    integration: string;
    type: string;
    wlpId: string;
    ccys: Array<string>;
    hasNotForInfoTokenCcys: boolean;
    hasIntegrationConfig: boolean;
    deleted: boolean;
    disabled: boolean;
    maintenance: boolean;
    createFeesIfSystem: boolean;
    categoryCode: string;
    subCategoryCode: string;
    feeScopeEmi: string;
    feeScopeWlp: string;
    profileType: string;
    userGroupId: number;
    userLevelId: number;
    name: string;
    description: string;
    allowedCountries: Array<string>;
};

export interface ProcessesFilterConfiguration {
    process: string;
    ccys: Array<string>;
    integration: string;
    processName: string;
};