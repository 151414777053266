import { Button, DialogContent, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import DialogTitle from '@/components/DialogTitle';
import { getErrorMessage } from "@/utils/errors";
import { deleteFee, deleteWlpBasedFee } from "@/redux/platformFees/platformFeesSlice";
import DialogWithStatus from "@/components/DialogWithStatus";
import { dispatch, useSelector } from "@/store/store";
import { setError, setLoadingTime, setSuccess } from "@/redux/dialog/dialogSlice";
import { useEffect } from "react";

interface Props {
    feeId: number;
    wlpId?: string;
    open: boolean;
    wlpBasedFee: boolean;
    onDelete: () => void;
    onComplete: () => void;
    onClose: () => void;
};

const DeleteFeeForm = ({ feeId, wlpId, open, wlpBasedFee, onComplete, onDelete, onClose }: Props) => {
    const { t } = useTranslation();
    const { showResult, loading, loadingTime } = useSelector((state) => state.dialog);

    const initialValues = {
        feeId: feeId
    };

    const validationSchema = Yup.object({
        feeId: Yup.number().required('Required'),
    });

    const submit = async (formData: { feeId: number }) => {
        try {
            const { feeId } = formData;
            const payload = {
                feeId: feeId,
                ...(wlpId && { wlpId })
            }
            wlpBasedFee ? await deleteWlpBasedFee(payload) : await deleteFee(payload);
            const message = t("fees.feeDeletedSuccessfully").toString();
            dispatch(setLoadingTime(2000));
            dispatch(setSuccess(message));
        } catch (e) {
            dispatch(setLoadingTime(2000));
            dispatch(setError(getErrorMessage(e)));
        }
    };

    useEffect(() => {
        if (!loading && loadingTime === 0 && showResult) {
            onComplete && onComplete();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, loadingTime, showResult]);

    return (
        <DialogWithStatus
            open={open}
            onClose={onClose}
            fullWidth
            PaperProps={{
                sx: {
                    maxWidth: '500px'
                }
            }}>
            <DialogTitle onClose={onClose}>
                {t('compliance.sar.confirmationRequired')}
            </DialogTitle>

            <DialogContent>
                <Grid container direction='column' wrap='nowrap'>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={submit}>
                        {({ isSubmitting }) => {
                            return (
                                <Form>
                                    <Grid container item mt={1}>
                                        <Typography>
                                            {t('fees.wantDoDeleteThisFee')}
                                        </Typography>
                                    </Grid>

                                    <Grid container item justifyContent='space-between' mt='auto' pt={3}>
                                        <Button onClick={onClose} variant='outlined' color='secondary' disabled={isSubmitting}>{t('form.buttons.no')}</Button>
                                        <Button onClick={onDelete} type='submit'>{t('form.buttons.yes')}</Button>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik >

                </Grid>
            </DialogContent>
        </DialogWithStatus >
    );
}

export default DeleteFeeForm;