import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { Box, Button, Grid, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { DataGrid, DataGridProps, GridDensity, GridPaginationModel, GridSortModel, useGridApiRef } from '@mui/x-data-grid';
import { showErrorNotification } from '@/utils/errors';
import CustomToolbar from './CustomToolbar';
import { useTranslation } from 'react-i18next';
import CustomGridPagination from './CustomGridPagination';
import NoRowsOverlay from './NoRowsOverlay';
import { useSelector, dispatch } from '@/store/store';
import { setDensity } from '@/redux/dataGrid/dataGridSlice';
import AnySearchAdvanced from './anySearchAdvanced/AnySearchAdvanced';
import CustomColumnMenu from './CustomColumnMenu';
import { FilterOptionType } from './CustomFilterHeader';

export interface CustomDataGridRef {
    reloadData: () => void;
    updatePaginationParams: (fieldName: string, filter: string, subFieldName?: string) => void;
    enableFilterOptions: (fieldName: string, filterOptions: Array<FilterOptionType>, subFieldName?: string) => Array<FilterOptionType>;
};
interface Props extends DataGridProps {
    rowCount: number,
    paginationParams: PaginateParams,
    paginationParamsInit: PaginateParams,
    title?: string,
    withRefreshBtn?: boolean,
    onFirstCall?: () => void,
    loading: boolean,
    loadingError?: string | null,
    gridContainerHeight?: string,
    withSearch?: boolean,
    searchFilterName?: string,
    customButton?: React.ReactNode,
    hideHeader?: boolean,
    applyBorderStyles?: boolean,
    fetch: (params: PaginateParams) => void,
    readyToFetchCondition?: boolean,
    gridSx?: SxProps<Theme>,
    autoPageSize?: boolean,
    autoHeight?: boolean,
    noRowsOverlayText?: string;
    refreshFirstOnLoad?: boolean;
};

const CustomDataGrid = forwardRef((props: Props, ref) => {
    const {
        fetch,
        paginationParams,
        paginationParamsInit,
        rowCount,
        loading,
        loadingError,
        rows,
        refreshFirstOnLoad,
        withRefreshBtn,
        onFirstCall,
        title,
        gridContainerHeight,
        withSearch,
        searchFilterName = 'any',
        customButton,
        hideHeader = false,
        applyBorderStyles = true,
        readyToFetchCondition = true,
        gridSx,
        autoPageSize = false,
        autoHeight = true,
        noRowsOverlayText,
        ...otherProps
    } = props;

    const theme = useTheme();

    const apiGridRef = useGridApiRef();
    const apiRef = props.apiRef ? props.apiRef : apiGridRef;
    const { t } = useTranslation();
    const [firstCall, setFirstCall] = useState(true);
    const [skipFetch, setSkipFetch] = useState(false);
    const [fetchWithInitParams, setFetchWithInitParams] = useState(false);
    const [anyFilterValue, setAnyFilterValue] = useState('');

    const [sortModel, setSortModel] = useState<GridSortModel>([{
        field: paginationParams.orderBy,
        sort: paginationParams.sort === SortDirectionEnum.ASC ? 'asc' : 'desc'
    }]);

    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        pageSize: paginationParams.take,
        page: paginationParams.skip
    });

    const [loadingTable, setLoadingTable] = useState(true);
    useEffect(() => {
        setLoadingTable(loading === true || !readyToFetchCondition === true);
    }, [loading, readyToFetchCondition])

    const [pageSizeOptions] = useState([paginationParams.take])

    useEffect(() => {
        if (readyToFetchCondition) {
            if ((rows?.length === 0 && firstCall) || refreshFirstOnLoad) {
                fetch({ ...paginationParamsInit });
                onFirstCall && onFirstCall();
            }
            setFirstCall(false);
            const anyValueFromParams = paginationParams.filter.split(';').find(filter => filter.startsWith(`${searchFilterName}=`))?.split('=')[1];
            if (anyValueFromParams) {
                setAnyFilterValue(anyValueFromParams);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [readyToFetchCondition]);


    const { density } = useSelector((state) => state.dataGrid);

    const changeDensity = (density: GridDensity) => {
        dispatch(setDensity(density));
    };

    const refreshData = useCallback(async (searchParams: PaginateParams) => {
        const currentPage = apiRef.current.state.pagination.paginationModel.page;
        if (currentPage === 0) {
            fetch(searchParams);
        } else {
            // This triggers call onPaginationModelChange
            setTimeout(() => apiRef.current.setPage(0));
        }
    }, [apiRef, fetch]);

    const handleRefreshButton = useCallback(async () => {
        onFirstCall && onFirstCall();
        setAnyFilterValue('');
        const pageSize = apiRef.current.state.pagination.paginationModel.pageSize;
        setFetchWithInitParams(true);
        refreshData({ ...paginationParamsInit, take: pageSize });
        setTimeout(() => setFetchWithInitParams(false));
    }, [refreshData, paginationParamsInit, apiRef, onFirstCall]);

    // Expose reloadData to parent component via ref
    useImperativeHandle(ref, () => {
        return {
            reloadData: () => {
                const currentPage = apiRef.current.state.pagination.paginationModel.page;
                const pageSize = apiRef.current.state.pagination.paginationModel.pageSize;

                const newPage = {
                    ...paginationParams, skip: currentPage, take: pageSize
                };
                fetch(newPage);
            },
            updatePaginationParams: (fieldName: string, filter: string, subFieldName?: string) => {
                const pageSize = apiRef.current.state.pagination.paginationModel.pageSize;
                const prevFilter = paginationParams.filter

                const prevFilterFiltered = prevFilter
                    .split(';')
                    .filter(part =>
                        part && // Ignore empty parts
                        !part.startsWith(fieldName) &&
                        (!subFieldName || !part.startsWith(subFieldName))
                    )
                    .join(';');

                const updatedFilter = filter
                    ? (prevFilterFiltered ? `${prevFilterFiltered};${filter}` : filter)
                    : prevFilterFiltered;

                setSkipFetch(true);
                fetch({ ...paginationParamsInit, take: pageSize, filter: updatedFilter });
                // Goes to the onPaginationModelChange without fetching new data
                setTimeout(() => {
                    apiRef.current.setPage(0);
                    setSkipFetch(false);
                });
            },
            enableFilterOptions: (fieldName: string, filterOptions: Array<FilterOptionType>, subFieldName?: string) => {
                if (!subFieldName) {
                    const fieldFilter = paginationParams.filter.split(';').find(f => f.startsWith(fieldName + '='));
                    const fieldValues = fieldFilter ? fieldFilter.split('=')[1].split(',') : [];
                    const enabledByParamsFilters = filterOptions.map(option => ({
                        ...option,
                        enabled: fieldValues.includes(option.value),
                    }));

                    const anyOptionEnabled = enabledByParamsFilters.some(option => option.enabled);
                    if (!anyOptionEnabled) {
                        return enabledByParamsFilters.map(option => ({
                            ...option,
                            enabled: true,
                        }));
                    };
                    return enabledByParamsFilters;
                } else {
                    const fieldFilter = paginationParams.filter.split(';').find(f => f.startsWith(fieldName + '='));
                    const fieldValues = fieldFilter ? fieldFilter.split('=')[1].split(',') : [];

                    const subFieldFilter = paginationParams.filter.split(';').find(f => f.startsWith(subFieldName + '='));
                    const subFieldValues = subFieldFilter ? subFieldFilter.split('=')[1].split(',') : [];

                    const enabledByParamsFilters = filterOptions.map(option => ({
                        ...option,
                        enabled: fieldValues.includes(option.value),
                        subValues: option.subValues?.map(subValue => ({
                            ...subValue,
                            enabled: subFieldValues.includes(subValue.value)
                        }))
                    }));

                    const anyOptionEnabled = enabledByParamsFilters.some(option => option.enabled);

                    if (!anyOptionEnabled) {
                        return enabledByParamsFilters.map(option => ({
                            ...option,
                            enabled: true,
                            subValues: option.subValues?.map(subValue => ({
                                ...subValue,
                                enabled: true
                            }))
                        }));
                    };
                    return enabledByParamsFilters;
                }
            }
        }
    });

    // This is called on first componenet load and on clicking page/next
    const onPaginationModelChange = useCallback(async (model: GridPaginationModel) => {
        try {
            if (firstCall === true && rows?.length > 0 && !refreshFirstOnLoad) {
                // Restore page from state 
                apiRef.current.setPage(paginationParams.skip);
            } else if (!skipFetch) {
                // Call page from grid model
                if (!fetchWithInitParams) {
                    const newPage = { ...paginationParams, take: model.pageSize, skip: model.page };
                    fetch(newPage);
                } else {
                    fetch({ ...paginationParamsInit });
                }
            }
            setPaginationModel(model);
        }
        catch (e) {
            showErrorNotification(e);
        }
    }, [paginationParams, firstCall, rows?.length, apiRef, skipFetch, fetch, fetchWithInitParams, paginationParamsInit, refreshFirstOnLoad]);

    const onSortModelChange = async (sortModel: GridSortModel) => {
        try {
            let page = paginationParams;
            if (firstCall !== true && !skipFetch) {
                // sortModel is empty when filter is unset (third click on column) 
                if (sortModel?.length > 0) {
                    const sortDirection = sortModel[0]?.sort === 'asc' ? SortDirectionEnum.ASC : SortDirectionEnum.DESC;
                    page = {
                        ...paginationParams,
                        orderBy: sortModel[0].field,
                        sort: sortDirection,
                    };
                }
                else {
                    page = {
                        ...paginationParams,
                        orderBy: paginationParamsInit.orderBy,
                        sort: paginationParamsInit.sort,
                    };
                }
                refreshData(page);
            }
            setSortModel(sortModel);
        }
        catch (e) {
            showErrorNotification(e);
        }
    };

    return (
        <Grid container item xs={12}
            sx={{
                ...(applyBorderStyles && {
                    borderColor: theme.palette.transparentBlack,
                    borderRadius: 2,
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    bgcolor: theme.palette.white.main,
                    ...gridSx
                })
            }}>
            <Grid container item sx={{
                ...(applyBorderStyles && {
                    px: 2,
                    py: 1.2
                })
            }}>
                {title && withSearch &&
                    <Grid
                        sx={{
                            ...(((withSearch || customButton || withRefreshBtn) && title) && { pb: 4, pt: 2, pl: 1 })
                        }}
                        justifyContent='space-between'
                        alignItems='center'>
                        <Typography variant='h2'>
                            {title}
                        </Typography>
                    </Grid>
                }

                {(withSearch || customButton || withRefreshBtn) &&
                    <Grid container justifyContent='space-between'>
                        <Grid item>
                            {withSearch ? (
                                <AnySearchAdvanced
                                    anyFilterValue={anyFilterValue}
                                    paginationParams={paginationParams}
                                    searchFilterName={searchFilterName}
                                    setAnyFilterValue={setAnyFilterValue}
                                    onSearch={async (params: PaginateParams) => {
                                        // This one will ignore onPaginationModelChange
                                        setSkipFetch(true);
                                        await fetch(params);
                                        setSkipFetch(false);
                                    }}
                                />
                            ) : (
                                title && (
                                    <Grid container
                                        sx={{
                                            ...(((withSearch || customButton || withRefreshBtn) && title) && { pt: 1 })
                                        }}
                                        justifyContent='space-between'
                                        alignItems='center'>
                                        {title &&
                                            <Typography
                                                variant='h2'>
                                                {title}
                                            </Typography >}
                                    </Grid>
                                )
                            )}
                        </Grid>
                        <Grid item>
                            <Grid container columnGap={1}>
                                {customButton &&
                                    <Grid item>
                                        {customButton}
                                    </Grid >}
                                {withRefreshBtn &&
                                    <Grid item >
                                        <Button
                                            onClick={handleRefreshButton}
                                            size="large"
                                            color="secondary"
                                            variant="outlined">
                                            {t('form.buttons.refresh')}
                                        </Button>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </Grid>

            {(title || withRefreshBtn || withSearch) && <Box mt='20px' />}
            <Box
                sx={{
                    width: '100%',
                    '& .datagrid-medium-bold': {
                        fontWeight: 500
                    }
                }}>
                <DataGrid
                    sortModel={sortModel}
                    paginationModel={paginationModel}
                    autoHeight={autoHeight}
                    apiRef={apiRef}
                    loading={loadingTable}
                    pagination
                    autoPageSize={autoPageSize}
                    pageSizeOptions={pageSizeOptions}
                    rows={rows}
                    rowCount={rowCount}
                    paginationMode="server"
                    onPaginationModelChange={onPaginationModelChange}
                    filterMode="server"
                    sortingMode="server"
                    onSortModelChange={onSortModelChange}
                    disableRowSelectionOnClick={true}
                    {...(density ? { density } : {})}
                    onStateChange={v => {
                        if (v.density && v.density.value !== undefined) {
                            if (density !== v.density.value) {
                                changeDensity(v.density.value);
                            }
                        }
                    }}
                    sx={{
                        '& .MuiDataGrid-virtualScroller': {
                            overflowX: 'hidden', // Hide horizontal scrollbar
                        },
                    }}
                    slots={{
                        pagination: CustomGridPagination,
                        footer: (props) => <CustomToolbar readyToFetchCondition={readyToFetchCondition} {...props} />,
                        columnMenu: CustomColumnMenu,
                        noRowsOverlay: () => <NoRowsOverlay error={!!loadingError} onReload={handleRefreshButton} text={noRowsOverlayText} />,
                        ...(hideHeader && { columnHeaders: () => <></> }),
                    }}
                    {...otherProps}
                />
            </Box>
        </Grid >

    );
});

export default CustomDataGrid;
