import TypographyWithEllipsis from "@/components/TypographyWithEllipsis";
import { getIsCrypto } from "@/redux/currencies/currenciesSlice";
import { getCurrencyLogo, getCurrencySign, getIsFiat } from "@/utils/currency";
import { CardMedia, TypographyProps, useTheme } from "@mui/material";
import Decimal from "decimal.js";
import { NumericFormat } from "react-number-format";

export interface Props {
    value: number;
    currency: string;
    fontWeight?: number;
    showCcy?: boolean;
    withCcySign?: boolean;
    withColor?: boolean | { positive: string; negative: string; };
    color?: string;
    withDash?: boolean;
    decimalScale?: number;
    fixedDecimalScale?: boolean;
    customFieldName?: string;
    valueIsNumericString?: boolean;
    withMinusSign?: boolean;
    withLogo?: boolean;
    logoSize?: string;
    textProps?: TypographyProps;
    valuePositiveColor?: string;
    isPercentValue?: boolean;
};

const calculateMinimumDecimalPlacesForPercent = (value: number, defaultScale: number = 0, maxScale: number = 2) => {
    if (!value) return { scale: defaultScale, fixed: true };

    const decimalValue = new Decimal(value);
    const actualDecimals = decimalValue.decimalPlaces();

    const scale = Math.max(defaultScale, Math.min(actualDecimals, maxScale));
    const fixed = actualDecimals <= defaultScale;

    return { scale, fixed };
};

const calculateMinimumDecimalPlaces = (value: number, isFiat: boolean, defaultScaleForFiat: number = 2, maxScaleForCrypto: number = 22) => {
    if (!value) return { scale: 2, fixed: true }
    if (isFiat) {
        return { scale: defaultScaleForFiat, fixed: true };
    } else {
        const decimalValue = new Decimal(value);
        const actualDecimals = decimalValue.decimalPlaces();

        const scale = Math.max(2, Math.min(actualDecimals, maxScaleForCrypto));
        const fixed = actualDecimals <= 2;
        return { scale, fixed };
    }
};

const calculateMinimumDecimalPlacesForFixed = (value: number, decimalScale: number, maxScaleForCrypto: number = 22) => {
    if (!value) return { scale: 2, fixed: true }
    else {
        const scale = Math.max(2, Math.min(decimalScale, maxScaleForCrypto));
        const fixed = decimalScale <= 2;
        return { scale, fixed };
    }
};

// This one is new and must replace the AmountCell in some time
const AmountValueCell = ({
    value,
    currency,
    fontWeight,
    showCcy = true,
    withCcySign = true,
    withColor,
    color,
    withDash = true,
    decimalScale,
    fixedDecimalScale = false,
    withMinusSign = true,
    withLogo = false,
    logoSize = '18px',
    textProps,
    valuePositiveColor = 'blue',
    isPercentValue = false
}: Props) => {
    const theme = useTheme();

    const ccy = currency ? (withCcySign ? getCurrencySign(currency) : currency) : ((!value && value !== 0) ? getCurrencySign('EUR') : '');

    const computedColor = withColor === true
        ? (color || (value < 0 ? theme.palette.error.main : valuePositiveColor))
        : (typeof withColor === 'object'
            ? (value < 0 ? withColor.negative : withColor.positive)
            : undefined);

    const isFiat = getIsFiat(currency);
    const isCrypto = getIsCrypto(currency);

    const { scale, fixed } =
        isPercentValue ? calculateMinimumDecimalPlacesForPercent(value) :
            fixedDecimalScale && decimalScale !== null && decimalScale !== undefined
                ? { scale: decimalScale, fixed: true }
                : decimalScale !== null && decimalScale !== undefined
                    ? calculateMinimumDecimalPlacesForFixed(value, decimalScale)
                    : calculateMinimumDecimalPlaces(value, isFiat);

    return (
        <>
            {(showCcy && withLogo && isCrypto && currency && !isPercentValue) &&
                <CardMedia
                    image={getCurrencyLogo(currency)}
                    component="img"
                    alt={currency}
                    sx={{ width: logoSize, height: logoSize, mr: 0.5 }}
                />}
            <TypographyWithEllipsis sx={{ fontWeight: fontWeight, color: computedColor }} {...textProps}>
                <NumericFormat
                    displayType={'text'}
                    decimalScale={scale}
                    fixedDecimalScale={fixed}
                    thousandsGroupStyle='thousand'
                    thousandSeparator={true}
                    value={Math.abs(value)}
                    allowNegative={false}
                    prefix={
                        showCcy
                            ? withLogo && isCrypto && currency
                                ? `${ccy} ${withMinusSign && value < 0 ? '-' : ''}`
                                : `${ccy} ${withMinusSign && value < 0 ? '-' : ''}`
                            : withMinusSign && value < 0 ? '-' : ''
                    }
                    suffix={`${isPercentValue ? ' %' : ""}`}
                />
            </TypographyWithEllipsis>
        </>
    )
}

export default AmountValueCell;
