import { SvgIcon } from '@mui/material';

const ArrowRoundRightUp = (props: any) => (
    <SvgIcon {...props}>
        <svg width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="1.5" />
            <path d="M9 15L15 9M15 9H10.5M15 9V13.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    </SvgIcon>
);
export default ArrowRoundRightUp;