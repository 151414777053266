import React, { createContext, useEffect, useMemo, useState } from 'react';
import Loader from 'components/Loader';
import { dispatch, useSelector } from '@/store/store';
import { RootState } from '@/store/reducer';
import { useIdleTimer } from 'react-idle-timer';
import { extendSession, refreshToken } from '@/redux/user/userSlice';
import ExtendSessionModal from '@/pages/authentication/ExtendSessionModal';

interface InitialStateType {
    isAuthorized: boolean;
    isOnboarded: boolean | undefined
}
const initialState: InitialStateType = {
    isAuthorized: false,
    isOnboarded: undefined
};

const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }: { children: React.ReactElement }) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
    const [openInactiveModal, setOpenInactiveModal] = useState<boolean>(false);

    const [openExtendSession, setOpenExtendSession] = useState<boolean>(false);

    const { token, expireTime } = useSelector(
        (state: RootState) => state.credentials
    );

    const { twoFaEnabled, permissions } = useSelector((state: RootState) => state.user);

    const hasWritePermission = permissions.some(p => p.writePermission);

    const twoFaRequired = hasWritePermission && twoFaEnabled === false;

    useEffect(() => {
        if (token && expireTime && expireTime <= 200) {
            setOpenExtendSession(true);
            setLoading(false);
        } else if (!token) {
            if (isAuthorized) setIsAuthorized(false);
            setLoading(false);
        } else if (!isAuthorized) {
            setIsAuthorized(true);
            setLoading(false);
        }
    }, [token, expireTime, isAuthorized]);

    useEffect(() => {
        // Used to refresh token after page reload
        if (isAuthorized) dispatch(refreshToken());
    }, [isAuthorized]);

    useEffect(() => {
        if (!isAuthorized || !token || (expireTime && expireTime <= 200)) return;
        const intervalId = setInterval(() => {
            dispatch(refreshToken());
        }, 60 * 1000 * 1); // 1 min
        return () => clearInterval(intervalId);
    }, [isAuthorized, token, expireTime]);

    const handleOnIdle = () => {
        setOpenInactiveModal(true);
    };

    useIdleTimer({
        timeout: 1000 * 60 * 20,
        onIdle: handleOnIdle,
        debounce: 500,
        disabled: !isAuthorized
    });

    const authContextValue = useMemo(() => ({
        isAuthorized,
        isOnboarded: !twoFaRequired,
    }), [isAuthorized, twoFaRequired]);

    if (loading) {
        return <Loader />;
    };

    return (
        <>
            <ExtendSessionModal
                open={openInactiveModal}
                onClose={() => setOpenInactiveModal(false)}
                onContinue={() => dispatch(refreshToken())}
            />
            <ExtendSessionModal
                open={openExtendSession}
                time={expireTime}
                onClose={() => setOpenExtendSession(false)}
                onContinue={() => dispatch(extendSession())}
            />
            <AuthContext.Provider value={authContextValue}>
                {children}
            </AuthContext.Provider>
        </>
    );
};

export default AuthContext;