import { AppDispatch } from '@/store/store';
import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { showErrorNotification } from '@/utils/errors';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { Wlp } from '../user/types';
import { getWlpName } from '@/components/dataGrid/utils/Platform';
import { ComplianceAccountDetailsStore, CompliancePaymentPayload, FrozenBalance } from './complianceAccountDetailsTypes';
import { AccountDetailsResponseType } from '../systemAccounts/systemAccountsTypes';

export const compAccDPaginationParamsInit = {
	filter: '',
	orderBy: 'frozenBalance',
	sort: SortDirectionEnum.DESC,
	take: 15,
	skip: 0
};



const initialState: ComplianceAccountDetailsStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	pagination: compAccDPaginationParamsInit,
	filters: {
		[HeaderFilterType.CLIENT_ID]: []
	},
	filtersSet: false,
	storedAccountId: null,
	networks: [],
	hasConfig: false,
};


const slice = createSlice({
	name: 'complianceAccountDetails',
	initialState,
	reducers: {
		setList(state, action: PayloadAction<Array<FrozenBalance>>) {
			state.list = action.payload;
		},
		setNetworks(state, action: PayloadAction<Array<string>>) {
			state.networks = action.payload;
		},
		setHasConfig: (state, action: PayloadAction<boolean>) => {
			state.hasConfig = action.payload
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload
		},
		setError: (state, action: PayloadAction<string>) => {
			state.error = action.payload;
		},
		setCount: (state, action: PayloadAction<number>) => {
			state.count = action.payload;
		},
		setPagination: (state, action: PayloadAction<PaginateParams>) => {
			state.pagination = action.payload;
		},
		// Filters
		setFilters: (state, action: PayloadAction<{ wlps: Array<Wlp>, currencies: Array<FilterOptionType> }>) => {
			const wlpsOptions = action.payload.wlps.map(item => ({
				value: item.wlpId,
				text: getWlpName(item.wlpId),
				enabled: true
			}));
			state.filters[HeaderFilterType.WLPS] = wlpsOptions;
			state.filters[HeaderFilterType.CURRENCY] = action.payload.currencies;
			state.filtersSet = true;
		},
		setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, options: Array<FilterOptionType> }>) => {
			state.filters[action.payload.filterType] = action.payload.options;
		},
		setFiltersSet(state, action: PayloadAction<boolean>) {
			state.filtersSet = action.payload;
		},
		setStoredAccountId(state, action: PayloadAction<number>) {
			state.storedAccountId = action.payload;
		}
	}
});

export const { setLoading, setError, setCount, setPagination, setList, setFilters, setFilter, setFiltersSet, setStoredAccountId, setNetworks, setHasConfig } = slice.actions;

export const getFrozenBalancesList = (accountId: number, payload: PaginateParams) => {
	return async (dispatch: AppDispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get(`/api/console/compliance/account/details/${accountId}`, {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: payload.take,
				}
			});
			const { data } = response;
			dispatch(setList(data.accounts));
			dispatch(setNetworks(data.networks));
			dispatch(setHasConfig(data.hasConfig));
			dispatch(setCount(data.count));
			dispatch(setPagination(payload));
			dispatch(setStoredAccountId(accountId));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			showErrorNotification(e);
			dispatch(setLoading(false));
		}
	};
};

export const getComplianceSingleAccount = async (accountId: number): Promise<AccountDetailsResponseType> => {
	const response = await request.get(`/api/console/compliance/account/single/${accountId}`);
	const { data } = response;
	return data;
};

export const postPayment = async (payload: CompliancePaymentPayload): Promise<any> => {
	const response = await request.post('/api/console/compliance/account/payment', payload);
	const { data } = response;
	return data;
};

export default slice.reducer;
