import { PaginateParams, SortDirectionEnum } from '@/types/pagination';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import request from '../../services/request';
import { Country, CreateOrUpdateFeePayload, CreateOrUpdateFeesPayload, CreateOrUpdateServiceFeePayload, PlatformFeeStore, ProcessConfiguration, ProcessesFilterConfiguration, ServiceFee, WlpCountry } from './platformFeesTypes';
import { FeeCategoryCodeType } from '../platforms/platformsTypes';
import { AppDispatch } from '@/store/store';
import { showErrorNotification } from '@/utils/errors';
import { FilterOptionType, HeaderFilterType } from '@/components/dataGrid/CustomFilterHeader';
import { get, groupBy, head, map } from "lodash";
import { t } from 'i18next';
import { WlpFee } from '../wlpFees/wlpFeesTypes';


export const getPaginationParamsInit = (feeCategoryCode: FeeCategoryCodeType) => {
    if (feeCategoryCode === FeeCategoryCodeType.SOFTWARE)
        return {
            filter: '',
            orderBy: "id",
            sort: SortDirectionEnum.DESC,
            take: 25,
            skip: 0
        }
    else {
        return {
            filter: `categoryCode=${feeCategoryCode};processDisabled=false`,
            orderBy: "id",
            sort: SortDirectionEnum.DESC,
            take: 25,
            skip: 0
        }
    };
};

const initialState: PlatformFeeStore = {
    commonFilterOptions: {
        storedWlpId: null,
        regionsOptions: [],
        loadingRegions: false,
    },
    fees: {
        [FeeCategoryCodeType.COMPLIANCE]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: getPaginationParamsInit(FeeCategoryCodeType.COMPLIANCE),
            loadingProcesses: false,
            uniqueCcy: [],
            processesConfigurations: null,
            selectedCurrency: "EUR",
            filters: {
                [HeaderFilterType.PROCESSES]: [],
                [HeaderFilterType.USER_TYPES]: [],
                [HeaderFilterType.RISK_GROUPS]: [],
                [HeaderFilterType.REGION_CODE]: [],
            },
            filtersSet: false
        },
        [FeeCategoryCodeType.BANKING]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: getPaginationParamsInit(FeeCategoryCodeType.BANKING),
            loadingProcesses: false,
            uniqueCcy: [],
            processesConfigurations: null,
            selectedCurrency: "EUR",
            filters: {
                [HeaderFilterType.PROCESSES]: [],
                [HeaderFilterType.USER_TYPES]: [],
                [HeaderFilterType.RISK_GROUPS]: [],
                [HeaderFilterType.REGION_CODE]: [],
            },
            filtersSet: false
        },
        [FeeCategoryCodeType.CARD]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: getPaginationParamsInit(FeeCategoryCodeType.CARD),
            loadingProcesses: false,
            uniqueCcy: [],
            processesConfigurations: null,
            selectedCurrency: "EUR",
            filters: {
                [HeaderFilterType.PROCESSES]: [],
                [HeaderFilterType.USER_TYPES]: [],
                [HeaderFilterType.RISK_GROUPS]: [],
                [HeaderFilterType.REGION_CODE]: [],
            },
            filtersSet: false
        },
        [FeeCategoryCodeType.CRYPTO]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: getPaginationParamsInit(FeeCategoryCodeType.CRYPTO),
            loadingProcesses: false,
            uniqueCcy: [],
            processesConfigurations: null,
            selectedCurrency: "USDT",
            filters: {
                [HeaderFilterType.PROCESSES]: [],
                [HeaderFilterType.USER_TYPES]: [],
                [HeaderFilterType.RISK_GROUPS]: [],
                [HeaderFilterType.REGION_CODE]: [],
            },
            filtersSet: false
        }
    },
    serviceFees: {
        [FeeCategoryCodeType.SOFTWARE]: {
            loading: false,
            list: [],
            error: null,
            count: 0,
            pagination: getPaginationParamsInit(FeeCategoryCodeType.SOFTWARE)
        },
    }

};

const slice = createSlice({
    name: 'platformFees',
    initialState,
    reducers: {
        reset: () => initialState,
        setFees(state, action: PayloadAction<{ type: FeeCategoryCodeType, list: Array<WlpFee> }>) {
            state.fees[action.payload.type].list = action.payload.list;
        },
        setLoading: (state, action: PayloadAction<{ type: FeeCategoryCodeType, loading: boolean }>) => {
            state.fees[action.payload.type].loading = action.payload.loading
        },
        setError: (state, action: PayloadAction<{ type: FeeCategoryCodeType, error: string }>) => {
            state.fees[action.payload.type].error = action.payload.error;
        },
        setCount: (state, action: PayloadAction<{ type: FeeCategoryCodeType, count: number }>) => {
            state.fees[action.payload.type].count = action.payload.count;
        },
        setPagination: (state, action: PayloadAction<{ type: FeeCategoryCodeType, pagination: PaginateParams }>) => {
            state.fees[action.payload.type].pagination = action.payload.pagination;
        },
        // Service fees
        setServiceFees(state, action: PayloadAction<{ type: FeeCategoryCodeType, list: Array<ServiceFee> }>) {
            state.serviceFees[action.payload.type].list = action.payload.list;
        },
        setLoadingService: (state, action: PayloadAction<{ type: FeeCategoryCodeType, loading: boolean }>) => {
            state.serviceFees[action.payload.type].loading = action.payload.loading
        },
        setErrorService: (state, action: PayloadAction<{ type: FeeCategoryCodeType, error: string }>) => {
            state.serviceFees[action.payload.type].error = action.payload.error;
        },
        setCountService: (state, action: PayloadAction<{ type: FeeCategoryCodeType, count: number }>) => {
            state.serviceFees[action.payload.type].count = action.payload.count;
        },
        setPaginationService: (state, action: PayloadAction<{ type: FeeCategoryCodeType, pagination: PaginateParams }>) => {
            state.serviceFees[action.payload.type].pagination = action.payload.pagination;
        },
        // Filters
        setUniqueCCy(state, action: PayloadAction<{ type: FeeCategoryCodeType, list: Array<string> }>) {
            state.fees[action.payload.type].uniqueCcy = action.payload.list;
        },
        setProcessesLoading: (state, action: PayloadAction<{ type: FeeCategoryCodeType, loading: boolean }>) => {
            state.fees[action.payload.type].loadingProcesses = action.payload.loading
        },
        setSelectedCurrency(state, action: PayloadAction<{ type: FeeCategoryCodeType, currency: string }>) {
            state.fees[action.payload.type].selectedCurrency = action.payload.currency;
        },
        setProcessesConfigurations(state, action: PayloadAction<{ type: FeeCategoryCodeType, processesConfigurations: Array<ProcessesFilterConfiguration> }>) {
            state.fees[action.payload.type].processesConfigurations = action.payload.processesConfigurations;
        },
        setFilters: (state, action: PayloadAction<{ type: FeeCategoryCodeType }>) => {
            const riskGroupOptions = getRiskGroupCodeOptions();
            const userTypesOptions = getUserTypesOptions();
            state.fees[action.payload.type].filters[HeaderFilterType.RISK_GROUPS] = riskGroupOptions;
            state.fees[action.payload.type].filters[HeaderFilterType.USER_TYPES] = userTypesOptions;
            state.fees[action.payload.type].filters[HeaderFilterType.REGION_CODE] = state.commonFilterOptions.regionsOptions;
            state.fees[action.payload.type].filtersSet = true;
        },
        setFilter: (state, action: PayloadAction<{ filterType: HeaderFilterType, type: FeeCategoryCodeType, options: Array<FilterOptionType> }>) => {
            state.fees[action.payload.type].filters[action.payload.filterType] = action.payload.options;
        },
        setFiltersSet(state, action: PayloadAction<{ type: FeeCategoryCodeType, set: boolean }>) {
            state.fees[action.payload.type].filtersSet = action.payload.set;
        },
        // Common filters
        setStoredWlpId: (state, action: PayloadAction<string>) => {
            state.commonFilterOptions.storedWlpId = action.payload
        },
        setRegionsLoading: (state, action: PayloadAction<boolean>) => {
            state.commonFilterOptions.loadingRegions = action.payload
        },
        setRegionsOptions: (state, action: PayloadAction<Array<FilterOptionType>>) => {
            state.commonFilterOptions.regionsOptions = action.payload
        }
    },
});

export const {
    reset,
    setFees,
    setLoading,
    setError,
    setCount,
    setPagination,
    setUniqueCCy,
    setProcessesLoading,
    setFilter,
    setStoredWlpId,
    setRegionsLoading,
    setRegionsOptions,
    setSelectedCurrency,
    setFilters,
    setFiltersSet,
    setServiceFees,
    setLoadingService,
    setErrorService,
    setCountService,
    setPaginationService,
    setProcessesConfigurations
} = slice.actions;

export const getProcessFilterOptions = (processesConfigurations: Array<ProcessesFilterConfiguration>, selectedCurrency: string,) => {
    const filteredProcessesConfigurations = processesConfigurations.filter(process => process.ccys.includes(selectedCurrency));
    const groupedIntegration = groupBy(filteredProcessesConfigurations, 'integration');
    const integrationOptionTypes = Object.keys(groupedIntegration)
        .map(integrationKey => {
            const processes = groupedIntegration[integrationKey];
            const uniqueProcesses = Array.from(new Map(processes.map(process => [process['process'], process])).values());
            return {
                value: integrationKey,
                text: t('integrations.' + integrationKey),
                enabled: true,
                subValues: uniqueProcesses.map(process => ({
                    value: process.process,
                    text: process.processName,
                    enabled: true
                }))
            };
        });
    if (integrationOptionTypes.length === 1) return integrationOptionTypes[0].subValues;
    return integrationOptionTypes;
};

export const getRiskGroupCodeOptions = () => {
    const riskGroupCodeOptions = ['LOW', 'MEDIUM', 'HIGH'].map(riskGroupCode => ({
        value: riskGroupCode,
        text: t("compliance.individualComplianceOverview.riskGroup." + riskGroupCode),
        enabled: true
    }));
    return riskGroupCodeOptions;
};

export const getUserTypesOptions = () => {
    const userTypesOptions = ['BUSINESS', 'INDIVIDUAL'].map(userType => ({
        value: userType,
        text: t('enum.userType.' + userType),
        enabled: true
    }));
    return userTypesOptions;
}

export const getRegionsOptions = (countries: Array<Country>): Array<FilterOptionType> => {
    const grouped = groupBy(countries, 'region');
    return map(grouped, (countriesInRegion, region) => ({
        value: region,
        text: get(head(countriesInRegion), 'regionName') || '',
        enabled: true,
        subValues: countriesInRegion.map(country => ({
            value: country.country,
            text: country.countryName,
            enabled: true
        })),
    }));
};

export const getAndSetProcessesConfigurations = (type: FeeCategoryCodeType, wlpId: string, selectedCurrency: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setProcessesLoading({ type, loading: true }));

            const filter = `hasIntegrationConfig=true;deleted=false;disabled=false;notIntegration=SYSTEM,TOKENS;categoryCode=${type}`;
            const response = await request.get(`/api/console/platforms/process/configurations/v2`, {
                params: {
                    wlpId: wlpId,
                    filter: filter
                }
            });

            const { data } = response as { data: Array<ProcessConfiguration> };
            const processesConfigurations = data.map(item => {
                return {
                    process: item.process,
                    processName: item.name,
                    ccys: item.ccys,
                    integration: item.integration
                };
            });
            dispatch(setProcessesConfigurations({ type, processesConfigurations: processesConfigurations }));

            const uniqueCcy = [...new Set(data.flatMap(process => process.ccys))];
            dispatch(setUniqueCCy({ type, list: uniqueCcy }));

            if (uniqueCcy.length > 0) {
                if (type === FeeCategoryCodeType.CRYPTO) {
                    if (uniqueCcy.includes('USDT')) dispatch(setSelectedCurrency({ type, currency: 'USDT' }));
                    else if (uniqueCcy.includes('BTC')) dispatch(setSelectedCurrency({ type, currency: 'BTC' }));
                    else dispatch(setSelectedCurrency({ type, currency: uniqueCcy[0] }));
                } else {
                    if (uniqueCcy.includes('EUR')) dispatch(setSelectedCurrency({ type, currency: 'EUR' }));
                    else dispatch(setSelectedCurrency({ type, currency: uniqueCcy[0] }));
                }
            };

            const filteredProcessesConfigurations = getProcessFilterOptions(processesConfigurations, selectedCurrency);
            dispatch(setFilter({ filterType: HeaderFilterType.PROCESSES, type: type, options: filteredProcessesConfigurations }));
        } catch (e) {
            showErrorNotification(e);
            dispatch(setFilter({ filterType: HeaderFilterType.PROCESSES, type: type, options: [] }));
        } finally {
            dispatch(setProcessesLoading({ type, loading: false }));
        }
    };
};

export const getRegions = (wlpId: string) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setRegionsLoading(true));
            const dataCountries = await getWlpCountries(wlpId);
            const allowedCountries = dataCountries.map(obj => obj.countryCode);

            const dataRegions = await getRegionCountries();
            const filteredRegions = dataRegions.filter(country => allowedCountries.includes(country.country));

            const regionOptions = getRegionsOptions(filteredRegions);

            dispatch(setRegionsOptions(regionOptions));
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setRegionsLoading(false));
        }
    };
};

export const getFeesByProcesses = (type: FeeCategoryCodeType, wlpId: string, ccy: string, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoading({ type, loading: true }));
            const response = await request.get(`/api/console/platforms/processes/fees/${wlpId}/${ccy}`, {
                params: {
                    filter: payload.filter,
                    orderBy: payload.orderBy,
                    sort: payload.sort.toUpperCase(),
                    skip: payload.skip.toString(),
                    take: payload.take,
                }
            });
            const { data } = response;
            dispatch(setFees({ type, list: data.emiProcessFees }));
            dispatch(setCount({ type, count: data.count }));
            dispatch(setPagination({ type, pagination: payload }));
            return data;
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoading({ type, loading: false }));
        }
    };
};

export const getServiceFees = (wlpId: string, payload: PaginateParams) => {
    return async (dispatch: AppDispatch) => {
        try {
            dispatch(setLoadingService({ type: FeeCategoryCodeType.SOFTWARE, loading: true }));
            const response = await request.get(`/api/console/platforms/service/fees/${wlpId}`, {
                params: {
                    filter: payload.filter,
                    skip: payload.skip.toString(),
                    take: payload.take,
                }
            });
            const { data } = response;
            dispatch(setServiceFees({ type: FeeCategoryCodeType.SOFTWARE, list: data.serviceFees }));
            dispatch(setCountService({ type: FeeCategoryCodeType.SOFTWARE, count: data.count }));
            dispatch(setPaginationService({ type: FeeCategoryCodeType.SOFTWARE, pagination: payload }));
            return data;
        } catch (e) {
            showErrorNotification(e);
        } finally {
            dispatch(setLoadingService({ type: FeeCategoryCodeType.SOFTWARE, loading: false }));
        }
    };
};

export const getRegionCountries = async (): Promise<Array<Country>> => {
    const response = await request.get('/api/console/platforms/countries/list')
    const { data } = response;
    return data;
};

export const getWlpCountries = async (wlpId?: string): Promise<Array<WlpCountry>> => {
    const response = await request.get(`/api/console/platforms/wlp/countries/list`, {
        ...(wlpId && { params: { wlpId } })
    });
    const { data } = response;
    return data;
};

export const createOrUpdateFeesList = async (wlpId: string, payload: CreateOrUpdateFeesPayload) => {
    const response = await request.post(`/api/console/platforms/fees/create-or-update/${wlpId}`, payload);
    return response?.data;
};

export const createOrUpdateFee = async (wlpId: string, payload: CreateOrUpdateFeePayload) => {
    const response = await request.post(`/api/console/platforms/fee/create-or-update/${wlpId}`, payload);
    return response?.data;
};

export const createOrUpdateServiceFee = async (wlpId: string, payload: CreateOrUpdateServiceFeePayload) => {
    const response = await request.post(`/api/console/platforms/service/fee/create-or-update/${wlpId}`, payload);
    return response?.data;
};

export const deleteServiceFee = async (wlpId: string, serviceFeeId: number) => {
    const response = await request.post(`/api/console/platforms/service/fee/delete/${wlpId}/${serviceFeeId}`);
    return response?.data;
};

export const deleteFee = async (payload: { feeId: number, wlpId?: string }) => {
    const response = await request.post(`/api/console/platforms/fee/delete`, payload);
    return response?.data;
};

// Used for user, group, plan fees
export const deleteWlpBasedFee = async (payload: { feeId: number, wlpId?: string }) => {
    const response = await request.post(`/api/console/platforms/wlp-based-fee/delete`, payload);
    return response?.data;
};

export default slice.reducer;